import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getProject, getReviews } from "../../apis";
import { AppButton1, ReviewSlider } from "../../components";
import config from "../../config/config";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../../assets/css/portfolio.css";
import { PopupButton } from "react-calendly";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

const Project = () => {
  const scheduleMeetingModalRef = useRef(null);
  const handleClickScheduleMeeting = (e) => {
    // console.log(scheduleMeetingModalRef.current);
    scheduleMeetingModalRef.current.onClick(e);
  };
  const { slug } = useParams();
  const [reviewsList, setReviewsList] = useState([]);

  const [project, setProject] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchProjects = async (params, headers) => {
    try {
      setLoading(true);
      const { data } = await getProject(params, headers);
      if (data.status !== "success") throw new Error(data.message);
      console.log(data);
      if (data.data?.Projects === null) throw new Error("Page Not Found");
      setProject(data?.data?.Projects);

      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchReviews = async (params, headers) => {
    try {
      const { data } = await getReviews(params, headers);
      if (data.status !== "success") throw new Error(data.message);

      setReviewsList(data?.data?.Review);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    fetchProjects(slug);
    fetchReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    // <div className="page project-page ">
    //   <div className="">
    //     <div className="top-section bg-el">
    //       {!loading && (
    //         <div className="page-section bg-el pt3 pb3">
    //           <div
    //             className="d-flex align-items-end justify-content-between "
    //             style={{ columnGap: 30 }}
    //           >
    //             <div className="d-flex align-items-center gap-5">
    //               {project.middleImage && (
    //                 <img
    //                   src={config.apiUrl + project.middleImage}
    //                   alt=""
    //                   className="mb2"
    //                   width="100px"
    //                 />
    //               )}
    //               <div className="">
    //                 <Typography
    //                   className="heading mb1"
    //                   variant="h4"
    //                   component="h2"
    //                   fontWeight={600}
    //                 >
    //                   {project?.name}
    //                 </Typography>
    //                 <Typography
    //                   className="heading"
    //                   variant="h5"
    //                   component="h2"
    //                   fontWeight={600}
    //                 >
    //                   {project?.heading}
    //                 </Typography>
    //               </div>
    //             </div>
    //             <Box textAlign={"end"} className="">
    //               <Typography
    //                 className="heading mb1"
    //                 variant="h5"
    //                 component="h2"
    //                 marginRight={"20px"}
    //                 fontWeight={600}
    //               >
    //                 Technology's
    //               </Typography>
    //               <Typography
    //                 className="card-gallery"
    //                 display={"flex"}
    //                 justifyContent={"end"}
    //                 gap={"20px"}
    //                 marginRight={"20px"}
    //               >
    //                 {project?.techno?.map((tech) => (
    //                   <Typography
    //                     key={tech.slug}
    //                     className="tech-img"
    //                     // style={{
    //                     //     background: "white",
    //                     //     boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.20)",
    //                     //     borderRadius: 100,
    //                     // }}
    //                   >
    //                     <img
    //                       src={tech.logo}
    //                       alt={tech.name}
    //                       style={{ width: "30px", height: "30px" }}
    //                     />
    //                   </Typography>
    //                 ))}
    //               </Typography>
    //             </Box>
    //           </div>
    //           <div className="d-flex gap-4 f-center mt2">
    //             <div className="d-flex align-items-center gap-1">
    //               <BsClock
    //                 style={{
    //                   fill: "var(--app-primary-main)",
    //                   fontSize: "18px",
    //                 }}
    //               />
    //               <Typography variant="body1" component="h3" className="clr-tl">
    //                 Reading Time : {project.readingDuration}
    //               </Typography>
    //             </div>
    //             |
    //             <div className="d-flex align-items-center gap-1">
    //               <FaRegCalendarAlt
    //                 style={{
    //                   fill: "var(--app-primary-main)",
    //                   fontSize: "18px",
    //                 }}
    //               />
    //               <Typography variant="body1" component="h3" className="clr-tl">
    //                 {formatDate(project.createdAt, "dd month yyyy")}
    //               </Typography>
    //             </div>
    //           </div>
    //         </div>
    //       )}
    //     </div>
    //   </div>

    //   <div className="card-header f-center">
    //     <div
    //       className="card-image"
    //       // style={{ backgroundImage: "url(/img/bg-1.png)", backgroundRepeat: "no-repeat" }}
    //     >
    //       <img
    //         src={config.apiUrl + project?.heroImage}
    //         alt={project?.title}
    //         style={{ maxWidth: "30rem" }}
    //         className="image"
    //       />
    //       {/* <img src="/img/fitness.png" alt="" /> */}
    //     </div>
    //   </div>
    //   <div className="card-action f-center">
    //     <AppButton1 text="Get Project" onClick={handleClickScheduleMeeting} />
    //     <PopupButton
    //       ref={scheduleMeetingModalRef}
    //       url="https://calendly.com/appretive"
    //       rootElement={document.getElementById("root")}
    //       styles={{ display: "none" }}
    //       text=""
    //     />
    //   </div>

    //   <div className="content page-section">
    //     <ol className="p1">
    //       <li style={{ fontSize: "20px", fontWeight: "600" }}>
    //         <Grid
    //           container
    //           className="mt1"
    //           gap-x={6}
    //           justifyContent={"space-between"}
    //         >
    //           <Grid item sm={12} md={3}>
    //             <Typography
    //               variant="h6"
    //               component="h1"
    //               color={project.textColor || "#3335CF"}
    //               fontWeight={"bold"}
    //               width={"100%"}
    //             >
    //               {project.heading}
    //             </Typography>
    //           </Grid>
    //           <Grid item sm={12} md={8}>
    //             <Typography
    //               variant="body1"
    //               component="h3"
    //               className="clr-tl project-descreption "
    //               fontWeight={"semibold"}
    //               margin="auto"
    //               textAlign={"justify"}
    //               dangerouslySetInnerHTML={{ __html: project?.desc }}
    //             ></Typography>
    //           </Grid>
    //         </Grid>
    //       </li>
    //       <li
    //         style={{ fontSize: "20px", fontWeight: "600", marginTop: "30px" }}
    //       >
    //         <Grid
    //           container
    //           className="mt1"
    //           gap-x={6}
    //           justifyContent={"space-between"}
    //         >
    //           <Grid item sm={12} md={3}>
    //             <Typography
    //               variant="h6"
    //               component="h1"
    //               color={project.textColor || "#3335CF"}
    //               fontWeight={"bold"}
    //             >
    //               Task
    //             </Typography>
    //           </Grid>
    //           <Grid item sm={12} md={8}>
    //             <Typography
    //               variant="body1"
    //               textAlign={"justify"}
    //               component="h3"
    //               className="clr-tl project-task"
    //               fontWeight={"semibold"}
    //               margin="auto"
    //               dangerouslySetInnerHTML={{ __html: project.task }}
    //             >
    //               {/* {project.task} */}
    //             </Typography>
    //           </Grid>
    //         </Grid>
    //       </li>
    //     </ol>
    //   </div>
    //   <div className="bg-el p2">
    //     <ContactSection />
    //   </div>
    // </div>

    <div className="page project-page ">
      <div className="">
        {console.log(project)}
        <div className="top-section bg-el">
          {!loading && (
            <div className="page-section bg-el pt3 pb3 flex flex-col  sm:flex-row   justify-between max-w-[90%] mx-auto">
              <div
                className="w-full md:max-w-[35%]  pl-10 md:ml-10 flex flex-col justify-center  "
                style={{ columnGap: 30 }}
              >
                <div className="flex items-center gap-3">
                  {project.middleImage && (
                    <img
                      src={config.apiUrl + project.middleImage}
                      alt=""
                      className="mb2"
                      width="100px"
                    />
                  )}
                  <div className="">
                    <Typography
                      className="heading mb1"
                      variant="h4"
                      component="h2"
                      fontWeight={600}
                    >
                      {project?.name}
                    </Typography>
                  </div>
                </div>
                <div>
                  <Typography
                    className="heading"
                    variant="h5"
                    component="h2"
                    fontWeight={600}
                  >
                    {project?.heading}
                  </Typography>
                </div>
                <Box textAlign={""} className="my-6">
                  <Typography
                    className="heading mb2"
                    variant="h5"
                    component="h2"
                    marginRight={"20px"}
                    fontWeight={600}
                  >
                    Technology's
                  </Typography>
                  <Typography
                    className="card-gallery"
                    display={"flex"}
                    justifyContent={""}
                    gap={"30px"}
                    marginRight={"20px"}
                    component="div"
                  >
                    {project?.techno?.map((tech) => (
                      <div
                        key={tech.slug}
                        className="tech-img"
                        // style={{
                        //     background: "white",
                        //     boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.20)",
                        //     borderRadius: 100,
                        // }}
                      >
                        <img
                          src={tech.logo}
                          alt={tech.name}
                          style={{ width: "30px", height: "30px" }}
                        />
                      </div>
                    ))}
                  </Typography>
                </Box>
                <div className="card-action f-start mt-6 md:mt-9">
                  <AppButton1
                    text="Get Project"
                    onClick={handleClickScheduleMeeting}
                  />
                  <PopupButton
                    ref={scheduleMeetingModalRef}
                    url="https://calendly.com/appretive"
                    rootElement={document.getElementById("root")}
                    styles={{ display: "none" }}
                    text=""
                  />
                </div>
              </div>

              <div className="card-header relative f-center w-full md:max-w-[60%]">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  modules={[Autoplay, Pagination]}
                  autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    el: ".swiper-pagination",
                    clickable: true,
                  }}
                  className="card-image-slider"
                >
                  {/* {project?.heroImage?.map((image, index) => (
                    <SwiperSlide key={index}>
                      <div className="card-image w-full object-cover">
                        <img
                          src={config.apiUrl + image}
                          // src={`${config.apiUrl}${image}`}
                          alt={`Slide ${index}`}
                          style={{ maxWidth: "50rem" }}
                          className="image"
                        />
                      </div>
                    </SwiperSlide>
                  ))} */}
                  {Array.isArray(project.heroImage) ? (
                    project.heroImage.map((image, index) => (
                      <SwiperSlide key={index}>
                        <div className="card-image w-full object-cover">
                          <img
                            src={`${config.apiUrl}${image}`}
                            alt={`Slide ${index}`}
                            style={{ maxWidth: "50rem" }}
                            className="image"
                          />
                        </div>
                      </SwiperSlide>
                    ))
                  ) : (
                    <SwiperSlide>
                      <div className="card-image w-full object-cover">
                        <img
                          src={`${config.apiUrl}${project.heroImage}`}
                          alt={`Slide 0`}
                          style={{ maxWidth: "50rem" }}
                          className="image"
                        />
                      </div>
                    </SwiperSlide>
                  )}
                </Swiper>

                <div className="swiper-pagination mt-10"></div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="content page-section">
        <ol className="p1">
          <li style={{ fontSize: "20px", fontWeight: "600" }}>
            <Grid
              container
              className="mt1"
              gap-x={6}
              justifyContent={"space-between"}
            >
              <Grid item sm={12} md={3}>
                <Typography
                  variant="h6"
                  component="h1"
                  color={project.textColor || "#3335CF"}
                  fontWeight={"bold"}
                  width={"100%"}
                >
                  {project.heading}
                </Typography>
              </Grid>
              <Grid item sm={12} md={8}>
                <Typography
                  variant="body1"
                  component="h3"
                  className="clr-tl project-descreption "
                  fontWeight={"semibold"}
                  margin="auto"
                  textAlign={"justify"}
                  dangerouslySetInnerHTML={{ __html: project?.desc }}
                ></Typography>
              </Grid>
            </Grid>
          </li>
          <li
            style={{ fontSize: "20px", fontWeight: "600", marginTop: "30px" }}
          >
            <Grid
              container
              className="mt1"
              gap-x={6}
              justifyContent={"space-between"}
            >
              <Grid item sm={12} md={3}>
                <Typography
                  variant="h6"
                  component="h1"
                  color={project.textColor || "#3335CF"}
                  fontWeight={"bold"}
                >
                  Task
                </Typography>
              </Grid>
              <Grid item sm={12} md={8}>
                <Typography
                  variant="body1"
                  textAlign={"justify"}
                  component="h3"
                  className="clr-tl project-task"
                  fontWeight={"semibold"}
                  margin="auto"
                  dangerouslySetInnerHTML={{ __html: project.task }}
                >
                  {/* {project.task} */}
                </Typography>
              </Grid>
            </Grid>
          </li>
        </ol>
      </div>
      {/* <div className="bg-el p2"><ContactSection /></div> */}
      <div className="clientReview my-10  mb-[-100px] lg:mb-[100px]">
        <div className="mx-auto text-center">
          <h1 className="text-[26px] text-center lg:text-[2.7vw] font-bold mb-4">
            Some of our happy clients
          </h1>
          <p className=" px-5 text-[19px] text-[#696969] font-lato text-center">
            With smiles of satisfaction, here’s what our clients’ had to say
            about our services
          </p>
        </div>
        <div className="contact-review mb-[50px] md:mb-[70px] lg:mb-[130px]">
          <div className="page-section contact-review-flex sm:flex flex-wrap justify-evenly">
            <div className={"contact-section"}>
              <div className={`page-section`}>
                <Typography
                  className="mb3 t-start clr-w font-bold"
                  variant="h4"
                  component="h3"
                  fontWeight={900}
                  style={{ fontFamily: "Gilroy-bold" }}
                >
                  Let’s Turn
                  <span className="clr-pm">Your Ideas</span> into Reality
                </Typography>
                <Typography
                  className="mb3 t-start clr-w"
                  variant="h6"
                  component="p"
                  style={{ fontFamily: "Gilroy-bold" }}
                >
                  Let's innovate for your technology needs with Appretive
                  Infotech.
                </Typography>
                <div className="f-start">
                  <AppButton1
                    variant="h6"
                    style={{ fontFamily: "Gilroy-bold" }}
                    text={"Let's Talk"}
                    onClick={handleClickScheduleMeeting}
                  />
                  <PopupButton
                    ref={scheduleMeetingModalRef}
                    url="https://calendly.com/appretive"
                    rootElement={document.getElementById("root")}
                    styles={{ display: "none" }}
                    text=""
                  />
                </div>
              </div>
            </div>
            <div className="review-section review-section lg:mb-[-180px]">
              <div
                className="page-section"
                style={{ paddingTop: 0, paddingLeft: 0 }}
              >
                <div className="bg-w shadow review-box w-[95%]">
                  {/* <h2
                                        className="mb2 t-center"
                                        style={{ fontSize: "36px", fontWeight: "600", padding: "20px" }}
                                    > */}
                  <Typography
                    className="mb1 mt1 t-center clr-w"
                    variant="h4"
                    component="h3"
                    fontWeight={600}
                    style={{ fontFamily: "Gilroy-bold" }}
                  >
                    <span className="heading-highlighted clr-b">
                      {" "}
                      What’s Our <span className="clr-pm">Client</span> Say
                    </span>{" "}
                  </Typography>
                  <div className="slider f-center">
                    <ReviewSlider data={reviewsList} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PopupButton
          ref={scheduleMeetingModalRef}
          url="https://calendly.com/appretive"
          rootElement={document.getElementById("root")}
          styles={{ display: "none" }}
          text=""
        />
      </div>
    </div>
  );
};

export default Project;
