import { Box, Grid, Typography } from "@mui/material";
// import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
// SwiperCore.use([Navigation, Pagination, FreeMode]);

import "../../assets/css/home.css";
import { AppButton1, ReviewSlider } from "../../components";
import config from "../../config/config";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { PopupButton } from "react-calendly";
import { useState } from "react";
import { getAllProject, getReviews } from "../../apis";
import { makeStyles } from "@mui/styles";
import services from "../../utils/services.json";
import Industries from "./component/Industries";
// import Clients from "./component/Clients";
import HeroSection from "./component/HeroSection";
// import WorkflowSlider from "./component/Process";
// import DevelopmentProcessSection from "./component/Development";

const useStyles = makeStyles((theme) => ({
    filter: {
        textAlign: "center",
        marginBottom: theme.spacing(2),
    },
    galleryItem: {
        padding: theme.spacing(2),
        textAlign: "center",
        width: 150,
    },
    itemImg: {
        maxWidth: "100%",
        // height: "55px",
        width: "55px",
    },
    technoTitle: {
        marginTop: theme.spacing(1),
    },
}));

// const processSectionData = [
//     {
//         icon: "/img/call.png",
//         title: "Contact",
//         description:
//             "Easy to share your project idea with us and lets discuss about your project for making best project.",
//     },
//     {
//         icon: "/img/signal.png",
//         title: "Analysis",
//         description: "We will contact you to and see you need then clarity your project and your requirements.",
//     },
//     {
//         icon: "/img/contact-card.png",
//         title: "Proposal",
//         description: "We will provide you with our free, non- binding bid or a tailored proposal for share your idea.",
//     },
//     {
//         icon: "/img/team.png",
//         title: "Team",
//         description: "We will assemble and prepare a best team for your best project design & devlopment",
//     },
//     {
//         icon: "/img/check-mark.png",
//         title: "Start",
//         description: "We will make scenarios and collect data then introduce you to the team and start the project.",
//     },
// ];
// const services = [
//     {
//         title: "Mobile Development",
//         description: "We are developing mobile experiences that serve the unique needs of your target audience.",
//         image: "/img/Mobile.png",
//     },
//     {
//         title: "Web Development",
//         description:
//             "We have a technically Strong development any type of web application. We have successfully delivered projects.",
//         image: "/img/web_site.png",
//     },
//     {
//         title: "Game Development",
//         description:
//             "We provide all type game development making web game or app game. android, ios, custom, html, etc. game development",
//         image: "/img/game.png",
//     },
//     {
//         title: "UI/UX Development",
//         description:
//             "we give best and most unique UI/UX design along with branding for your mobile application and website development.",
//         image: "/img/ui-ux.png",
//     },
//     {
//         title: "Digital Marketing",
//         description:
//             "We provide our clients with a strong creative digital marketing presence, through strategy, positioning, conversion",
//         image: "/img/marketing.png",
//     },
//     {
//         title: "SEO",
//         description:
//             "Achieve the highest possible rankings in Google and more customers by using the affordable best SEO services",
//         image: "/img/SEO.png",
//     },
// ];

// const industries = [
//   { title: "Photo & Video", icon: "/img/camera.png" },
//   { title: "Hand-on Delivery", icon: "/img/delivery.png" },
//   { title: "Food & Restaurant", icon: "/img/eat.png" },
//   { title: "Real Estates", icon: "/img/house-2.png" },
//   { title: "Health & Fitness", icon: "/img/heart-add.png" },
//   { title: "Transportation & Logistics", icon: "/img/truck.png" },
//   { title: "Social Networking", icon: "/img/speaker.png" },
//   { title: "Education & E-Learning", icon: "/img/monitor.png" },
//   { title: "Entertainment", icon: "/img/ticket-star.png" },
//   { title: "Baking & Finance", icon: "/img/bank.png" },
//   { title: "Ecommerce & Shop", icon: "/img/bucket.png" },
//   { title: "Travel & Tourism", icon: "/img/trash.png" },
// ];

// const whychooseus = [
//     {
//         title: "75%",
//         icon: "/img/gear-wcu.png",
//         description: "Success score on Setting",
//     },
//     {
//         title: "60%",
//         icon: "/img/appstore.png",
//         description: "Success in make IOS project",
//     },
//     {
//         title: "95%",
//         icon: "/img/bag-wcu.png",
//         description: "Success score on Upwork",
//     },
//     {
//         title: "95%",
//         icon: "/img/upwork.png",
//         description: "Success score on Upwork",
//     },
//     {
//         title: "75%",
//         icon: "/img/user-wcu.png",
//         description: "Make best project development",
//     },
//     {
//         title: "80%",
//         icon: "/img/bulb-wcu.png",
//         description: "Our Creativity in project",
//     },
//     {
//         title: "80%",
//         icon: "/img/cloud-wcu.png",
//         description: "Success score on deliver project",
//     },
//     {
//         title: "50%",
//         icon: "/img/award-wcu.png",
//         description: "Get 10 achievement",
//     },
//     {
//         title: "95%",
//         icon: "/img/users-wcu.png",
//         description: "Our team member",
//     },
// ];

const service = [
    {
        title: "Web Development",
        description: "Build your own effective and responsive website with our us",
        icon: "/img/Web_Development.svg",
        // backgroundColor: "rgba(90, 82, 249, 0.07)",
        hoverColor: "#5a52f938",
        link: "/service/web-development",
    },
    {
        title: "Mobile App Development",
        description: "Turn your app ideas into reality with trending features and best functionalities.",
        icon: "/img/mobile_app_development.svg",
        // backgroundColor: "#e76d2726",
        hoverColor: "#e76d2759",
        link: "/service/mobile-app-development",
    },

    {
        title: "Software Development",
        description: "Transform your business with our custom software development service",
        icon: "/img/software_development.svg",
        // backgroundColor: "rgba(241, 55, 51, 0.15)",
        hoverColor: "#f1373352",
        link: "/service/software-development",
    },
    {
        title: "Shopify  Development",
        description: "Get our Shopify Development service to Meet Your Specific Requirements",
        icon: "/img/ShopifyApp_Development.svg",
        // backgroundColor: "#900c3e17",
        hoverColor: "#900c3e29",
        link: "/service/shopify-app-development",
    },
    {
        title: "E-Commerce Software",
        description: "Increase your Sales with Our E-Commerce Software Development Service",
        icon: "/img/ecommerce.svg",
        // backgroundColor: "#00c5d91f",
        hoverColor: "#00c5d947",
        link: "/service/e-commerce-devlopment",
    },

    {
        title: "E-Commerce  Ads",
        description: "Boost Your Sale with our advancved E-Commerce Advertising Services",
        icon: "/img/eCommerce-Shopping-Ads.svg",
        // backgroundColor: "rgba(255, 179, 2, 0.09)",
        hoverColor: "#ffb3024f",
        link: "/service/e-commerce-shopping-ads",
    },
    {
        title: "Blockchain Development",
        description: "We provide expert Blockchain Development for best future of application",
        icon: "/img/blockchain.svg",
        // backgroundColor: "#a704bd14",
        hoverColor: "#a704bd3d",
        link: "/service/blockchain-development",
    },
    {
        title: "IOT service",
        description: "Connect Your World with our IoT Development services",
        icon: "/img/iot.svg",
        // backgroundColor: "rgba(245, 30, 133, 0.12)",
        hoverColor: "#f51e8547",
        link: "/service/internet-of-things",
    },
    {
        title: "SAP Service",
        description: "Improve Your business with Our Skilled SAP Services",
        icon: "/img/sap_new.svg",
        // backgroundColor: "#01418529",
        hoverColor: "#01418569",
        link: "/service/sap-services",
    },
];

const Home = () => {
    const scheduleMeetingModalRef = useRef(null);
    const handleClickScheduleMeeting = (e) => {
        scheduleMeetingModalRef.current.onClick(e);
    };
    const classes = useStyles();

    const [reviewsList, setReviewsList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [servicesList, setServicesList] = useState(services && services[0]?.subServices);
    const [slug, setSlug] = useState("mobile");

    const fetchReviews = async (params, headers) => {
        try {
            const { data } = await getReviews(params, headers);
            if (data.status !== "success") throw new Error(data.message);

            setReviewsList(data?.data?.Review);
        } catch (err) {
            console.error(err.message);
        }
    };

    const handelChangeFilter = (slugg) => {
        if (slug === slugg) return;
        setSlug(slugg);

        const [current] = services?.filter((s) => s?.slug === slugg);
        setServicesList([]);
        setTimeout(() => {
            setServicesList(current?.subServices);
        }, 10);
    };

    const fatchProjects = async (params, headers) => {
        try {
            const { data } = await getAllProject(params, headers);
            if (data.status !== "success") throw new Error(data.message);

            setProjectList(data?.data?.Projects);
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        fetchReviews();
        fatchProjects();
        const counters = document.querySelectorAll(".counters");
        let count = 0;
        counters.forEach((counter) => {
            const updateCounter = () => {
                const countTarget = parseInt(counter.getAttribute("data-counttarget"));
                count++;
                if (count < countTarget) {
                    counter.innerHTML = count + "+";
                    setTimeout(
                        updateCounter,
                        countTarget > 350 ? 3 : countTarget > 100 ? 7 : countTarget > 30 ? 50 : 150
                    );
                } else {
                    counter.innerHTML = countTarget + "+";
                }
            };
            updateCounter();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // let [activeTechCategory, setActiveTechCategory] = useState("Front End");
    // let [activeServiceCategory, setActiveServiceCategory] = useState(1);

    // useEffect(() => {
    //     const elements = document.querySelectorAll(".service-obserrve");
    //     elements.forEach((ele) => {
    //         let options = {
    //             // root: document.querySelector(".service-observer-container"),
    //             rootMargin: "0px",
    //             threshold: 0.7,
    //         };

    //         let callback = (entries, observer) => {
    //             entries.forEach((entry) => {
    //                 console.log(entry);
    //                 if (entry.isIntersecting) {
    //                     const id = parseInt(entry.target.id.split("-").splice(-1));
    //                     setActiveServiceCategory(id);
    //                 }
    //             });
    //         };

    //         let observer = new IntersectionObserver(callback, options);
    //         observer.observe(ele);
    //     });
    // }, []);
    useEffect(() => {
        // window.scrollTo(0, 0);
    }, []);

    return (
        <div className="page home-page">
            <div className={classes.root}>
                <HeroSection handleClickScheduleMeeting={handleClickScheduleMeeting} />
                {/* <Clients /> */}
                <div
                    className="service-section"
                    style={{
                        // backgroundImage: "url(/img/world-map.png)",
                        // backgroundPosition: "center",
                        // backgroundSize: "contain",
                        marginBottom: 20,
                    }}
                    // style={{
                    //     backgroundImage: "url(/img/service-back.jpg)",
                    //     backgroundSize: "cover",
                    //     backgroundAttachment: "fixed",
                    // }}
                >
                    <div className="page-section" style={{ minHeight: "25rem" }}>
                        <Typography className="mb5" variant="h4" component="h3" textAlign="center" fontWeight={600}>
                            <span
                                className="heading-highlighted clr-b
               "
                                style={{ fontFamily: "Gilroy-Bold" }}
                            >
                                {" "}
                                Our
                            </span>{" "}
                            <span style={{ fontFamily: "Gilroy-Bold" }} className="clr-pm">
                                Services
                            </span>
                        </Typography>
                        {/* <div className="card-container">
                        {services.map((service, i) => (
                            <div key={i} className="card">
                                <div className="card-head">
                                    <div className="card-img">
                                        <img src={service.image} alt={service.title} />
                                        <div className="card-img-bg">
                                            <svg
                                                width="130"
                                                height="132"
                                                viewBox="0 0 130 132"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M129.5 60C129.5 96.4508 100.951 116 64.5 116C28.0492 116 10 96.4508 10 60C10 23.5492 24.5492 0 61 0C97.4508 0 129.5 23.5492 129.5 60Z"
                                                    fill="#7FA7FE"
                                                />
                                                <path
                                                    className="logo-bg"
                                                    d="M119.5 76C119.5 112.451 90.9508 132 54.5 132C18.0492 132 0 112.451 0 76C0 39.5492 14.5492 16 51 16C87.4508 16 119.5 39.5492 119.5 76Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Typography variant="h6" component="h4" className="card-heading t-center">
                                        {service.title}
                                    </Typography>
                                    <Typography variant="body1" component="p" className="card-description t-center">
                                        {service.description}
                                    </Typography>
                                </div>
                                <div className="divider d-flex align-items-center justify-content-center gap-1 mt3">
                                    <span
                                        className="d-block w-75 bg-w"
                                        style={{
                                            height: "4px",
                                            maxWidth: "65px",
                                            borderRadius: "100px",
                                        }}
                                    ></span>
                                    <span
                                        className="d-block w-25 bg-w"
                                        style={{
                                            height: "4px",
                                            maxWidth: "15px",
                                            borderRadius: "100px",
                                        }}
                                    ></span>
                                    <Link to={"/"} style={{ marginBottom: "-3px" }}>
                                        <FaArrowRight fontWeight={900} fontSize={25} fill="white" cursor={"pointer"} />
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div> */}

                        <div className={(classes.filter, "filtering")}>
                            <div className="filter d-flex flex-wrap justify-content-center align-items-center poppins-font">
                                {services?.map((service, i) => (
                                    <span
                                        key={i}
                                        className={`${slug === service?.slug ? "active" : ""} ${
                                            services?.length === i + 1 ? "" : "dot"
                                        } pt1 pb1`}
                                        style={{ fontSize: "14px" }}
                                        onClick={() => handelChangeFilter(service?.slug)}
                                    >
                                        {service?.title}
                                    </span>
                                ))}
                                {/* <span
                                    className={slug === "web-development" ? "active" : ""}
                                    onClick={() => handelChangeFilter("web-development")}
                                >
                                    Backend
                                </span>
                                <span
                                    className={slug === "web-development" ? "active" : ""}
                                    onClick={() => handelChangeFilter("web-development")}
                                >
                                    Mobile
                                </span>
                                <span
                                    className={slug === "web-development" ? "active" : ""}
                                    onClick={() => handelChangeFilter("web-development")}
                                >
                                    DevOps & Cloud
                                </span>
                                <span
                                    className={slug === "web-development" ? "active" : ""}
                                    onClick={() => handelChangeFilter("web-development")}
                                >
                                    Database
                                </span>
                                <span
                                    className={slug === "web-development" ? "active" : ""}
                                    onClick={() => handelChangeFilter("web-development")}
                                >
                                    Project Manager
                                </span>
                                <span
                                    className={slug === "web-development" ? "active" : ""}
                                    onClick={() => handelChangeFilter("web-development")}
                                >
                                    CMS
                                </span> */}
                            </div>
                        </div>

                        <Grid
                            container
                            spacing={2}
                            justifyContent="center"
                            alignItems="flex-end"
                            className={`items mt3 mb3`}
                        >
                            {servicesList?.map((service, i) => (
                                <Grid item key={i} xs={6} sm={3} md={2}>
                                    <Box
                                        className={`${classes.galleryItem} `}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        flexDirection="column"
                                    >
                                        <span>
                                            <img
                                                className={`${classes.itemImg} fade-in`}
                                                alt={service?.title}
                                                src={service?.icon}
                                                width={500}
                                            />
                                        </span>
                                        <div className={`cont`}>
                                            <Typography
                                                variant="subtitle1"
                                                className={`${classes.technoTitle} poppins-font`}
                                            >
                                                {service?.title}
                                            </Typography>
                                        </div>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </div>

                <div className="text-center text-[24px] max-w-[70%] mx-auto">
                    <p className="font-bold ">
                        Our expert web and mobile app developers create the best solutions to match your company's goals
                        and provide strong commercial growth.
                    </p>
                </div>

                <div className="w-full px-4 sm:px-6 lg:px-8 my-12">
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 justify-center xl:mx-32">
                        {service.map((service, index) => (
                            <div key={index} className="col-span-1 min-w-0">
                                <div className="group relative">
                                    <div className="bg-white p-4 sm:p-6 lg:p-8 shadow-lg border-[1px] rounded-md text-center cursor-pointer transform transition-transform duration-300 hover:scale-105  hover:shadow-xl h-auto">
                                        <Link to={service.link}>
                                            <div
                                            //  className="bg-white p-4 sm:p-6 lg:p-8 shadow-lg border-[1px] rounded-md text-center cursor-pointer transform transition-transform duration-300 hover:scale-105  hover:shadow-xl h-auto"
                                            >
                                                <div className="mb-4">
                                                    <img
                                                        width="32"
                                                        height="32"
                                                        src={service.icon}
                                                        loading="lazy"
                                                        className="h-[100px] w-[160px] mx-auto"
                                                        alt={service.title}
                                                    />
                                                </div>
                                                <h3
                                                    className="text-[20px] sm:text-[25px] font-bold mt-4 mb-2"
                                                    style={{ color: "black" }}
                                                >
                                                    {service.title}
                                                </h3>
                                                <p className="text-[14px] sm:text-[16px] my-6">{service.description}</p>
                                            </div>
                                            {/* </Link> */}
                                            {/* <Link
                                        to={service.link}
                                        style={{ marginBottom: "4px" }}
                                        // className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-[#3776FF] opacity-0 group-hover:opacity-100 group-hover:underline transition duration-300 cursor-pointer hover:scale-125"
                                    > */}
                                            <AppButton1
                                                variant="body2"
                                                center={true}
                                                style={{ fontFamily: "Gilroy-bold" }}
                                                text={"Learn more"}
                                                className=""
                                            />
                                            {/* <span style={{ marginBottom: "10px", color: " #3776FF " }}>Learn More</span>
                                        <i className="ri-arrow-right-line"></i> */}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="who-section  ">
                    {" "}
                    {/*bg-el*/}
                    <div
                        className="page-section content !pb-[20px]"
                        // style={{ paddingTop: 0, paddingRight: 0 }}
                        style={{ backgroundColor: "#F0F9FF" }}
                    >
                        <div className="left">
                            <Typography variant="h4" component="h3" textAlign="center" fontWeight={600}>
                                <span className="heading-highlighted clr-b" style={{ fontFamily: "Gilroy-bold" }}>
                                    Who We
                                </span>{" "}
                                <span className="clr-pm" style={{ fontFamily: "GilRoy-bold" }}>
                                    Are
                                </span>
                            </Typography>
                            <div className="my-8">
                                <Typography className="mb2" variant="h5" component="h4" fontWeight={600}>
                                    <span className="d-block clr-pm">Who is</span> Appretive Infosoft
                                </Typography>
                                <div className="my-6 text-justify">
                                    <Typography
                                        className="mb1 poppins-fonts"
                                        variant="body3"
                                        component="p"
                                        color="text.light"
                                        fontWeight={500}
                                        lineHeight="30px"
                                    >
                                        We believe in maintaining integrity and transparency in our work to preserve our
                                        clients' trust.
                                    </Typography>
                                </div>
                                <div className="mt-8">
                                    <Typography
                                        className="mb4 poppins-fonts"
                                        variant="body3"
                                        component="p"
                                        color="text.light"
                                        fontWeight={500}
                                        lineHeight="34px"
                                    >
                                        Our main goal is to reduce the time from system to brilliant repairs, aiding in
                                        winning each competition in app development services. We enhance businesses'
                                        online presence, going beyond web design to improve our clients' overall digital
                                        presence, attracting more customers online and generating revenue.
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div className="h-100 right achived-section">
                            <div className="">
                                <Typography
                                    className="mb3"
                                    variant="h4"
                                    component="h5"
                                    textAlign="center"
                                    fontWeight={600}
                                >
                                    <span
                                        style={{ fontFamily: "Gilroy-Bold" }}
                                        className="heading-highlighted clr-b font-GilRoy"
                                    >
                                        We Have
                                    </span>{" "}
                                    <span className="clr-pm" style={{ fontFamily: "Gilroy-Bold" }}>
                                        Achieved
                                    </span>
                                </Typography>
                                {/* <div className="card-container">
                  <div
                    className="p-relative mt1 mb3 card"
                    style={{ margin: "auto" }}
                  >
                    <div
                      className="h-100"
                      style={{
                        margin: "auto",
                        overflow: "hidden",
                        objectFit: "cover",
                        width: "250px",
                      }}
                    >
                      <img
                        src="/img/image 465.png"
                        alt="slide-1"
                        width={"100%"}
                        height={"100%"}
                        style={{ objectFit: "contain" }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the opacity as needed
                          zIndex: 1,
                        }}
                      ></div>
                    </div>
                    <div
                      className="card pt3 pb3 p-absolute w-100 h-100"
                      style={{
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "white",
                      }}
                    >
                      <div className="card-head">
                        <Typography
                          className="counters clr-w"
                          data-counttarget="500"
                          variant="h4"
                          component="p"
                          fontWeight={600}
                        >
                          500+
                        </Typography>
                      </div>
                      <div className="card-body">
                        <Typography
                          variant="h6"
                          component="p"
                          fontWeight={500}
                          style={{ fontFamily: "poppins", color: "white" }}
                          color={"white"}
                        >
                          Enterprise Clients
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div
                    className="p-relative  mt1 mb3 card"
                    style={{
                      margin: "auto",
                    }}
                  >
                    <div
                      className="h-100"
                      style={{
                        margin: "auto",
                        overflow: "hidden",
                        objectFit: "cover",
                        width: "250px",
                      }}
                    >
                      <img
                        src="/img/expert-team.png"
                        alt="slide-2"
                        width={"100%"}
                        height={"100%"}
                        style={{
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <div
                      className="card  pt3 pb3 p-absolute w-100 h-100"
                      style={{
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "white",
                      }}
                    >
                      {" "}
                      <div className="card-head">
                        <Typography
                          className="counters clr-w"
                          data-counttarget="50"
                          variant="h4"
                          component="p"
                          fontWeight={600}
                        >
                          50+
                        </Typography>
                      </div>
                      <div className="card-body">
                        <Typography
                          variant="h6"
                          component="p"
                          fontWeight={500}
                          // className="quicksand-fonts"
                          style={{ fontFamily: "poppins", color: "white" }}
                          // fontSize="1.5rem !important"
                        >
                          Expert Team
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div
                    className="p-relative  mt1 mb3 card"
                    style={{
                      margin: "auto",
                    }}
                  >
                    <div
                      className="h-100"
                      style={{
                        margin: "auto",
                        overflow: "hidden",
                        objectFit: "cover",
                        width: "250px",
                      }}
                    >
                      <img
                        src="/img/image 467.png"
                        alt="slide-3"
                        width={"100%"}
                        height={"100%"}
                        style={{
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <div
                      className="card  pt3 pb3 p-absolute w-100 h-100"
                      style={{
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "white",
                      }}
                    >
                      {" "}
                      <div className="card-head">
                        <Typography
                          className="counters clr-w"
                          data-counttarget="15"
                          variant="h4"
                          component="p"
                          fontWeight={600}
                        >
                          15+
                        </Typography>
                      </div>
                      <div className="card-body">
                        <Typography
                          variant="h6"
                          component="p"
                          fontWeight={500}
                          // className="quicksand-fonts"
                          // fontSize="1.5rem !important"
                          style={{ fontFamily: "poppins", color: "white" }}
                        >
                          Awards Winner
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div
                    className="p-relative  mt1 mb3 card"
                    style={{
                      margin: "auto",
                    }}
                  >
                    <div
                      className="h-100"
                      style={{
                        margin: "auto",
                        overflow: "hidden",
                        objectFit: "cover",
                        width: "250px",
                      }}
                    >
                      <img
                        src="/img/review.png"
                        alt="slide-4"
                        width={"100%"}
                        height={"100%"}
                        style={{
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <div
                      className="card  pt3 pb3 p-absolute w-100 h-100"
                      style={{
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "white",
                      }}
                    >
                      {" "}
                      <div className="card-head">
                        <Typography
                          className="counters clr-w"
                          data-counttarget="250"
                          variant="h4"
                          component="p"
                          fontWeight={600}
                        >
                          250+
                        </Typography>
                      </div>
                      <div className="card-body">
                        <Typography
                          variant="h6"
                          component="p"
                          fontWeight={500}
                          // className="quicksand-fonts"
                          className="poppins-font"
                          style={{
                            color: "white",
                          }}

                          // fontSize="1.5rem !important"
                        >
                          Positive Reviews
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div> */}
                                <div className="card-container">
                                    <div className="p-relative mt1 mb3 card" style={{ margin: "auto" }}>
                                        <div
                                            className="h-100"
                                            style={{
                                                margin: "auto",
                                                overflow: "hidden",
                                                objectFit: "cover",
                                                width: "250px",
                                                position: "relative",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <img
                                                src="/img/image 465.png"
                                                alt="slide-1"
                                                width={"100%"}
                                                height={"100%"}
                                                style={{ objectFit: "contain" }}
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100%",
                                                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the opacity as needed
                                                    zIndex: 1,
                                                }}
                                            ></div>
                                        </div>
                                        <div
                                            className="card pt3 pb3 p-absolute w-100 h-100"
                                            style={{
                                                left: "50%",
                                                top: "50%",
                                                transform: "translate(-50%, -50%)",
                                                color: "white",
                                                zIndex: 2,
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                textAlign: "center",
                                            }}
                                        >
                                            <div className="card-head">
                                                <Typography
                                                    className="counters clr-w"
                                                    data-counttarget="500"
                                                    variant="h4"
                                                    component="p"
                                                    fontWeight={600}
                                                >
                                                    500+
                                                </Typography>
                                            </div>
                                            <div className="card-body">
                                                <Typography
                                                    variant="h6"
                                                    component="p"
                                                    fontWeight={500}
                                                    style={{ fontFamily: "poppins", color: "white" }}
                                                >
                                                    Enterprise Clients
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-relative mt1 mb3 card" style={{ margin: "auto" }}>
                                        <div
                                            className="h-100"
                                            style={{
                                                margin: "auto",
                                                overflow: "hidden",
                                                objectFit: "cover",
                                                width: "250px",
                                                position: "relative",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <img
                                                src="/img/expert-team.png"
                                                alt="slide-2"
                                                width={"100%"}
                                                height={"100%"}
                                                style={{ objectFit: "contain" }}
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100%",
                                                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the opacity as needed
                                                    zIndex: 1,
                                                }}
                                            ></div>
                                        </div>
                                        <div
                                            className="card pt3 pb3 p-absolute w-100 h-100"
                                            style={{
                                                left: "50%",
                                                top: "50%",
                                                transform: "translate(-50%, -50%)",
                                                color: "white",
                                                zIndex: 2,
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                textAlign: "center",
                                            }}
                                        >
                                            <div className="card-head">
                                                <Typography
                                                    className="counters clr-w"
                                                    data-counttarget="50"
                                                    variant="h4"
                                                    component="p"
                                                    fontWeight={600}
                                                >
                                                    50+
                                                </Typography>
                                            </div>
                                            <div className="card-body">
                                                <Typography
                                                    variant="h6"
                                                    component="p"
                                                    fontWeight={500}
                                                    style={{ fontFamily: "poppins", color: "white" }}
                                                >
                                                    Expert Team
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-relative mt1 mb3 card" style={{ margin: "auto" }}>
                                        <div
                                            className="h-100"
                                            style={{
                                                margin: "auto",
                                                overflow: "hidden",
                                                objectFit: "cover",
                                                width: "250px",
                                                position: "relative",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <img
                                                src="/img/image 467.png"
                                                alt="slide-3"
                                                width={"100%"}
                                                height={"100%"}
                                                style={{ objectFit: "contain" }}
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100%",
                                                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the opacity as needed
                                                    zIndex: 1,
                                                }}
                                            ></div>
                                        </div>
                                        <div
                                            className="card pt3 pb3 p-absolute w-100 h-100"
                                            style={{
                                                left: "50%",
                                                top: "50%",
                                                transform: "translate(-50%, -50%)",
                                                color: "white",
                                                zIndex: 2,
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                textAlign: "center",
                                            }}
                                        >
                                            <div className="card-head">
                                                <Typography
                                                    className="counters clr-w"
                                                    data-counttarget="15"
                                                    variant="h4"
                                                    component="p"
                                                    fontWeight={600}
                                                >
                                                    15+
                                                </Typography>
                                            </div>
                                            <div className="card-body">
                                                <Typography
                                                    variant="h6"
                                                    component="p"
                                                    fontWeight={500}
                                                    style={{ fontFamily: "poppins", color: "white" }}
                                                >
                                                    Awards Winner
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-relative mt1 mb3 card" style={{ margin: "auto" }}>
                                        <div
                                            className="h-100"
                                            style={{
                                                margin: "auto",
                                                overflow: "hidden",
                                                objectFit: "cover",
                                                width: "250px",
                                                position: "relative",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <img
                                                src="/img/review.png"
                                                alt="slide-4"
                                                width={"100%"}
                                                height={"100%"}
                                                style={{ objectFit: "contain" }}
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100%",
                                                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the opacity as needed
                                                    zIndex: 1,
                                                }}
                                            ></div>
                                        </div>
                                        <div
                                            className="card pt3 pb3 p-absolute w-100 h-100"
                                            style={{
                                                left: "50%",
                                                top: "50%",
                                                transform: "translate(-50%, -50%)",
                                                color: "white",
                                                zIndex: 2,
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                textAlign: "center",
                                            }}
                                        >
                                            <div className="card-head">
                                                <Typography
                                                    className="counters clr-w"
                                                    data-counttarget="250"
                                                    variant="h4"
                                                    component="p"
                                                    fontWeight={600}
                                                >
                                                    250+
                                                </Typography>
                                            </div>
                                            <div className="card-body">
                                                <Typography
                                                    variant="h6"
                                                    component="p"
                                                    fontWeight={500}
                                                    style={{ fontFamily: "poppins", color: "white" }}
                                                >
                                                    Positive Reviews
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <DevelopmentProcessSection /> */}
                {/* <div className="industries-section bg-w">
          <div className="page-section" style={{ marginTop: "-3rem" }}>
            <Typography
              className="mb5"
              variant="h4"
              component="h3"
              textAlign="center"
              fontWeight={600}
            >
              <span
                className="heading-highlighted clr-b"
                style={{ fontFamily: "Gilroy-bold" }}
              >
                Industries
              </span>{" "}
              <span className="clr-pm" style={{ fontFamily: "Gilroy-bold" }}>
                we serve
              </span>
            </Typography>
            <div className="card-container d-flex flex-wrap justify-content-center gap-2">
              {industries.map((industry, i) => (
                <div
                  key={i}
                  className="card d-flex flex-column align-items-center justify-content-center shadow-pop-tr"
                >
                  <img
                    className="card-image"
                    src={industry.icon}
                    alt=""
                    width="40px"
                  />
                  <Typography
                    variant="h6"
                    component="h6"
                    className="card-heading t-center mt3 poppins-fonts"
                  >
                    {industry.title}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </div> */}
                <Industries />
                {/* <div className="whychooseus-section bg-el">
                    <div className="page-section">
                        <Typography className="mb5" variant="h4" component="h3" textAlign="center" fontWeight={600}>
                            <span className="heading-highlighted clr-b"> Why Choose </span>{" "}
                            <span className="clr-pm">Appretive</span> Technologies ?
                        </Typography>
                        <div className="card-container d-flex flex-wrap justify-content-center">
                            {whychooseus.map((industry, i) => (
                                <div key={i} className="card d-flex align-items-strech">
                                    <div className="card-head">
                                        <img className="card-image" src={industry.icon} alt="" />
                                    </div>
                                    <div className="card-body d-flex justify-content-center flex-column">
                                        <div>
                                            <Typography
                                                variant="h5"
                                                component="h6"
                                                className="card-heading"
                                                fontWeight={700}
                                            >
                                                {industry.title}
                                            </Typography>
                                            <Typography variant="body1" component="p" className="card-description">
                                                {industry.description}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                <div className="process-section mt1 bg-sky-50">
                    <div className="page-section">
                        <Typography className="mb5" variant="h4" component="h3" textAlign="center" fontWeight={600}>
                            <span className="heading-highlighted clr-b" style={{ fontFamily: "Gilroy-bold" }}>
                                {" "}
                                Fast work with your
                            </span>{" "}
                            <span className=" clr-pm" style={{ fontFamily: "Gilroy-bold" }}>
                                Project
                            </span>
                        </Typography>
                        {/* {processSectionData.map((card, i) => (
                                <div className="card bg-w" key={i} data-index={i + 1}>
                                    <div className="card-header d-flex align-tems-center mb3">
                                        <div className="card-icon" style={{ zIndex: 10 }}>
                                            <img src={card.icon} alt={card.title} width="30px" />
                                        </div>
                                        <div className="card-heading f-center ml2" style={{ zIndex: 10 }}>
                                            {card.title}
                                        </div>
                                    </div>
                                    <div
                                        className="card-descripttion clr-tl"
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            lineHeight: "35px",
                                            zIndex: 10,
                                        }}
                                    >
                                        {card.description}
                                    </div>
                                </div>
                            ))} */}
                        {/* <div className=" d-flex flex-wrap align-items-center justify-content-center">
              <div className="workflow__slider ">
                <div className="workflow-wrapper">
                  <div className="swiper-slide poppins-fonts">
                    <div className="workflow__slide fade_left">
                      <Typography
                        variant="h4"
                        className="workflow__step  poppins-fonts"
                      >
                        step 1
                      </Typography>
                      <Typography variant="h5" className="workflow__number">
                        1
                      </Typography>
                      <Typography variant="h6" className="workflow__title">
                        Requirement Analysis
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ lineHeight: "24px" }}
                      >
                        Understanding your needs is needed to start the journey.{" "}
                      </Typography>
                    </div>
                  </div>
                  <div className="swiper-slide quicksand-fonts">
                    <div className="workflow__slide fade_left">
                      <Typography
                        variant="h4"
                        className="workflow__step quicksand-fonts"
                      >
                        step 2
                      </Typography>
                      <Typography variant="h5" className="workflow__number">
                        2
                      </Typography>
                      <Typography variant="h6" className="workflow__title">
                        Design & Development
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ lineHeight: "24px" }}
                      >
                        Crafting software solutions with creativity and
                        expertise.
                      </Typography>
                    </div>
                  </div>
                  <div className="swiper-slide quicksand-fonts">
                    <div className="workflow__slide fade_left">
                      <Typography
                        variant="h4"
                        className="workflow__step quicksand-fonts"
                      >
                        step 3
                      </Typography>
                      <Typography variant="h5" className="workflow__number">
                        3
                      </Typography>
                      <Typography variant="h6" className="workflow__title">
                        Test & Assure Quality
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ lineHeight: "24px" }}
                      >
                        Ensuring top performance and reliability.
                      </Typography>
                    </div>
                  </div>
                  <div className="swiper-slide quicksand-fonts">
                    <div className="workflow__slide fade_left">
                      <Typography
                        variant="h4"
                        className="workflow__step quicksand-fonts"
                      >
                        step 4
                      </Typography>
                      <Typography variant="h5" className="workflow__number">
                        4
                      </Typography>
                      <Typography variant="h6" className="workflow__title">
                        Deployment & Support
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ lineHeight: "24px" }}
                      >
                        Seamless deployment and ongoing assistance.
                      </Typography>
                    </div>
                  </div>
                  <div className="swiper-slide quicksand-fonts">
                    <div className="workflow__slide fade_left">
                      <Typography
                        variant="h4"
                        className="workflow__step quicksand-fonts"
                      >
                        step 5
                      </Typography>
                      <Typography variant="h5" className="workflow__number">
                        5
                      </Typography>
                      <Typography variant="h6" className="workflow__title">
                        User Testing
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ lineHeight: "24px" }}
                      >
                        We look forward to engaging with beyond the
                        conventional.
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
                        <div className="flex flex-wrap justify-center overflow-x-auto ml-auto">
                            <div className="workflow__slider flex py-4 px-2 md:px-0 overflow-x-auto">
                                <div className="workflow__slide max-w-md p-4  rounded-md ">
                                    <Typography variant="h4" className="workflow__step">
                                        step 1
                                    </Typography>
                                    {/* <Typography variant="h5" className="workflow__number">
                    1
                  </Typography> */}
                                    <h1 className="text-[95px] font-bold text-[#cfcece] font-poppins">1</h1>
                                    <Typography variant="h6" className="workflow__title">
                                        Requirement Analysis
                                    </Typography>
                                    <Typography variant="body1" style={{ lineHeight: "24px" }}>
                                        Understanding your needs is needed to start the journey.
                                    </Typography>
                                </div>
                                <div className="workflow__slide max-w-md p-4 bg-sky-50 rounded-md">
                                    <Typography variant="h4" className="workflow__step">
                                        step 2
                                    </Typography>
                                    {/* <Typography variant="h5" className="workflow__number">
                    2
                  </Typography> */}
                                    <h1 className="text-[95px] font-bold text-[#cfcece] font-poppins">2</h1>
                                    <Typography variant="h6" className="workflow__title">
                                        Design & Development
                                    </Typography>
                                    <Typography variant="body1" style={{ lineHeight: "24px" }}>
                                        Crafting software solutions with creativity and expertise.
                                    </Typography>
                                </div>
                                <div className="workflow__slide max-w-md p-4 bg-sky-50 rounded-md">
                                    <Typography variant="h4" className="workflow__step">
                                        step 3
                                    </Typography>
                                    {/* <Typography variant="h5" className="workflow__number">
                    3
                  </Typography> */}
                                    <h1 className="text-[95px] font-bold text-[#cfcece] font-poppins">3</h1>
                                    <Typography variant="h6" className="workflow__title">
                                        Test & Assure Quality
                                    </Typography>
                                    <Typography variant="body1" style={{ lineHeight: "24px" }}>
                                        Ensuring top performance and reliability.
                                    </Typography>
                                </div>
                                <div className="workflow__slide max-w-md p-4 bg-sky-50 rounded-md">
                                    <Typography variant="h4" className="workflow__step">
                                        step 4
                                    </Typography>
                                    {/* <Typography variant="h5" className="workflow__number">
                    4
                  </Typography> */}
                                    <h1 className="text-[95px] font-bold text-[#cfcece] font-poppins">4</h1>
                                    <Typography variant="h6" className="workflow__title">
                                        Deployment & Support
                                    </Typography>
                                    <Typography variant="body1" style={{ lineHeight: "24px" }}>
                                        Seamless deployment and ongoing assistance.
                                    </Typography>
                                </div>
                                <div className="workflow__slide max-w-md p-4 bg-sky-50 rounded-md">
                                    <Typography variant="h4" className="workflow__step">
                                        step 5
                                    </Typography>
                                    {/* <Typography variant="h5" className="workflow__number">
                    5
                  </Typography> */}
                                    <h1 className="text-[95px] font-bold text-[#cfcece] font-poppins">5</h1>
                                    <Typography variant="h6" className="workflow__title">
                                        User Testing
                                    </Typography>
                                    <Typography variant="body1" style={{ lineHeight: "24px" }}>
                                        We look forward to engaging with beyond the conventional.
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="portfolio-section pt3">
                    <Typography className="mb3" variant="h4" component="h3" textAlign="center" fontWeight={600}>
                        <span className="heading-highlighted clr-b" style={{ fontFamily: "Gilroy-bold" }}>
                            {" "}
                            Our
                        </span>{" "}
                        <span className=" clr-pm" style={{ fontFamily: "Gilroy-bold" }}>
                            Portfolio
                        </span>
                    </Typography>

                    <div className="page-section ">
                        <div className="slider">
                            <Swiper
                                slidesPerView={"auto"}
                                spaceBetween={30}
                                modules={[Autoplay, Pagination]}
                                autoplay={{
                                    delay: 3500,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    el: ".cards-pagination",
                                    clickable: true,
                                    // renderBullet: (index, className) => {
                                    //     return `<span className="${className}"></span>`;
                                    // },
                                }}
                            >
                                {projectList?.map((card, i) => (
                                    <SwiperSlide key={i}>
                                        <div
                                            className="card flex justify-around "
                                            style={{ backgroundColor: card.bgColor || "#ffa121" }}
                                        >
                                            <div className="card-body lg:max-w-[48%]">
                                                <div className="card-heading">
                                                    <Typography
                                                        variant="h4"
                                                        component="h4"
                                                        color={card.textColor || "text.main"}
                                                        fontWeight={600}
                                                        style={{
                                                            fontFamily: "Gilroy-bold",
                                                            // fontSize: "40px",
                                                        }}
                                                    >
                                                        {card.name}
                                                    </Typography>
                                                </div>
                                                <Typography
                                                    className="card-description clr-pl poppins-fonts ellipsis-6line "
                                                    style={{ fontSize: "18px" }}
                                                    variant="h6"
                                                    component="p"
                                                    color="text.light"
                                                    dangerouslySetInnerHTML={{
                                                        __html: card?.desc?.split("</p>")[0],
                                                    }}
                                                >
                                                    {/* {card.desc} */}
                                                </Typography>
                                                <Link to={`/portfolio/${card.slug}`}>
                                                    <div className="card-btn">
                                                        <p
                                                            className="btn-text poppins-fonts"
                                                            style={{
                                                                color: card.fontColor || "#000000",
                                                            }}
                                                        >
                                                            Read More
                                                        </p>
                                                        <svg
                                                            width="47"
                                                            height="28"
                                                            viewBox="0 0 67 28"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M2 26.5058L13.9422 16.4188C15.3526 15.2275 15.3526 13.2782 13.9422 12.087L2 2"
                                                                stroke={card.fontColor || "#000000"}
                                                                strokeWidth="2.5"
                                                                strokeMiterlimit="10"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M19 26.5058L30.0236 16.4188C31.3255 15.2275 31.3255 13.2782 30.0236 12.087L19 2"
                                                                stroke={card.fontColor || "#000000"}
                                                                strokeOpacity="0.8"
                                                                strokeWidth="2.5"
                                                                strokeMiterlimit="10"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M35 26.5058L46.9422 16.4188C48.3526 15.2275 48.3526 13.2782 46.9422 12.087L35 2"
                                                                stroke={card.fontColor || "#000000"}
                                                                strokeOpacity="0.6"
                                                                strokeWidth="2.5"
                                                                strokeMiterlimit="10"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M52 26.5058L63.9422 16.4188C65.3526 15.2275 65.3526 13.2782 63.9422 12.087L52 2"
                                                                stroke={card.fontColor || "#000000"}
                                                                strokeOpacity="0.4"
                                                                strokeWidth="2.5"
                                                                strokeMiterlimit="10"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="card-header d-flex p-1">
                                                {Array.isArray(card.heroImage) && card.heroImage.length > 0 ? (
                                                    <img
                                                        className="card-image max-w-[40%]"
                                                        src={`${config.apiUrl}${card.heroImage[0]}`}
                                                        alt={card?.title}
                                                    />
                                                ) : (
                                                    <img
                                                        className="card-image max-w-[40%] "
                                                        src={`${config.apiUrl}${card.heroImage}`}
                                                        alt={card?.title}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="cards-pagination f-center mt3"></div>
                        </div>
                    </div>
                </div>
                <div className="contact-review">
                    <div className="page-section contact-review-flex">
                        <div className={"contact-section"}>
                            <div className={`page-section`}>
                                <Typography
                                    className="mb3 t-start clr-w font-bold"
                                    variant="h4"
                                    component="h3"
                                    fontWeight={900}
                                    style={{ fontFamily: "Gilroy-bold" }}
                                >
                                    Let’s Turn
                                    <span className="clr-pm">Your Ideas</span> into Reality
                                </Typography>
                                <Typography
                                    className="mb3 t-start clr-w"
                                    variant="h6"
                                    component="p"
                                    style={{ fontFamily: "Gilroy-bold" }}
                                >
                                    Let's innovate for your technology needs with Appretive Infotech.
                                </Typography>
                                <div className="f-start">
                                    <AppButton1
                                        variant="h6"
                                        style={{ fontFamily: "Gilroy-bold" }}
                                        text={"Let's Talk"}
                                        onClick={handleClickScheduleMeeting}
                                    />
                                    <PopupButton
                                        ref={scheduleMeetingModalRef}
                                        url="https://calendly.com/appretive"
                                        rootElement={document.getElementById("root")}
                                        styles={{ display: "none" }}
                                        text=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="review-section">
                            <div className="page-section" style={{ paddingTop: 0, paddingLeft: 0 }}>
                                <div className="bg-w shadow review-box">
                                    {/* <h2
                                        className="mb2 t-center"
                                        style={{ fontSize: "36px", fontWeight: "600", padding: "20px" }}
                                    > */}
                                    <Typography
                                        className="mb1 mt1 t-center clr-w"
                                        variant="h4"
                                        component="h3"
                                        fontWeight={600}
                                        style={{ fontFamily: "Gilroy-bold" }}
                                    >
                                        <span className="heading-highlighted clr-b">
                                            {" "}
                                            What’s Our <span className="clr-pm">Client</span> Say
                                        </span>{" "}
                                    </Typography>
                                    <div className="slider f-center">
                                        <ReviewSlider data={reviewsList} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PopupButton
                    ref={scheduleMeetingModalRef}
                    url="https://calendly.com/appretive"
                    rootElement={document.getElementById("root")}
                    styles={{ display: "none" }}
                    text=""
                />
            </div>
        </div>
    );
};

export default Home;
