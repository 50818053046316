import React, { useEffect, useRef, useState } from "react";
import { AppButton1, ReviewSlider } from "../../components";
import { PopupButton } from "react-calendly";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import config from "../../config/config";

import "../../assets/css/mobileAppDev.css";
import { getAllProject, getReviews } from "../../apis";
import Achieved from "../Home/component/Achieved";

const data = [
    {
        title: "eCommerce technology guidance",
        description:
            "Our own specialists in planning and the auditing process, along with our skilled eCommerce consultants, offer the best solutions for backend automation and application migration.",
        image: "/img/eCommerce-technology-consultation.svg",
    },
    {
        title: "Skilled eCommerce developers",
        description:
            "The top eCommerce developers at Appretive Infosoft have years of expertise creating unique eCommerce platforms that provide organizations with the best possible outcomes.",
        image: "/img/Experienced-eCommerce-developers.svg",
    },

    {
        title: "eCommerce application devoted to the user",
        description:
            "Appretive Infosoft offers user-cantered eCommerce web development services. We provide your clients customized online purchasing.",
        image: "/img/User-centric-eCommerce-Application.svg",
    },
    {
        title: "Milestones & reporting on Development",
        description:
            "We assist our clients with timely reporting on the project development process and the milestones agreed upon, following to the timeframes specified.",
        image: "/img/Milestones-reporting-on-Development.svg",
    },

    {
        title: "Services for mCommerce development",
        description:
            "We provide a full mCommerce app creation service, including the registration procedure, wish list button, bespoke branding, and Google Analytics integration.",
        image: "/img/mCommerce-development-services.svg",
    },
    {
        title: "Complete eCommerce solutions",
        description:
            "For more than ten years, our business has been a well-known supplier of IT solutions. Hire eCommerce developers, together with analysts and designers.",
        image: "/img/End-to-end-eCommerce-solutions.svg",
    },
];

const frontEndTechnology = [
    {
        title: "Apple",
        img: "/img/apple-service.png",
    },
    {
        title: "Android",
        img: "/img/android-service.png",
    },
    {
        title: "Flutter",
        img: "/img/flutter-service.png",
    },
    {
        title: "React Native",
        img: "/img/React-service.png",
    },
    {
        title: "Ionic",
        img: "/img/iconic-service.png",
    },
];

const backendTechnologies = [
    {
        title: "Node Js",
        img: "/img/node-service.png",
    },
    {
        title: "Express Js",
        img: "/img/express-js-logo.png",
    },

    {
        title: "Python",
        img: "/img/Python-service.png",
    },

    {
        title: "Nest JS",
        img: "/img/nestjs-service.png",
    },
    {
        title: "Laravel",
        img: "/img/Laravel-service.png",
    },

    {
        title: "Java",
        img: "/img/java-service.png",
    },

    {
        title: "Django",
        img: "/img/django-service.png",
    },

    {
        title: "Rails",
        img: "/img/rails-service.png",
    },
    {
        title: "Ruby",
        img: "/img/Ruby-service.png",
    },
];

const Databases = [
    {
        title: "MongoDB",
        img: "/img/mongodb-service.png",
    },
    {
        title: "MySql",
        img: "/img/Mysql-service.png",
    },
    {
        title: "PostgreSQL",
        img: "/img/Postgresql-service.png",
    },
    {
        title: "Firebase",
        img: "/img/firebase-service.png",
    },
    {
        title: "Realm",
        img: "/img/realm-service.png",
    },
    {
        title: "SQL Lite",
        img: "/img/SQLite-service.png",
    },
];

const ECommerceDev = () => {
    const [projectList, setProjectList] = useState([]);
    const [reviewsList, setReviewsList] = useState([]);
    //   const [activeCategory] = useState("software-development");
    //   const [page, setPage] = useState({
    //     page: 1,
    //     perPage: 10,
    //     totalPages: 1,
    //     loading: false,
    //   });
    //   const [blogList, setBlogList] = useState({ blogList: [] });

    //   const fetchBlogs = async (params, headers) => {
    //     setPage({ ...page, loading: true });
    //     try {
    //       const { data } = await getBlogs(params, headers);
    //       if (data.status !== "success") throw new Error(data.message);

    //       setBlogList({ blogList: data?.data?.blog });
    //       setPage({ ...page, page: 1, totalPages: data.data.totalPage });
    //     } catch (err) {
    //       console.log(err);
    //     } finally {
    //       setPage((prev) => ({ ...prev, loading: false }));
    //     }
    //   };

    //   const moreBlogs = async (params, headers) => {
    //     setPage({ ...page, loading: true });
    //     try {
    //       const { data } = await getBlogs(params, headers);
    //       if (data.status !== "success") throw new Error(data.message);

    //       setBlogList((prev) => ({
    //         blogList: [...prev.blogList, ...data?.data.blog],
    //       }));
    //       // setBlogList((prev) => ({ blogList: [...prev.blogList, ...data?.data.blog] }));
    //       setPage((prev) => ({ ...prev, totalPages: data.data.totalPage }));
    //     } catch (err) {
    //       console.log(err);
    //     } finally {
    //       setPage((prev) => ({ ...prev, loading: false }));
    //     }
    //   };

    const scheduleMeetingModalRef = useRef(null);
    const handleClickScheduleMeeting = (e) => {
        scheduleMeetingModalRef.current.onClick(e);
    };
    const fetchReviews = async (params, headers) => {
        try {
            const { data } = await getReviews(params, headers);
            if (data.status !== "success") throw new Error(data.message);

            setReviewsList(data?.data?.Review);
        } catch (err) {
            console.error(err.message);
        }
    };

    const fatchProjects = async (params, headers) => {
        try {
            const { data } = await getAllProject(params, headers);
            if (data.status !== "success") throw new Error(data.message);

            setProjectList(data?.data?.Projects);
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        fatchProjects();
        fetchReviews();
        // fetchBlogs({ page: 1, tag: activeCategory, limit: 10 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="mt-16">
                <div className="flex flex-wrap-reverse items-center justify-evenly sm:mx-[15px] md:mx-[20px] lg:mx-[50px] px-4 lg:my-10 my-6 pt-12 bg-white">
                    <div className="w-full  lg:max-w-[50%]">
                        <h1 className=" text-[30px]  md:text-[2.5vw] font-bold font-poppins  text-start mb-4">
                            Ecommerce software <br /> Development Company
                        </h1>
                        <p className="text-justify font-poppins font-[400] text-[18px] mb-6">
                            With eCommerce growing in importance within the global retail sector, Appretive Infosoft
                            establishes itself as a leading eCommerce app development company by offering excellent
                            solutions. We create specialized shopping solutions to assist the company expand its
                            customer base considerably and attract customers from around the world.
                        </p>

                        <ul className="list-none space-y-3 text-left my-10 text-[18px] font-poppins font-[400] ">
                            <li className="flex items-start">
                                <span className="inline-block min-w-2 h-2 bg-black rounded-full mr-3 mt-2"></span>
                                <span>Customized online purchasing options. </span>
                            </li>
                            <li className="flex items-start ">
                                <span className="inline-block min-w-2 h-2 bg-black rounded-full mr-3 mt-2"></span>
                                <span>Engage and converse with your clients. </span>
                            </li>
                            <li className="flex items-start">
                                <span className="inline-block min-w-2 h-2 bg-black rounded-full mr-3 mt-2"></span>
                                <span>Better returns with lower operating costs. </span>
                            </li>
                        </ul>
                        <div className="f-start">
                            <AppButton1
                                variant="h6"
                                style={{ fontFamily: "Gilroy-bold" }}
                                text={"Let's Talk"}
                                onClick={handleClickScheduleMeeting}
                            />
                            <PopupButton
                                ref={scheduleMeetingModalRef}
                                url="https://calendly.com/appretive"
                                rootElement={document.getElementById("root")}
                                styles={{ display: "none" }}
                                text=""
                            />
                        </div>
                    </div>
                    <div className="relative">
                        <img
                            src="/img/ecommerce_development.png"
                            alt="Ecommerce App Development"
                            className="w-full max-w-md mx-auto"
                        />
                    </div>
                </div>

                <div className="my-12 max-w-[75%] mx-auto">
                    <h1 className="text-[30px] text-center md:text-[2.5vw] font-bold font-poppins   my-6">
                        E-Commerce Development Tech
                    </h1>
                    <div className="my-6">
                        {" "}
                        <h1 className=" font-bold text-[20px]">FrontEnd Technology</h1>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-2 mx-auto place-content-center">
                        {frontEndTechnology.map((tech, index) => (
                            <div
                                key={index}
                                className="flex flex-col items-center justify-center gap-2 p-4 bg-white sm:w-full border-[1px] md:max-w-[250px] rounded-lg shadow-lg  transform transition-transform duration-300 hover:scale-105 hover:shadow-xl group"
                            >
                                <div className="mb-1">
                                    <img className="h-10 w-10" src={tech.img} alt="img" />
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-xl font-semibold ">{tech.title}</h2>
                                    <div className="hidden group-hover:block transition-transform duration-300 ease-in-out border-b-2 border-blue-500"></div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="my-6">
                        {" "}
                        <h1 className=" font-bold text-[20px]">Backend Technology</h1>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-2 mx-auto place-content-center">
                        {backendTechnologies.map((tech, index) => (
                            <div
                                key={index}
                                className="flex flex-col items-center justify-center gap-2 p-4 bg-white sm:w-full border-[1px] md:max-w-[250px] rounded-lg shadow-lg  transform transition-transform duration-300 hover:scale-105 hover:shadow-xl group"
                            >
                                <div className="mb-1">
                                    <img className="h-10 w-10" src={tech.img} alt="img" />
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-xl font-semibold ">{tech.title}</h2>
                                    <div className="hidden group-hover:block transition-transform duration-300 ease-in-out border-b-2 border-blue-500"></div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="my-6">
                        {" "}
                        <h1 className=" font-bold text-[20px]">Databases</h1>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-2 mx-auto place-content-center">
                        {Databases.map((tech, index) => (
                            <div
                                key={index}
                                className="flex flex-col items-center justify-center gap-2 p-4 bg-white sm:w-full border-[1px] md:max-w-[250px] rounded-lg shadow-lg  transform transition-transform duration-300 hover:scale-105 hover:shadow-xl group"
                            >
                                <div className="mb-1">
                                    <img className="h-10 w-10" src={tech.img} alt="img" />
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-xl font-semibold ">{tech.title}</h2>
                                    <div className="hidden group-hover:block transition-transform duration-300 ease-in-out border-b-2 border-blue-500"></div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="w-full ">
                    <div className="text-center mx-auto max-w-[75%]">
                        <h1 className="text-[30px] lg:text-[2.5vw] font-bold font-poppins mb-4 capitalize">
                            Industries that use our custom apps and got satisfied
                        </h1>
                        <p className="text-[20px] leading-6 text-justify md:text-center mb-8">
                            Our final product reflects our technological expertise, successful work method, and
                            abilities.
                        </p>
                    </div>
                    <div className="page-section">
                        <div className="slider">
                            <Swiper
                                slidesPerView={"auto"}
                                spaceBetween={30}
                                modules={[Autoplay, Pagination]}
                                autoplay={{
                                    delay: 3500,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    el: ".cards-pagination",
                                    clickable: true,
                                    // renderBullet: (index, className) => {
                                    //     return `<span className="${className}"></span>`;
                                    // },
                                }}
                            >
                                {projectList?.map((card, i) => (
                                    <SwiperSlide key={i}>
                                        <div
                                            className="card flex flex-wrap justify-around "
                                            style={{ backgroundColor: card.bgColor || "#ffa121" }}
                                        >
                                            <div className="card-body max-w-[48%] px-6">
                                                <div className="card-heading">
                                                    <Typography
                                                        variant="h4"
                                                        component="h4"
                                                        color={card.textColor || "text.main"}
                                                        fontWeight={600}
                                                        style={{
                                                            fontFamily: "Gilroy-bold",
                                                            fontSize: "40px",
                                                        }}
                                                    >
                                                        {card.name}
                                                    </Typography>
                                                </div>
                                                <Typography
                                                    className="card-description clr-pl poppins-fonts ellipsis-3line "
                                                    style={{ fontSize: "18px" }}
                                                    variant="h6"
                                                    component="p"
                                                    color="text.light"
                                                    dangerouslySetInnerHTML={{
                                                        __html: card?.desc?.split("</p>")[0],
                                                    }}
                                                >
                                                    {/* {card.desc} */}
                                                </Typography>
                                                <Link to={`/portfolio/${card.slug}`}>
                                                    <div className="card-btn">
                                                        <p
                                                            className="btn-text poppins-fonts"
                                                            style={{
                                                                color: card.fontColor || "#000000",
                                                            }}
                                                        >
                                                            Read More
                                                        </p>
                                                        <svg
                                                            width="47"
                                                            height="28"
                                                            viewBox="0 0 67 28"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M2 26.5058L13.9422 16.4188C15.3526 15.2275 15.3526 13.2782 13.9422 12.087L2 2"
                                                                stroke={card.fontColor || "#000000"}
                                                                strokeWidth="2.5"
                                                                strokeMiterlimit="10"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M19 26.5058L30.0236 16.4188C31.3255 15.2275 31.3255 13.2782 30.0236 12.087L19 2"
                                                                stroke={card.fontColor || "#000000"}
                                                                strokeOpacity="0.8"
                                                                strokeWidth="2.5"
                                                                strokeMiterlimit="10"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M35 26.5058L46.9422 16.4188C48.3526 15.2275 48.3526 13.2782 46.9422 12.087L35 2"
                                                                stroke={card.fontColor || "#000000"}
                                                                strokeOpacity="0.6"
                                                                strokeWidth="2.5"
                                                                strokeMiterlimit="10"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M52 26.5058L63.9422 16.4188C65.3526 15.2275 65.3526 13.2782 63.9422 12.087L52 2"
                                                                stroke={card.fontColor || "#000000"}
                                                                strokeOpacity="0.4"
                                                                strokeWidth="2.5"
                                                                strokeMiterlimit="10"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="card-header d-flex">
                                                {/* <img
                          className="card-image max-w-[40%] "
                          src={config.apiUrl + card.heroImage}
                          alt={card.name}
                        /> */}
                                                {Array.isArray(card.heroImage) && card.heroImage.length > 0 ? (
                                                    <img
                                                        className="card-image max-w-[40%] "
                                                        src={`${config.apiUrl}${card.heroImage[0]}`}
                                                        alt={card?.title}
                                                    />
                                                ) : (
                                                    <img
                                                        className="card-image max-w-[40%] "
                                                        src={`${config.apiUrl}${card.heroImage}`}
                                                        alt={card?.title}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="cards-pagination f-center mt3"></div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="mx-auto my-7  ">
                        <h1 className="mx-3 text-center text-[30px] lg:text-[2.5vw] font-bold font-poppins mb-4 capitalize">
                            our ECommerce Development Process Step By Step
                        </h1>
                        <p className="text-[19px] font-normal font-poppins text-justify px-5 mx-auto md:text-center max-w-[85%]">
                            With an estimated 2.5 billion consumers choosing to buy products from eCommerce stores
                            globally, Appretive Infosoft, an eCommerce development company, assists you in
                            differentiating your online store from the competition. With hundreds of websites launched
                            on the World Wide Web every day, we create an eye-catching eCommerce site that draws in and
                            convinces users.
                        </p>
                    </div>

                    <div className="flex flex-wrap justify-center max-w-[90%] overflow-x-auto mx-auto">
                        <div className="workflow__slider flex  py-4 px-1 md:px-0 overflow-x-auto">
                            <div className="workflow__slide max-w-md  p-5  bg-white rounded-md ">
                                <Typography variant="h4" className="workflow__step">
                                    step 1
                                </Typography>
                                {/* <Typography variant="h5" className="workflow__number">
                  1
                </Typography> */}
                                <h1 className="text-[95px] font-bold text-[#cfcece] font-poppins">1</h1>
                                <Typography variant="h6" className="workflow__title">
                                    Technical reliability
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        lineHeight: "24px",
                                        fontFamily: "poppins",
                                        fontWeight: 500,
                                    }}
                                >
                                    Our skilled consultants assist you in launching your company online by developing
                                    effective eCommerce strategies about B2B or B2C, the type of eCommerce, and the
                                    general appearance of the portal, in order to provide the ideal solution with the
                                    appropriate features and resources.
                                </Typography>
                            </div>
                            <div className="workflow__slide max-w-md p-4 bg-white rounded-md">
                                <Typography variant="h4" className="workflow__step">
                                    step 2
                                </Typography>
                                {/* <Typography variant="h5" className="workflow__number">
                  2
                </Typography> */}
                                <h1 className="text-[95px] font-bold text-[#cfcece] font-poppins">2</h1>
                                <Typography variant="h6" className="workflow__title">
                                    Appropriate platform
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        lineHeight: "24px",
                                        fontFamily: "poppins",
                                        fontWeight: 500,
                                    }}
                                >
                                    Particular eCommerce platforms have a specific function and steer your company in
                                    the right direction. We choose a platform for mCommerce or eCommerce web development
                                    based on your business objectives, budget, and preferences.
                                </Typography>
                            </div>
                            <div className="workflow__slide max-w-md p-4 bg-white rounded-md">
                                <Typography variant="h4" className="workflow__step">
                                    step 3
                                </Typography>
                                {/* <Typography variant="h5" className="workflow__number">
                  3
                </Typography> */}
                                <h1 className="text-[95px] font-bold text-[#cfcece] font-poppins">3</h1>
                                <Typography variant="h6" className="workflow__title">
                                    Design & develop
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        lineHeight: "24px",
                                        fontFamily: "poppins",
                                        fontWeight: 500,
                                    }}
                                >
                                    The main eCommerce developers at Appretive Infosoft apply their years of expertise
                                    to create a stable, scalable, and sales-focused eCommerce portal for your company
                                    after the viability assessment and appropriate platform selection.
                                </Typography>
                            </div>
                            <div className="workflow__slide max-w-md p-4 bg-white rounded-md">
                                <Typography variant="h4" className="workflow__step">
                                    step 4
                                </Typography>
                                {/* <Typography variant="h5" className="workflow__number">
                  4
                </Typography> */}
                                <h1 className="text-[95px] font-bold text-[#cfcece] font-poppins">4</h1>
                                <Typography variant="h6" className="workflow__title">
                                    QA & launch
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        lineHeight: "24px",
                                        fontFamily: "poppins",
                                        fontWeight: 500,
                                    }}
                                >
                                    Before sending the finished product to you, we inspect it using rigorous testing
                                    methods. Accurate testing is done on the portal's functionality, ergonomically
                                    chosen designs, and loading times. Your concerns are handled professionally.
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-16">
                    <div className="mx-auto max-w-[80%]">
                        <h1 className="text-[30px] text-justify md:text-center lg:text-[2.5vw] font-bold font-poppins mb-4">
                            What really Makes Appretive Infosoft the Best Indian <br /> E-commerce software Development
                            Company?
                        </h1>

                        <p className="text-[20px] font-normal font-poppins text-justify md:text-center">
                            Appretive Infosoft is a leading outsourced e-commerce software app development company with
                            more than 100 talented developers and a global clientele. We develop unique, award-winning
                            apps that promote business success for businesses of all kinds.
                        </p>
                    </div>

                    <Achieved />
                </div>

                <div className=" flex gap-2 flex-wrap justify-around  items-center text-center mt-10 mb-12 max-w-[70%] mx-auto p-7 border-[15px] border-blue-500 rounded-xl shadow-md round">
                    <p className="text-[22px] font-bold font-poppins">Request a free quote in less than a minute</p>
                    <div className="f-start">
                        <AppButton1
                            variant="h6"
                            style={{ fontFamily: "Gilroy-bold" }}
                            text={"Let's Talk"}
                            onClick={handleClickScheduleMeeting}
                        />
                        <PopupButton
                            ref={scheduleMeetingModalRef}
                            url="https://calendly.com/appretive"
                            rootElement={document.getElementById("root")}
                            styles={{ display: "none" }}
                            text=""
                        />
                    </div>
                </div>

                <div className="whychoose">
                    <div className="mx-auto text-center max-w-[80%]">
                        <h1 className="text-[30px] text-justify md:text-center lg:text-[2.5vw] font-bold font-poppins mb-4">
                            Why Appretive Infosoft for E-commerce Software Development ?
                        </h1>
                        <p className="text-[19px]  text-justify md:text-center  mx-auto font-poppins font-[400]">
                            At Appretive Infosoft, receive a competitive advantage in eCommerce development. Make it
                            function as a catalyst to spur the expansion of your company.
                        </p>
                    </div>
                    <div className="my-10 max-w-[75%] mx-auto">
                        <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mx-auto place-content-center">
                            {data.map((service, index) => (
                                <div
                                    key={index}
                                    className="p-5 flex flex-col justify-center items-center text-center bg-white sm:w-full border-[1px] md:max-w-[400px] rounded-lg shadow-lg  transform transition-transform ease-in-out duration-300 hover:scale-105 hover:shadow-xl group"
                                >
                                    <div className="mb-[15px]">
                                        <img src={service.image} className="h-[100px] w-[100px]" alt="img" />
                                    </div>
                                    <div className="my-4">
                                        <h2 className="text-[24px]  font-semibold  capitalize">{service.title}</h2>
                                        <div className="hidden transition-transform duration-2000 ease-in-out border-b-2 border-blue-500 group-hover:block "></div>
                                    </div>
                                    <p className="capitalize font-poppins font-[500] my-3 text-[16px] text-center">
                                        {service.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="clientReview my-10 mb-[-100px] lg:mb-[100px]">
                    <div className="mx-auto text-center">
                        <h1 className="text-[26px] text-center lg:text-[2.5vw] font-bold font-poppins mb-4 capitalize">
                            Some of our happy clients
                        </h1>
                        <p className=" px-5 text-[19px] font-[400] font-poppins text-center">
                            Our clients' reviews demonstrate how satisfied they are with our services
                        </p>
                    </div>
                    <div className="contact-review mb-[50px] md:mb-[70px] lg:mb-[130px]">
                        <div className="page-section contact-review-flex sm:flex flex-wrap justify-evenly">
                            <div className={"contact-section"}>
                                <div className={`page-section`}>
                                    <Typography
                                        className="mb3 t-start clr-w font-bold"
                                        variant="h4"
                                        component="h3"
                                        fontWeight={900}
                                        style={{ fontFamily: "Gilroy-bold" }}
                                    >
                                        Let’s Turn
                                        <span className="clr-pm">Your Ideas</span> into Reality
                                    </Typography>
                                    <Typography
                                        className="mb3 t-start clr-w"
                                        variant="h6"
                                        component="p"
                                        style={{ fontFamily: "Gilroy-bold" }}
                                    >
                                        Let's innovate for your technology needs with Appretive Infotech.
                                    </Typography>
                                    <div className="f-start">
                                        <AppButton1
                                            variant="h6"
                                            style={{ fontFamily: "Gilroy-bold" }}
                                            text={"Let's Talk"}
                                            onClick={handleClickScheduleMeeting}
                                        />
                                        <PopupButton
                                            ref={scheduleMeetingModalRef}
                                            url="https://calendly.com/appretive"
                                            rootElement={document.getElementById("root")}
                                            styles={{ display: "none" }}
                                            text=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="review-section review-section lg:mb-[-180px]">
                                <div className="page-section" style={{ paddingTop: 0, paddingLeft: 0 }}>
                                    <div className="bg-w shadow review-box w-[95%]">
                                        {/* <h2
                                        className="mb2 t-center"
                                        style={{ fontSize: "36px", fontWeight: "600", padding: "20px" }}
                                    > */}
                                        <Typography
                                            className="mb1 mt1 t-center clr-w"
                                            variant="h4"
                                            component="h3"
                                            fontWeight={600}
                                            style={{ fontFamily: "Gilroy-bold" }}
                                        >
                                            <span className="heading-highlighted clr-b">
                                                {" "}
                                                What’s Our <span className="clr-pm">Client</span> Say
                                            </span>{" "}
                                        </Typography>
                                        <div className="slider f-center">
                                            <ReviewSlider data={reviewsList} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PopupButton
                        ref={scheduleMeetingModalRef}
                        url="https://calendly.com/appretive"
                        rootElement={document.getElementById("root")}
                        styles={{ display: "none" }}
                        text=""
                    />
                </div>
            </div>
        </>
    );
};

export default ECommerceDev;
