import React, { useEffect, useRef, useState } from "react";
import { AppButton1, ReviewSlider } from "../../components";
import { PopupButton } from "react-calendly";
import "swiper/swiper-bundle.min.css";
import "swiper/css";
import "swiper/css/pagination";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import config from "../../config/config";

import "../../assets/css/mobileAppDev.css";
import { getBlogs, getReviews } from "../../apis";
import { BsClock } from "react-icons/bs";
import { FaRegCalendarAlt } from "react-icons/fa";
import formatDate from "../../utils/formatDate";

const data = [
  {
    title: "Powerful & Experienced",
    description:
      "capability in integrating hardware and software depending on the state of various industries to link tangible objects and machinery. Our app developers are experts in IoT app development, having created numerous IoT apps. We create scalable IoT applications to streamline operations.",
    image: "/img/Scalable-Experienced.svg",
  },
  {
    title: "Better Productivity",
    description:
      "Possessing the ability to assess your company's potential, conduct flawless analysis and research, find potential customers, and pinpoint other crucial elements for your enterprise in order to articulate a strong IoT strategy is beneficial for improving your productivity.",
    image: "/img/Improved-Efficiency.svg",
  },

  {
    title: "Advance Tool & Technology",
    description:
      "In order to satisfy the requirements of any difficult development and accomplish corporate objectives and goals, we have a state-of-the-art infrastructure and knowledgeable developers, designers, and technocrats who assist us with the newest tools, technology, and platforms.",
    image: "/img/Latest-Tools-and-Technology.svg",
  },
];

const sections = [
  {
    title:
      "Learning the basic principles of IoT is necessary for maximizing its benefits",
    content: `
     As the Internet of Things (IoT) continues to evolve and become more sophisticated, there has been a significant shift in how people connect with each other and with objects. The main goal of the Internet of Things is to facilitate data exchange and communication amongst things that are linked to the network. It is feasible for humans and objects, as well as humans and humans, to interact in this way. Now you may have your gadgets speak for you in a way that is more desirable and intelligent. We work on the clever idea of utilizing IoT to effectively connect various items. Our work serves as evidence of the creativity we contribute to the field of technology.
    `,
  },
  {
    title: "How to Use the Internet of Things in the Real World ?",
    content: `
    The Internet of Things, or IoT, is a network of connected everyday objects that can transmit and receive data. This data may be used in a variety of real-world applications to improve productivity and convenience. Smart homes offer enhanced comfort, energy efficiency, and security through the remote control of Internet of Things equipment such as security systems, lights, and thermostats using smartphone apps. Wearable Internet of Things (IoT) devices in the healthcare industry track patients' vital signs in real-time, enabling prompt medical treatments and proactive health management. In order to minimize downtime and boost productivity, industrial IoT (IIoT) is utilized in manufacturing to track equipment performance, anticipate maintenance requirements, and improve production processes. IoT sensors in agriculture are used to assess weather and soil moisture, enabling precision farming techniques that enhance crop yields and resource management.
    `,
  },
  {
    title:
      "Why Include Wearables, Gadgets, and Devices with Your Mobile Applications?",
    content: `
     The ecosystem of IoT is growing every day. There is a great deal of promise for fantastic user experiences when mobile applications are integrated with gadgets and devices. With the use of automation, quality assurance testing, and the integration of sensors, cloud services, devices, and data, Internet of Things app development companies can add new features. App tethering is another way to share the user experience across multiple gadgets and smartphones. Through the use of various Internet of Things types, technology can assist a wide range of industries, including the food, shipping, manufacturing, and healthcare sectors. Employee productivity increases and labor costs are reduced with the use of connected devices.
    `,
  },
  {
    title: "Growing Popularity of IoT Technology",
    content: `
      With the use of data gathered from devices, the Internet of Things enables businesses to create new opportunities. By meeting the diverse needs of your clientele, you may excite them with creative solutions. IoT makes it possible to employ technology to more effectively target consumer wants, which improves the efficiency with which products reach the market. Its targeted approach to comprehending and applying actual data to your business helps you maintain an advantage in the cutthroat industry. Plus much more, all with better execution and lower costs. What more is required of a firm beyond cost reduction?
    `,
  },
];

const technologies = [
  {
    title: "React",
    img: "/img/React-service.png",
  },
  {
    title: "Angular",
    img: "/img/Angular-service.png",
  },
  {
    title: "Flutter",
    img: "/img/flutter-service.png",
  },
  {
    title: "GraphQL",
    img: "/img/GraphQL_Logo.webp",
  },
];
const Databases = [
  {
    title: "MongoDB",
    img: "/img/mongodb-service.png",
  },
  {
    title: "MySql",
    img: "/img/Mysql-service.png",
  },
  {
    title: "PostgreSQL",
    img: "/img/Postgresql-service.png",
  },
  {
    title: "Firebase",
    img: "/img/firebase-service.png",
  },
  {
    title: "Realm",
    img: "/img/realm-service.png",
  },
  {
    title: "SQL Lite",
    img: "/img/SQLite-service.png",
  },
];

const cloudPlatform = [
  {
    title: "AWS",
    img: "/img/aws-service.png",
  },
  {
    title: "Cpanel",
    img: "/img/cpanel-service.png",
  },
  {
    title: "Digital Ocean",
    img: "/img/DigitalOcean-service.png",
  },
  {
    title: "Docker",
    img: "/img/Docker-service.png",
  },
  {
    title: "Vercel",
    img: "/img/vercel-service.png",
  },
  {
    title: "Heroku",
    img: "/img/heroku-service.webp",
  },
  {
    title: "Hostgator",
    img: "/img/hostgator-service.png",
  },
  {
    title: "Jenkins",
    img: "/img/Jenkins-service.png",
  },
  {
    title: "Kubernetes",
    img: "/img/Kubernetes-service.png",
  },
];

const IOT = () => {
  const [reviewsList, setReviewsList] = useState([]);
  const [activeCategory] = useState("iot");
  const [page, setPage] = useState({
    page: 1,
    perPage: 10,
    totalPages: 1,
    loading: false,
  });
  const [blogList, setBlogList] = useState({ blogList: [] });

  const fetchBlogs = async (params, headers) => {
    setPage({ ...page, loading: true });
    try {
      const { data } = await getBlogs(params, headers);
      if (data.status !== "success") throw new Error(data.message);

      setBlogList({ blogList: data?.data?.blog });
      setPage({ ...page, page: 1, totalPages: data.data.totalPage });
    } catch (err) {
      console.log(err);
    } finally {
      setPage((prev) => ({ ...prev, loading: false }));
    }
  };

  const moreBlogs = async (params, headers) => {
    setPage({ ...page, loading: true });
    try {
      const { data } = await getBlogs(params, headers);
      if (data.status !== "success") throw new Error(data.message);

      setBlogList((prev) => ({
        blogList: [...prev.blogList, ...data?.data.blog],
      }));
      // setBlogList((prev) => ({ blogList: [...prev.blogList, ...data?.data.blog] }));
      setPage((prev) => ({ ...prev, totalPages: data.data.totalPage }));
    } catch (err) {
      console.log(err);
    } finally {
      setPage((prev) => ({ ...prev, loading: false }));
    }
  };

  const scheduleMeetingModalRef = useRef(null);
  const handleClickScheduleMeeting = (e) => {
    scheduleMeetingModalRef.current.onClick(e);
  };
  const fetchReviews = async (params, headers) => {
    try {
      const { data } = await getReviews(params, headers);
      if (data.status !== "success") throw new Error(data.message);

      setReviewsList(data?.data?.Review);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    fetchReviews();
    fetchBlogs({ page: 1, tag: activeCategory, limit: 10 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="mt-16">
        <div className="flex flex-wrap-reverse items-center justify-evenly sm:mx-[15px] md:mx-[20px] lg:mx-[50px] px-4 lg:my-10 my-6 pt-12 bg-white">
          <div className="w-full  lg:max-w-[50%]">
            <h1 className=" text-[30px]  md:text-[2.5vw] font-bold font-poppins  text-start mb-4 my-5">
              IoT Development <br /> Company
            </h1>
            <p className="text-justify  font-poppins font-[400] text-[18px] mb-6">
              Our IoT development service leverages cutting-edge technology to
              seamlessly integrate the physical and digital worlds, empowering
              businesses to unlock new opportunities and efficiencies. Our team
              of professionals works together with clients to build and
              implement customized IoT solutions that are tailored to their
              specific needs, from concept to deployment. Whether we're creating
              slick user interfaces, constructing sturdy backend systems, or
              designing intelligent devices, we make sure that every facet of
              the Internet of Things ecosystem is optimized for scalability,
              security, and speed. Our all-encompassing strategy and in-depth
              knowledge of the sector allow companies to fully utilize IoT to
              spur innovation, improve operational effectiveness, and provide
              outstanding consumer experiences.
            </p>

            <div className="f-start">
              <AppButton1
                variant="h6"
                style={{ fontFamily: "Gilroy-bold" }}
                text={"Let's Talk"}
                onClick={handleClickScheduleMeeting}
              />
              <PopupButton
                ref={scheduleMeetingModalRef}
                url="https://calendly.com/appretive"
                rootElement={document.getElementById("root")}
                styles={{ display: "none" }}
                text=""
              />
            </div>
          </div>
          <div className="relative">
            <img
              src="/img/IoT.png"
              alt="iot"
              className="w-full max-w-md mx-auto "
            />
          </div>
        </div>

        <div className="my-12 max-w-[75%] mx-auto">
          <h1 className="text-[30px] text-center  md:text-[2.5vw] font-bold font-poppins  my-6">
            IOT of Things Development Tech
          </h1>
          <div className="my-6">
            {" "}
            <h1 className=" font-bold text-[20px]">Technology</h1>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-2 mx-auto place-content-center">
            {technologies.map((tech, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center gap-2 p-4 bg-white sm:w-full border-[1px] md:max-w-[250px] rounded-lg shadow-lg  transform transition-transform duration-300 hover:scale-105 hover:shadow-xl group"
              >
                <div className="mb-1">
                  <img className="h-10 w-10" src={tech.img} alt="img" />
                </div>
                <div className="mb-2">
                  <h2 className="text-xl font-semibold font-poppins ">
                    {tech.title}
                  </h2>
                  <div className="hidden group-hover:block transition-transform duration-300 ease-in-out border-b-2 border-blue-500"></div>
                </div>
              </div>
            ))}
          </div>
          <div className="my-6">
            {" "}
            <h1 className=" font-bold text-[20px]">Databases</h1>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-2 mx-auto place-content-center">
            {Databases.map((tech, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center gap-2 p-4 bg-white sm:w-full border-[1px] md:max-w-[250px] rounded-lg shadow-lg  transform transition-transform duration-300 hover:scale-105 hover:shadow-xl group"
              >
                <div className="mb-1">
                  <img className="h-10 w-10" src={tech.img} alt="img" />
                </div>
                <div className="mb-2">
                  <h2 className="text-xl font-semibold font-poppins ">
                    {tech.title}
                  </h2>
                  <div className="hidden group-hover:block transition-transform duration-300 ease-in-out border-b-2 border-blue-500"></div>
                </div>
              </div>
            ))}
          </div>
          <div className="my-6">
            {" "}
            <h1 className=" font-bold text-[20px]">Cloud & Devops</h1>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-2 mx-auto place-content-center">
            {cloudPlatform.map((tech, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center gap-2 p-4 bg-white sm:w-full border-[1px] md:max-w-[250px] rounded-lg shadow-lg  transform transition-transform duration-300 hover:scale-105 hover:shadow-xl group"
              >
                <div className="mb-1">
                  <img className="h-10 w-10" src={tech.img} alt="img" />
                </div>
                <div className="mb-2">
                  <h2 className="text-xl font-semibold font-poppins ">
                    {tech.title}
                  </h2>
                  <div className="hidden group-hover:block transition-transform duration-300 ease-in-out border-b-2 border-blue-500"></div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full">
          {sections.map((section, index) => (
            <div className="text-center mx-auto max-w-[70%]" key={index}>
              <h1 className="text-[30px] lg:text-[2.5vw] font-bold font-poppins mb-4">
                {section.title}
              </h1>
              <p
                className="text-[18px]  font-poppins text-justify px-1 my-6 lg:my-10 mx-auto font-[400]
               md:text-center leading-9"
              >
                {section.content}
              </p>
            </div>
          ))}
        </div>

        <div className=" flex gap-2 flex-wrap justify-around  items-center text-center mt-10 mb-12 max-w-[70%] mx-auto p-7 border-[15px] border-blue-500 rounded-xl shadow-md round">
          <p className="text-[22px] font-bold font-poppins">
            Request a free quote in less than a minute
          </p>
          <div className="f-start">
            <AppButton1
              variant="h6"
              style={{ fontFamily: "Gilroy-bold" }}
              text={"Let's Talk"}
              onClick={handleClickScheduleMeeting}
            />
            <PopupButton
              ref={scheduleMeetingModalRef}
              url="https://calendly.com/appretive"
              rootElement={document.getElementById("root")}
              styles={{ display: "none" }}
              text=""
            />
          </div>
        </div>

        <div className="whychoose">
          <div className="mx-auto text-center max-w-[75%]">
            <h1 className="text-[30px] text-justify md:text-center lg:text-[2.5vw] font-bold font-poppins mb-4">
              How can you join the blockchain train with the support of
              Appretive Infosoft?
            </h1>
          </div>
          <div className="my-10 max-w-[75%] mx-auto">
            <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mx-auto place-content-center">
              {data.map((service, index) => (
                <div
                  key={index}
                  className="p-5 flex flex-col justify-center items-center text-center bg-white sm:w-full border-[1px] md:max-w-[400px] rounded-lg shadow-lg  transform transition-transform ease-in-out duration-300 hover:scale-105 hover:shadow-xl group"
                >
                  <div className="mb-[15px]">
                    <img
                      src={service.image}
                      className="h-[100px] w-[100px]"
                      alt="img"
                    />
                  </div>
                  <div className="my-4">
                    <h2 className="text-[24px]  font-semibold  capitalize">
                      {service.title}
                    </h2>
                    <div className="hidden transition-transform duration-2000 ease-in-out border-b-2 border-blue-500 group-hover:block "></div>
                  </div>
                  <p className="capitalize font-poppins font-[500] my-3 text-[16px] text-center">
                    {service.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="clientReview my-10">
          <div className="mx-auto text-center">
            <h1 className="text-[26px] text-center lg:text-[2.5vw] font-bold font-poppins mb-4 capitalize">
              Some of our happy clients
            </h1>
            <p className=" px-5 text-[19px] font-[400] font-poppins text-center">
              Our clients' reviews demonstrate how satisfied they are with our
              services
            </p>
          </div>
          <div className="contact-review mb-[50px] md:mb-[70px] lg:mb-[130px]">
            <div className="page-section contact-review-flex sm:flex flex-wrap justify-evenly">
              <div className={"contact-section"}>
                <div className={`page-section`}>
                  <Typography
                    className="mb3 t-start clr-w font-bold"
                    variant="h4"
                    component="h3"
                    fontWeight={900}
                    style={{ fontFamily: "Gilroy-bold" }}
                  >
                    Let’s Turn
                    <span className="clr-pm">Your Ideas</span> into Reality
                  </Typography>
                  <Typography
                    className="mb3 t-start clr-w"
                    variant="h6"
                    component="p"
                    style={{ fontFamily: "Gilroy-bold" }}
                  >
                    Let's innovate for your technology needs with Appretive
                    Infotech.
                  </Typography>
                  <div className="f-start">
                    <AppButton1
                      variant="h6"
                      style={{ fontFamily: "Gilroy-bold" }}
                      text={"Let's Talk"}
                      onClick={handleClickScheduleMeeting}
                    />
                    <PopupButton
                      ref={scheduleMeetingModalRef}
                      url="https://calendly.com/appretive"
                      rootElement={document.getElementById("root")}
                      styles={{ display: "none" }}
                      text=""
                    />
                  </div>
                </div>
              </div>
              <div className="review-section review-section lg:mb-[-180px]">
                <div
                  className="page-section"
                  style={{ paddingTop: 0, paddingLeft: 0 }}
                >
                  <div className="bg-w shadow review-box w-[95%]">
                    {/* <h2
                                        className="mb2 t-center"
                                        style={{ fontSize: "36px", fontWeight: "600", padding: "20px" }}
                                    > */}
                    <Typography
                      className="mb1 mt1 t-center clr-w"
                      variant="h4"
                      component="h3"
                      fontWeight={600}
                      style={{ fontFamily: "Gilroy-bold" }}
                    >
                      <span className="heading-highlighted clr-b">
                        {" "}
                        What’s Our <span className="clr-pm">Client</span> Say
                      </span>{" "}
                    </Typography>
                    <div className="slider f-center">
                      <ReviewSlider data={reviewsList} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PopupButton
            ref={scheduleMeetingModalRef}
            url="https://calendly.com/appretive"
            rootElement={document.getElementById("root")}
            styles={{ display: "none" }}
            text=""
          />
        </div>
        <div className="text-center my-2 mt-[-100px] border-[5px]  border-[#3776FF] px-8 sm:mt-[100px] max-w-fit mx-auto p-4 bg-[#d6e3ff] rounded-xl">
          <h1 className="text-[35px] font-poppins font-bold">Related Blog</h1>
        </div>
        <div className="mt-[-150px] ">
          <div className="blog-page" style={{ marginTop: "130px" }}>
            <div className="top-section">
              <div className="page-section pt0">
                {/* <Typography
                variant="h3"
                component="h2"
                className="mb2 quicksand-fonts"
                fontWeight={800}
                textAlign={"center"}
              >
                Popular Categories
              </Typography> */}
                {/* <Typography
                variant="p2"
                component="h2"
                className="mb5"
                fontWeight={400}
                textAlign={"center"}
              >
                Fresh articles about Technology and Business
              </Typography> */}
                <div className="category-container">
                  {/* {categories.map((category) => (
                  <div
                    key={category?._id}
                    className={[
                      "category",
                      activeCategory === category.value ? "active" : "",
                    ].join(" ")}
                  >
                    <Typography
                      variant="body3"
                      component="p"
                      onClick={(e) => setActiveCategory(category.value)}
                    >
                      {category.label}
                    </Typography>
                  </div>
                ))} */}
                </div>
              </div>
            </div>
            <div className="blog-section">
              <div className="page-section pt0">
                {blogList?.blogList?.length > 0 ? (
                  <div className="">
                    <div className="card-container">
                      {blogList?.blogList?.map((blog) => (
                        <div className="card" key={blog?._id}>
                          <div className="card-head">
                            <Link
                              to={`/blog/${blog?.title
                                ?.toLowerCase()
                                ?.split(" ")
                                .join("-")}`}
                              state={blog}
                            >
                              <div className="card-image">
                                <img
                                  src={config.apiUrl + blog?.blogLogo}
                                  alt={blog?.title}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </Link>
                          </div>

                          <div className="card-footer f-center">
                            <div className="d-flex align-items-center gap-1">
                              <BsClock />
                              <Typography variant="body1" component="h3">
                                {blog?.readingDuration}
                              </Typography>
                            </div>
                            <div className="d-flex align-items-center gap-1">
                              <FaRegCalendarAlt />
                              <Typography variant="body1" component="h3">
                                {formatDate(blog?.createdAt, "dd-mm-yyyy")}
                              </Typography>
                            </div>
                          </div>
                          <div
                            className="card-body t-center ellipsis-2line"
                            style={{ height: "65px" }}
                          >
                            <Link
                              to={`${blog?.title
                                ?.toLowerCase()
                                ?.split(" ")
                                .join("-")}`}
                              state={blog}
                            >
                              <Typography variant="body3" component="h3">
                                {blog?.title}
                              </Typography>
                            </Link>
                          </div>

                          {/* <Typography variant="body1" component="p" color={"#596274"}>
                                            {stripHtmlTags(blog?.description)}
                                            <Link
                                                to={`${blog?.title?.toLowerCase()?.split(" ").join("-")}`}
                                                state={blog}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    fontWeight={500}
                                                    color={"#596274"}
                                                >
                                                    {" "}
                                                    Read more...
                                                </Typography>
                                            </Link>
                                        </Typography> */}
                          <div className="f-center mt2">
                            <Link
                              to={`/blog/${blog?.title
                                ?.toLowerCase()
                                ?.split(" ")
                                .join("-")}`}
                              state={blog}
                            >
                              <AppButton1 text={"Read Blog"} />
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                    {console.log(page?.totalPages, page?.page)}
                    {page?.totalPages > page?.page && (
                      <div className="f-center mt4">
                        <AppButton1
                          text="Load More..."
                          onClick={() => {
                            moreBlogs({
                              page: page.page + 1,
                              tag: activeCategory,
                              limit: 10,
                            });
                            setPage({ ...page, page: page?.page + 1 });
                          }}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div style={{ maxWidth: "40rem", margin: "auto" }}>
                    <img src="/img/Empty.gif" alt="empty" className="w-100" />
                    {/* <div>Sorry, No Result Found!</div> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IOT;
