import React, { useEffect, useRef, useState } from "react";
import { AppButton1, ReviewSlider } from "../../components";
import { PopupButton } from "react-calendly";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import config from "../../config/config";

import "../../assets/css/mobileAppDev.css";
import { getAllProject, getBlogs, getReviews } from "../../apis";
import formatDate from "../../utils/formatDate";
import { BsClock } from "react-icons/bs";
import { FaRegCalendarAlt } from "react-icons/fa";
import Achieved from "../Home/component/Achieved";

const data = [
    {
        title: "Simple and open transactions",
        description:
            "Reputable custom software development company Appreive Infosoft is well-known for its simple and open business practices.",
        image: "/img/Easy-and-transparent-deals.svg",
    },

    {
        title: "skilled programmers",
        description:
            "Get highly skilled software developers from Appretive Infosoft if you're looking for a complete software package for the business you represent.",
        image: "/img/Enterprise-mobility-1.svg",
    },
    {
        title: "Uncompromising quality",
        description:
            "Hire the Appretive Infosoft software development team to ensure that your high-quality software product operates flawlessly.",
        image: "/img/Quality-uncompromised.svg",
    },
    {
        title: "detailed reporting",
        description:
            "We track completed tasks and fulfilled milestones using industry-standard instruments. Generate fast and reliable reporting on software development process.",
        image: "/img/Accurate-reporting.svg",
    },
    {
        title: "Complete solutions",
        description:
            "We have a team of skilled software engineers in-house that can design both mobile apps and websites.",
        image: "/img/End-to-end-solutions.svg",
    },

    {
        title: "Best support after sale",
        description:
            "We assure that your software product will function well long after it has been sold when you hire our coders for its development.",
        image: "/img/Unparalleled-after-sales-support.svg",
    },
];

const technology = [
    {
        title: "Java",
        img: "/img/java-service.png",
    },
    {
        title: "PHP",
        img: "/img/PHP-service.png",
    },
    {
        title: "Ruby",
        img: "/img/Ruby-service.png",
    },
    {
        title: "C#",
        img: "/img/cdnlogo.com_c-sharp.png",
    },
    {
        title: "C/C++",
        img: "/img/c-logo.png",
    },
    {
        title: "SQL",
        img: "/img/Mysql-service.png",
    },
    {
        title: "ASP .NET",
        img: "/img/dot-net-service.png",
    },
    {
        title: "Objective-C",
        // img: "/img/objective-c.svg",
        img: "/img/apple_objectivec_logo_icon_167846.png",
    },
];

const SoftwareDev = () => {
    const [projectList, setProjectList] = useState([]);
    const [reviewsList, setReviewsList] = useState([]);
    const [activeCategory] = useState("software-development");
    const [page, setPage] = useState({
        page: 1,
        perPage: 10,
        totalPages: 1,
        loading: false,
    });
    const [blogList, setBlogList] = useState({ blogList: [] });

    const fetchBlogs = async (params, headers) => {
        setPage({ ...page, loading: true });
        try {
            const { data } = await getBlogs(params, headers);
            if (data.status !== "success") throw new Error(data.message);

            setBlogList({ blogList: data?.data?.blog });
            setPage({ ...page, page: 1, totalPages: data.data.totalPage });
        } catch (err) {
            console.log(err);
        } finally {
            setPage((prev) => ({ ...prev, loading: false }));
        }
    };

    const moreBlogs = async (params, headers) => {
        setPage({ ...page, loading: true });
        try {
            const { data } = await getBlogs(params, headers);
            if (data.status !== "success") throw new Error(data.message);

            setBlogList((prev) => ({
                blogList: [...prev.blogList, ...data?.data.blog],
            }));
            // setBlogList((prev) => ({ blogList: [...prev.blogList, ...data?.data.blog] }));
            setPage((prev) => ({ ...prev, totalPages: data.data.totalPage }));
        } catch (err) {
            console.log(err);
        } finally {
            setPage((prev) => ({ ...prev, loading: false }));
        }
    };

    const scheduleMeetingModalRef = useRef(null);
    const handleClickScheduleMeeting = (e) => {
        scheduleMeetingModalRef.current.onClick(e);
    };
    const fetchReviews = async (params, headers) => {
        try {
            const { data } = await getReviews(params, headers);
            if (data.status !== "success") throw new Error(data.message);

            setReviewsList(data?.data?.Review);
        } catch (err) {
            console.error(err.message);
        }
    };

    const fatchProjects = async (params, headers) => {
        try {
            const { data } = await getAllProject(params, headers);
            if (data.status !== "success") throw new Error(data.message);

            setProjectList(data?.data?.Projects);
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        fatchProjects();
        fetchReviews();
        fetchBlogs({ page: 1, tag: activeCategory, limit: 10 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="mt-16">
                <div className="flex flex-wrap-reverse items-center justify-evenly sm:mx-[15px] md:mx-[20px] lg:mx-[50px] px-4 lg:my-10 my-6 pt-12 bg-white">
                    <div className="w-full  lg:max-w-[50%]">
                        <h1 className=" text-[30px]  md:text-[2.5vw] font-bold font-poppins  text-start mb-4">
                            Custom Software Development Company
                        </h1>
                        <p className="text-justify font-poppins font-[400] text-[18px] mb-6">
                            Appretive Infosoft is a top software development company that assists startups, mid-sized
                            businesses, and major corporations in developing software solutions that match with their
                            goals. We have become known for the customisation and scalability of our software
                            development services. Appretive Infosoft is an excellent software development business to
                            whom you may outsource software development, according to the greatest standards in the
                            business and internationally acknowledged methods.
                        </p>

                        <ul className="list-none space-y-3 text-left my-10 text-[19px] font-poppins font-[400] ">
                            <li className="flex items-start">
                                <span className="inline-block min-w-2 h-2 bg-black rounded-full mr-3 mt-2"></span>
                                <span>Fast Software development service </span>
                            </li>
                            <li className="flex items-start">
                                <span className="inline-block min-w-2 h-2 bg-black rounded-full mr-3 mt-2"></span>
                                <span>Custom software for an individual business</span>
                            </li>
                            <li className="flex items-start">
                                <span className="inline-block min-w-2 h-2 bg-black rounded-full mr-3 mt-2"></span>
                                <span> Applications for all sizes of business sectors </span>
                            </li>
                        </ul>
                        <div className="f-start">
                            <AppButton1
                                variant="h6"
                                style={{ fontFamily: "Gilroy-bold" }}
                                text={"Let's Talk"}
                                onClick={handleClickScheduleMeeting}
                            />
                            <PopupButton
                                ref={scheduleMeetingModalRef}
                                url="https://calendly.com/appretive"
                                rootElement={document.getElementById("root")}
                                styles={{ display: "none" }}
                                text=""
                            />
                        </div>
                    </div>
                    <div className="relative">
                        <img
                            src="/img/software_development_4.png"
                            alt="Software Development"
                            className="w-full max-w-md mx-auto"
                        />
                    </div>
                </div>

                <div className="my-12 max-w-[75%] mx-auto">
                    <h1 className="text-[30px] text-center  md:text-[2.5vw] font-bold   my-6">
                        Software Development Tech
                    </h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 mx-auto place-content-center">
                        {technology.map((tech, index) => (
                            <div
                                key={index}
                                className="flex flex-col items-center justify-center gap-2 p-4 bg-white sm:w-full border-[1px] md:max-w-[250px] rounded-lg shadow-lg  transform transition-transform duration-300 hover:scale-105 hover:shadow-xl group"
                            >
                                <div className="mb-1">
                                    <img className="h-10 w-10" src={tech.img} alt="img" />
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-xl font-semibold ">{tech.title}</h2>
                                    <div className="hidden group-hover:block transition-transform duration-300 ease-in-out border-b-2 border-blue-500"></div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="w-full ">
                    <div className="text-center mx-auto max-w-[75%]">
                        <h1 className="text-[30px] lg:text-[2.5vw] font-bold font-poppins mb-4 capitalize">
                            Industries that use our custom apps and got satisfied
                        </h1>
                        <p className="text-[20px] leading-6 text-justify md:text-center mb-8">
                            Our final product reflects our technological expertise, successful work method, and
                            abilities.
                        </p>
                    </div>
                    <div className="page-section">
                        <div className="slider">
                            <Swiper
                                slidesPerView={"auto"}
                                spaceBetween={30}
                                modules={[Autoplay, Pagination]}
                                autoplay={{
                                    delay: 3500,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    el: ".cards-pagination",
                                    clickable: true,
                                    // renderBullet: (index, className) => {
                                    //     return `<span className="${className}"></span>`;
                                    // },
                                }}
                            >
                                {projectList?.map((card, i) => (
                                    <SwiperSlide key={i}>
                                        <div
                                            className="card flex flex-wrap justify-around "
                                            style={{ backgroundColor: card.bgColor || "#ffa121" }}
                                        >
                                            <div className="card-body max-w-[48%] px-6">
                                                <div className="card-heading">
                                                    <Typography
                                                        variant="h4"
                                                        component="h4"
                                                        color={card.textColor || "text.main"}
                                                        fontWeight={600}
                                                        style={{
                                                            fontFamily: "Gilroy-bold",
                                                            fontSize: "40px",
                                                        }}
                                                    >
                                                        {card.name}
                                                    </Typography>
                                                </div>
                                                <Typography
                                                    className="card-description clr-pl poppins-fonts ellipsis-3line "
                                                    style={{ fontSize: "18px" }}
                                                    variant="h6"
                                                    component="p"
                                                    color="text.light"
                                                    dangerouslySetInnerHTML={{
                                                        __html: card?.desc?.split("</p>")[0],
                                                    }}
                                                >
                                                    {/* {card.desc} */}
                                                </Typography>
                                                <Link to={`/portfolio/${card.slug}`}>
                                                    <div className="card-btn">
                                                        <p
                                                            className="btn-text poppins-fonts"
                                                            style={{
                                                                color: card.fontColor || "#000000",
                                                            }}
                                                        >
                                                            Read More
                                                        </p>
                                                        <svg
                                                            width="47"
                                                            height="28"
                                                            viewBox="0 0 67 28"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M2 26.5058L13.9422 16.4188C15.3526 15.2275 15.3526 13.2782 13.9422 12.087L2 2"
                                                                stroke={card.fontColor || "#000000"}
                                                                strokeWidth="2.5"
                                                                strokeMiterlimit="10"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M19 26.5058L30.0236 16.4188C31.3255 15.2275 31.3255 13.2782 30.0236 12.087L19 2"
                                                                stroke={card.fontColor || "#000000"}
                                                                strokeOpacity="0.8"
                                                                strokeWidth="2.5"
                                                                strokeMiterlimit="10"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M35 26.5058L46.9422 16.4188C48.3526 15.2275 48.3526 13.2782 46.9422 12.087L35 2"
                                                                stroke={card.fontColor || "#000000"}
                                                                strokeOpacity="0.6"
                                                                strokeWidth="2.5"
                                                                strokeMiterlimit="10"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M52 26.5058L63.9422 16.4188C65.3526 15.2275 65.3526 13.2782 63.9422 12.087L52 2"
                                                                stroke={card.fontColor || "#000000"}
                                                                strokeOpacity="0.4"
                                                                strokeWidth="2.5"
                                                                strokeMiterlimit="10"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="card-header d-flex">
                                                {/* <img
                          className="card-image max-w-[40%] "
                          src={config.apiUrl + card.heroImage}
                          alt={card.name}
                        /> */}
                                                {Array.isArray(card.heroImage) && card.heroImage.length > 0 ? (
                                                    <img
                                                        className="card-image max-w-[40%] "
                                                        src={`${config.apiUrl}${card.heroImage[0]}`}
                                                        alt={card?.title}
                                                    />
                                                ) : (
                                                    <img
                                                        className="card-image max-w-[40%] "
                                                        src={`${config.apiUrl}${card.heroImage}`}
                                                        alt={card?.title}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="cards-pagination f-center mt3"></div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="mx-auto my-7 ">
                        <h1 className="mx-3 text-center text-[30px] lg:text-[2.5vw] font-bold font-poppins mb-4 capitalize">
                            Our Method for Developing Custom software
                        </h1>
                        <p className="text-[19px] font-normal font-poppins text-justify px-5 mx-auto md:text-center max-w-[85%]">
                            To create unique software applications, we use the most popular software development
                            techniques and development methodologies. Embark on a journey through every stage of the the
                            cycle of software development with our committed team of software developers as they create
                            innovative business solutions.
                        </p>
                    </div>
                    <div className="flex flex-wrap justify-center max-w-[90%] overflow-x-auto mx-auto">
                        <div className="workflow__slider flex py-4 px-1 md:px-0 overflow-x-auto">
                            <div className="workflow__slide max-w-md  p-5  bg-white rounded-md ">
                                <Typography variant="h4" className="workflow__step">
                                    step 1
                                </Typography>
                                {/* <Typography variant="h5" className="workflow__number">
                  1
                </Typography> */}
                                <h1 className="text-[95px] font-bold text-[#cfcece] font-poppins">1</h1>
                                <Typography variant="h6" className="workflow__title">
                                    Discussion
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        lineHeight: "24px",
                                        fontFamily: "poppins",
                                        fontWeight: 500,
                                    }}
                                >
                                    Detailed analysis of the business's goals
                                </Typography>
                            </div>
                            <div className="workflow__slide max-w-md p-4 bg-white rounded-md">
                                <Typography variant="h4" className="workflow__step">
                                    step 2
                                </Typography>
                                {/* <Typography variant="h5" className="workflow__number">
                  2
                </Typography> */}
                                <h1 className="text-[95px] font-bold text-[#cfcece] font-poppins">2</h1>
                                <Typography variant="h6" className="workflow__title">
                                    Planning
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        lineHeight: "24px",
                                        fontFamily: "poppins",
                                        fontWeight: 500,
                                    }}
                                >
                                    Creating a blueprint and measuring feasibility
                                </Typography>
                            </div>
                            <div className="workflow__slide max-w-md p-4 bg-white rounded-md">
                                <Typography variant="h4" className="workflow__step">
                                    step 3
                                </Typography>
                                {/* <Typography variant="h5" className="workflow__number">
                  3
                </Typography> */}
                                <h1 className="text-[95px] font-bold text-[#cfcece] font-poppins">3</h1>
                                <Typography variant="h6" className="workflow__title">
                                    Prototyping
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        lineHeight: "24px",
                                        fontFamily: "poppins",
                                        fontWeight: 500,
                                    }}
                                >
                                    Developing a scalable, accurate concept
                                </Typography>
                            </div>
                            <div className="workflow__slide max-w-md p-4 bg-white rounded-md">
                                <Typography variant="h4" className="workflow__step">
                                    step 4
                                </Typography>
                                {/* <Typography variant="h5" className="workflow__number">
                  4
                </Typography> */}
                                <h1 className="text-[95px] font-bold text-[#cfcece] font-poppins">4</h1>
                                <Typography variant="h6" className="workflow__title">
                                    Development
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        lineHeight: "24px",
                                        fontFamily: "poppins",
                                        fontWeight: 500,
                                    }}
                                >
                                    The main step of developing a website
                                </Typography>
                            </div>
                            <div className="workflow__slide max-w-md p-4 bg-white rounded-md">
                                <Typography variant="h4" className="workflow__step">
                                    step 5
                                </Typography>
                                {/* <Typography variant="h5" className="workflow__number">
                  5
                </Typography> */}
                                <h1 className="text-[95px] font-bold text-[#cfcece] font-poppins">5</h1>
                                <Typography variant="h6" className="workflow__title">
                                    Testing
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        lineHeight: "24px",
                                        fontFamily: "poppins",
                                        fontWeight: 500,
                                    }}
                                >
                                    complete testing while approving projects
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-16">
                    <div className="mx-auto max-w-[80%]">
                        <h1 className="text-[24px] text-justify md:text-center lg:text-[2.5vw] font-bold font-poppins mb-4">
                            What really Makes Appretive Infosoft the Best Indian Mobile App Development Company?
                        </h1>

                        <p className="text-[20px] font-normal font-poppins text-justify md:text-center">
                            Appretive Infosoft is a leading outsourced Mobile App Development company with more than 100
                            talented developers and a global clientele. We develop unique, award-winning apps that
                            promote business success for businesses of all kinds.
                        </p>
                    </div>

                    <Achieved />
                </div>

                <div className=" flex gap-2 flex-wrap justify-around  items-center text-center mt-10 mb-12 max-w-[70%] mx-auto p-7 border-[15px] border-blue-500 rounded-xl shadow-md round">
                    <p className="text-[22px] font-bold font-poppins">Request a free quote in less than a minute</p>
                    <div className="f-start">
                        <AppButton1
                            variant="h6"
                            style={{ fontFamily: "Gilroy-bold" }}
                            text={"Let's Talk"}
                            onClick={handleClickScheduleMeeting}
                        />
                        <PopupButton
                            ref={scheduleMeetingModalRef}
                            url="https://calendly.com/appretive"
                            rootElement={document.getElementById("root")}
                            styles={{ display: "none" }}
                            text=""
                        />
                    </div>
                </div>

                <div className="whychoose">
                    <div className="mx-auto text-center max-w-[80%]">
                        <h1 className="text-[30px] text-justify md:text-center lg:text-[2.5vw] font-poppins font-bold my-6">
                            Why choose Appretive Infosoft for Software Development Solutions?
                        </h1>
                        <p className="text-[20px] font-normal font-poppins text-justify md:text-center  mx-auto">
                            We can hire skilled groups of talented software developers who work hard on the assigned
                            software project. They keep closely to the business goals you provide in order to provide
                            the best software available. Employ experts from the top software development service
                            providers to assist you in creating custom software that is more affordable and will boost
                            your return on investment.
                        </p>
                    </div>
                    <div className="my-10 max-w-[75%] mx-auto">
                        <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mx-auto place-content-center">
                            {data.map((service, index) => (
                                <div
                                    key={index}
                                    className="p-5 flex flex-col justify-center items-center text-center bg-white sm:w-full border-[1px] md:max-w-[400px] rounded-lg shadow-lg  transform transition-transform ease-in-out duration-300 hover:scale-105 hover:shadow-xl group"
                                >
                                    <div className="mb-[15px]">
                                        <img src={service.image} className="h-[100px] w-[100px]" alt="img" />
                                    </div>
                                    <div className="my-4">
                                        <h2 className="text-[24px]  font-semibold  capitalize">{service.title}</h2>
                                        <div className="hidden transition-transform duration-2000 ease-in-out border-b-2 border-blue-500 group-hover:block "></div>
                                    </div>
                                    <p className="capitalize font-poppins font-[500] my-3 text-[16px] text-center">
                                        {service.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="clientReview my-10">
                    <div className="mx-auto text-center">
                        <h1 className="text-[26px] text-center lg:text-[2.5vw] font-bold font-poppins mb-4 capitalize">
                            Some of our happy clients
                        </h1>
                        <p className=" px-5 text-[19px] font-[400] font-poppins text-center">
                            Our clients' reviews demonstrate how satisfied they are with our services
                        </p>
                    </div>

                    <div className="contact-review mb-[50px] md:mb-[70px] lg:mb-[130px]">
                        <div className="page-section contact-review-flex sm:flex flex-wrap justify-evenly">
                            <div className={"contact-section"}>
                                <div className={`page-section`}>
                                    <Typography
                                        className="mb3 t-start clr-w font-bold"
                                        variant="h4"
                                        component="h3"
                                        fontWeight={900}
                                        style={{ fontFamily: "Gilroy-bold" }}
                                    >
                                        Let’s Turn
                                        <span className="clr-pm">Your Ideas</span> into Reality
                                    </Typography>
                                    <Typography
                                        className="mb3 t-start clr-w"
                                        variant="h6"
                                        component="p"
                                        style={{ fontFamily: "Gilroy-bold" }}
                                    >
                                        Let's innovate for your technology needs with Appretive Infotech.
                                    </Typography>
                                    <div className="f-start">
                                        <AppButton1
                                            variant="h6"
                                            style={{ fontFamily: "Gilroy-bold" }}
                                            text={"Let's Talk"}
                                            onClick={handleClickScheduleMeeting}
                                        />
                                        <PopupButton
                                            ref={scheduleMeetingModalRef}
                                            url="https://calendly.com/appretive"
                                            rootElement={document.getElementById("root")}
                                            styles={{ display: "none" }}
                                            text=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="review-section review-section lg:mb-[-180px]">
                                <div className="page-section" style={{ paddingTop: 0, paddingLeft: 0 }}>
                                    <div className="bg-w shadow review-box w-[95%]">
                                        {/* <h2
                                        className="mb2 t-center"
                                        style={{ fontSize: "36px", fontWeight: "600", padding: "20px" }}
                                    > */}
                                        <Typography
                                            className="mb1 mt1 t-center clr-w"
                                            variant="h4"
                                            component="h3"
                                            fontWeight={600}
                                            style={{ fontFamily: "Gilroy-bold" }}
                                        >
                                            <span className="heading-highlighted clr-b">
                                                {" "}
                                                What’s Our <span className="clr-pm">Client</span> Say
                                            </span>{" "}
                                        </Typography>
                                        <div className="slider f-center">
                                            <ReviewSlider data={reviewsList} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PopupButton
                        ref={scheduleMeetingModalRef}
                        url="https://calendly.com/appretive"
                        rootElement={document.getElementById("root")}
                        styles={{ display: "none" }}
                        text=""
                    />
                </div>
                <div className="text-center my-2 mt-[-100px] border-[5px]  border-[#3776FF] px-8 sm:mt-[100px] max-w-fit mx-auto p-4 bg-[#d6e3ff] rounded-xl">
                    <h1 className="text-[35px] font-poppins font-bold">Related Blog</h1>
                </div>
                <div className="mt-[-150px] ">
                    <div className="blog-page" style={{ marginTop: "130px" }}>
                        <div className="top-section">
                            <div className="page-section pt0">
                                {/* <Typography
                variant="h3"
                component="h2"
                className="mb2 quicksand-fonts"
                fontWeight={800}
                textAlign={"center"}
              >
                Popular Categories
              </Typography> */}
                                {/* <Typography
                variant="p2"
                component="h2"
                className="mb5"
                fontWeight={400}
                textAlign={"center"}
              >
                Fresh articles about Technology and Business
              </Typography> */}
                                <div className="category-container">
                                    {/* {categories.map((category) => (
                  <div
                    key={category?._id}
                    className={[
                      "category",
                      activeCategory === category.value ? "active" : "",
                    ].join(" ")}
                  >
                    <Typography
                      variant="body3"
                      component="p"
                      onClick={(e) => setActiveCategory(category.value)}
                    >
                      {category.label}
                    </Typography>
                  </div>
                ))} */}
                                </div>
                            </div>
                        </div>
                        <div className="blog-section">
                            <div className="page-section pt0">
                                {blogList?.blogList?.length > 0 ? (
                                    <div className="">
                                        <div className="card-container">
                                            {blogList?.blogList?.map((blog) => (
                                                <div className="card" key={blog?._id}>
                                                    <div className="card-head">
                                                        <Link
                                                            to={`${blog?.title?.toLowerCase()?.split(" ").join("-")}`}
                                                            state={blog}
                                                        >
                                                            <div className="card-image">
                                                                <img
                                                                    src={config.apiUrl + blog?.blogLogo}
                                                                    alt={blog?.title}
                                                                    style={{ width: "100%" }}
                                                                />
                                                            </div>
                                                        </Link>
                                                    </div>

                                                    <div className="card-footer f-center">
                                                        <div className="d-flex align-items-center gap-1">
                                                            <BsClock />
                                                            <Typography variant="body1" component="h3">
                                                                {blog?.readingDuration}
                                                            </Typography>
                                                        </div>
                                                        <div className="d-flex align-items-center gap-1">
                                                            <FaRegCalendarAlt />
                                                            <Typography variant="body1" component="h3">
                                                                {formatDate(blog?.createdAt, "dd-mm-yyyy")}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="card-body t-center ellipsis-2line"
                                                        style={{ height: "65px" }}
                                                    >
                                                        <Link
                                                            to={`${blog?.title?.toLowerCase()?.split(" ").join("-")}`}
                                                            state={blog}
                                                        >
                                                            <Typography variant="body3" component="h3">
                                                                {blog?.title}
                                                            </Typography>
                                                        </Link>
                                                    </div>

                                                    {/* <Typography variant="body1" component="p" color={"#596274"}>
                                            {stripHtmlTags(blog?.description)}
                                            <Link
                                                to={`${blog?.title?.toLowerCase()?.split(" ").join("-")}`}
                                                state={blog}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    fontWeight={500}
                                                    color={"#596274"}
                                                >
                                                    {" "}
                                                    Read more...
                                                </Typography>
                                            </Link>
                                        </Typography> */}
                                                    <div className="f-center mt2">
                                                        <Link
                                                            to={`/blog/${blog?.title
                                                                ?.toLowerCase()
                                                                ?.split(" ")
                                                                .join("-")}`}
                                                            state={blog}
                                                        >
                                                            <AppButton1 text={"Read Blog"} />
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {console.log(page?.totalPages, page?.page)}
                                        {page?.totalPages > page?.page && (
                                            <div className="f-center mt4">
                                                <AppButton1
                                                    text="Load More..."
                                                    onClick={() => {
                                                        moreBlogs({
                                                            page: page.page + 1,
                                                            tag: activeCategory,
                                                            limit: 10,
                                                        });
                                                        setPage({ ...page, page: page?.page + 1 });
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div style={{ maxWidth: "40rem", margin: "auto" }}>
                                        <img src="/img/Empty.gif" alt="empty" className="w-100" />
                                        {/* <div>Sorry, No Result Found!</div> */}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SoftwareDev;
