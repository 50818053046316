import { useEffect, useState } from "react";

import Menu from "../Menu/Menu";

import "../../assets/css/header.css";
import OffScreen from "../Menu/OffScreen";

const Header = () => {
    let [stickyHeader, setStickyHeader] = useState(false);
    const [offscreenMenuShow, setOffscreenMenuShow] = useState(false);

    const trackScroll = () => {
        document.body.onscroll = (e) => {
            if (window.scrollY > 40) {
                if (stickyHeader === true) return;
                stickyHeader = true;
                setStickyHeader(true);
            } else {
                if (stickyHeader === false) return;
                stickyHeader = false;
                setStickyHeader(false);
            }
        };
    };

    useEffect(() => {
        trackScroll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`header-container ${stickyHeader ? "sticky-header" : ""}`}>
            <header className="page-section app-header d-flex justify-content-between align-items-center">
                <div className="left">
                    <div className="logo f-center">
                        <img src="/img/logo.png" alt="logo" />
                    </div>
                </div>
                <div className="right d-flex align-items-center">
                    <div className="menu-container">
                        <div className="main">
                            <Menu />
                        </div>
                        <div className="offscreen">
                            <OffScreen open={offscreenMenuShow} setOpen={setOffscreenMenuShow} />
                        </div>
                    </div>
                    {/* <div className="spacer d-none-md" style={{ width: "25px" }}></div> */}

                    <div className="menu-btn" onClick={(e) => setOffscreenMenuShow((prev) => !prev)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </header>
        </div>
    );
};

export default Header;
