import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import "../../assets/css/button.css";

const AppButton1 = ({
  text,
  href,
  style,
  variant,
  className = "",
  ...other
}) => {
  if (href) {
    return (
      <div className={`app-button-container-1 ${className}`}>
        <Link to={href}>
          <button className="app-button-1 font-bold" style={style} {...other}>
            <Typography
              variant={variant || "body1"}
              className="font-bold"
              component="p"
            >
              {text || "Link Button"}
            </Typography>
          </button>
        </Link>
        <span></span>
      </div>
    );
  }

  return (
    <div className={`app-button-container-1 ${className}`}>
      <button className="app-button-1 font-bold" style={style} {...other}>
        <Typography
          variant={variant || "body1"}
          className="font-bold"
          component="p"
        >
          {text || "Button"}
        </Typography>
      </button>
      <span></span>
    </div>
  );
};

export default AppButton1;
