import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { getCaseStudy } from "../../apis";
import { AppButton1 } from "../../components";
import config from "../../config/config";

import "../../assets/css/portfolio.css";
import { PopupButton } from "react-calendly";

const CaseStudy = () => {
    const scheduleMeetingModalRef = useRef(null);
    const handleClickScheduleMeeting = (e) => {
        // console.log(scheduleMeetingModalRef.current);
        scheduleMeetingModalRef.current.onClick(e);
    };
    const { slug } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [caseStudy, setCaseStudy] = useState({});

    // const fetchservicess = async (params, headers) => {
    //     try {
    //         setLoading(true);
    //         const { data } = await getCaseStudy(params, headers);
    //         if (data.status !== "success") throw new Error(data.message);
    //         console.log(data);
    //         if (data.data?.case === null) throw new Error("Page Not Found");

    //         setCaseStudy(data?.data?.case);

    //         setLoading(false);
    //     } catch (err) {
    //         console.error(err);
    //     }
    // };
    useEffect(() => {
        const data = location?.state;
        console.log(data);
        if (!data) navigate("/case-study");
        const { content, ...blg } = data;
        setCaseStudy(blg);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug]);

    function stripHtmlTags(html) {
        // let con = html?.split(/\n/);

        // const anc = con?.map((paragraph) => {
        //     const tmp = document.createElement("p");
        //     tmp.innerHTML = `<p>${paragraph.split("\n")}</p>`;
        //     return tmp.textContent || tmp.innerText;
        // });
        // console.log(anc?.join("\n"));
        // return anc;

        const words = html?.split(" ");

        // Take the first 50 words
        const first50Words = words?.slice(0, 75);

        // Join the words back into a string
        const result = first50Words?.join(" ");

        // Add an ellipsis at the end
        const resultWithEllipsis = result + "...";

        return resultWithEllipsis;
    }

    // const processSectionData = [
    //     {
    //         icon: "/img/research.png",
    //         title: "Research",
    //         description:
    //             "Our team too a Investigate deeply to evaluate the client’s challenges, business model, and future goals. We compiled a comprehensive list of issues mischief other online food website, which guided us in formulating appropriate solutions.",
    //     },
    //     {
    //         icon: "/img/mock.png",
    //         title: "Mock-up's",
    //         description:
    //             "Armed with a clear understanding of the client’s needs, our team created mockups dedicated to solving all the problems. Our objective was to secure the client’s approval on these incipient designs and gather their feedback for extra civility.",
    //     },
    //     {
    //         icon: "/img/development.png",
    //         title: "Development",
    //         description:
    //             "Lastly, we used Planning, Website Design & Development, Delivery Logistics, Testing and payment options, Marketing, Operations and Maintenance for easy to use and provide many features for clients and get all solution in one caseStudy",
    //     },
    // ];

    const expertise = [
        {
            icon: "/img/noun-expert-5469019.png",
            title: "Technology",
            description: caseStudy?.techno?.map((t) => t.name).join(","),
        },
        { icon: "/img/noun-mountain-1121806.png", title: "Industry", description: caseStudy?.heading },
        { icon: "/img/noun-file-4021456.png", title: "Built for", description: "Website, Mobile" },
        { icon: "/img/noun-cloud-4021434.png", title: "Country", description: caseStudy?.country || "India" },
    ];

    // const pageName = (type) => {
    //     if (type === "first") return caseStudy?.slug?.split("-")[0];
    //     if (type === "firstSec") return caseStudy?.slug?.split("-")[0] + " " + caseStudy?.slug?.split("-")[1];
    //     if (type === "firstLast")
    //         return caseStudy?.slug?.split("-")[2]
    //             ? caseStudy?.slug?.split("-")[0] + " " + caseStudy?.slug?.split("-")[2]
    //             : caseStudy?.slug?.split("-")[0] + " Development";

    //     if (type === "secLast")
    //         return caseStudy?.slug?.split("-")[1] && caseStudy?.slug?.split("-")[2]
    //             ? caseStudy?.slug?.split("-")[1] + " " + caseStudy?.slug?.split("-")[2]
    //             : caseStudy?.slug?.split("-")[0] + " Development";

    //     return caseStudy?.slug?.split("-").join(" ");
    // };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="page project-page">
            <div className="hero-section bg-el ">
                <div className="page-section p-relative" style={{ paddingTop: "1rem" }}>
                    <div className="p-absolute " style={{ zIndex: 0, right: 0, top: 0 }}>
                        <img src="/img/projectHerobg.png" alt="bg" />
                    </div>
                    <div className="content d-flex items-center justify-content-between gap-4  p2 p0-s">
                        <div className="details" style={{ zIndex: 1 }}>
                            <Typography
                                className="page-heading mb1 clr-pm"
                                variant="h4"
                                component="h1"
                                fontWeight={600}
                                sx={{ maxWidth: "382px" }}
                            >
                                {caseStudy?.name}
                            </Typography>
                            <Typography
                                className="page-heading mb3"
                                variant="h4"
                                component="h1"
                                fontWeight={600}
                                sx={{ maxWidth: "382px" }}
                            >
                                {caseStudy?.heading}
                            </Typography>
                            <Typography className="page-heading mb1" variant="h5" component="h1" fontWeight={600}>
                                {caseStudy?.subHeading}
                            </Typography>
                            <Typography
                                className="page-description mb3"
                                variant="body3"
                                component="p"
                                color="text.light"
                                sx={{ lineHeight: "33px" }}
                            >
                                {stripHtmlTags(caseStudy?.desc)}
                                {/* <Typography className="ml1" variant="body3" component="span" color="text.light">
                                            Read More...
                                        </Typography> */}
                            </Typography>
                            <AppButton1
                                text="Let's Talk"
                                style={{ padding: "15px 40px", fontSize: "24px" }}
                                onClick={handleClickScheduleMeeting}
                            />
                            <PopupButton
                                ref={scheduleMeetingModalRef}
                                url="https://calendly.com/appretive"
                                rootElement={document.getElementById("root")}
                                styles={{ display: "none" }}
                                text=""
                            />
                        </div>
                        <div className="featured-image herobg" style={{ zIndex: 1 }}>
                            <img
                                src={config?.apiUrl + caseStudy?.heroImage}
                                className="w-full min-w-[400px]"
                                alt="hero"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="expertise-section bg-m">
                <div className="page-section">
                    <div className="card-container">
                        {expertise.map((ele) => (
                            <div key={ele?.title} className="card f-center">
                                <div className="card-header">
                                    <div className="card-image">
                                        <img src={ele?.icon} alt={ele?.title} width={"60%"} />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Typography variant="h5" fontWeight={600} className="card-heading">
                                        {ele?.title}
                                    </Typography>
                                    <p className="card-description">{ele?.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="problem-statement mt5 mt0-s">
                <div className="page-section">
                    <Typography variant="h4" component="h3" className="mb5 pb0-s t-center" fontWeight={600}>
                        <span className="heading-highlighted clr-pm">Problem statement</span> of the client
                    </Typography>
                    <div className="content d-flex justify-contant-between gap-5 p2">
                        <div className="image f-center">
                            <img src={config?.apiUrl + caseStudy?.middleImage} alt="i" />
                        </div>
                        <div className="details w-50">
                            <ul style={{ listStyle: "inside" }}>
                                {caseStudy?.problem?.split("\n").map((c, i) => (
                                    <Typography
                                        key={i}
                                        className="page-description mb3"
                                        variant="body3"
                                        component="li"
                                        color="text.light"
                                        sx={{ lineHeight: "28px" }}
                                    >
                                        {c}
                                    </Typography>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="process-section bg-m">
                <div className="page-section">
                    <Typography
                        className="t-center mb5"
                        textTransform={"capitalize"}
                        variant="h4"
                        color="text.white"
                        fontWeight={600}
                    >
                        <span className="heading-highlighted clr-w">Process</span>
                    </Typography>
                </div>
            </div>
            <div className="proccess-card f-center">
                <div className="card-container d-flex  align-items-center justify-content-center">
                    <div className="card bg-w">
                        <div className="card-header mb1">
                            <div className="card-icon">
                                <img src="/img/research.png" alt="research" width="30px" />
                            </div>
                            <div className="card-heading mt1">Research</div>
                        </div>
                        <div
                            className="card-descripttion clr-tl"
                            style={{
                                fontSize: "14px",
                                fontWeight: "500",
                            }}
                        >
                            {caseStudy?.research ||
                                "Our team too a Investigate deeply to evaluate the client’s challenges, business model, and future goals. We compiled a comprehensive list of issues mischief other online food website, which guided us in formulating appropriate solutions."}
                        </div>
                    </div>
                    <div className="card bg-w">
                        <div className="card-header mb1">
                            <div className="card-icon">
                                <img src="/img/Mock.png" alt="research" width="30px" />
                            </div>
                            <div className="card-heading mt1">Mock-up</div>
                        </div>
                        <div
                            className="card-descripttion clr-tl"
                            style={{
                                fontSize: "14px",
                                fontWeight: "500",
                            }}
                        >
                            {caseStudy?.mockUp ||
                                "Armed with a clear understanding of the client’s needs, our team created mockups dedicated to solving all the problems. Our objective was to secure the client’s approval on these incipient designs and gather their feedback for extra civility."}
                        </div>
                    </div>
                    <div className="card bg-w">
                        <div className="card-header mb1">
                            <div className="card-icon">
                                <img src="/img/development.png" alt="Mock-up" width="30px" />
                            </div>
                            <div className="card-heading mt1">Development</div>
                        </div>
                        <div
                            className="card-descripttion clr-tl"
                            style={{
                                fontSize: "14px",
                                fontWeight: "500",
                            }}
                        >
                            {caseStudy?.development}
                        </div>
                    </div>
                </div>
            </div>
            <div className="solution-statement mt5 mt0-s">
                <div className="page-section">
                    <Typography variant="h4" component="h3" className="mb5 pb0-s t-center" fontWeight={600}>
                        <span className="heading-highlighted clr-pm">Solution statement</span> of the client
                    </Typography>
                    <div className="content d-flex gap-5 p2">
                        <div className="details w-50">
                            <ul style={{ listStyle: "inside" }}>
                                {caseStudy?.solution?.split("\n").map((c, i) => (
                                    <Typography
                                        key={i}
                                        className="page-description mb3"
                                        variant="body3"
                                        component="li"
                                        color="text.light"
                                        sx={{ lineHeight: "28px" }}
                                    >
                                        {c}
                                    </Typography>
                                ))}
                            </ul>
                        </div>
                        <div className="image f-center w-50">
                            <img src={config?.apiUrl + caseStudy?.bottomImage} alt="i" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CaseStudy;
