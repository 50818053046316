import {
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import "../../assets/css/portfolio.css";
import { AppButton1 } from "../../components";
import { getAllProject, submitForm } from "../../apis";
import config from "../../config/config";
import { FaChevronDown } from "react-icons/fa";
import formatDate from "../../utils/formatDate";

// const productCategories = [
//     { _id: 0, name: "All", slug: "all" },
//     { _id: 1, name: "Mobile Development", slug: "mobile-development" },
//     { _id: 2, name: "Web Development", slug: "web-development" },
//     { _id: 3, name: "Game Development", slug: "game-development" },
//     { _id: 4, name: "AR/VR Development", slug: "ar/vr-development" },
//     { _id: 5, name: "UI/UX Development", slug: "ui/ux-development" },
//     { _id: 6, name: "Backend Development", slug: "backend-development" },
// ];

const Portfolio = () => {
  const categoriesRef = useRef(null);
  const dateRef = useRef(null);
  const [activeCategory, setActiveCategory] = useState(0);
  const [projectCategories, setProjectCategories] = useState([]);
  const [contantFormData, setContactFormData] = useState({
    formName: "idea",
    name: "",
    email: "",
    looking: " ",
    budget: " ",
    startTime: "",
    requirement: "",
    description: "",
    file: "",
  });
  const [projectList, setProjectList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClickChangeActiveCategory = (e, category) => {
    if (category?.id === activeCategory) return;
    setActiveCategory(category.id);

    if (category.slug) fatchProjects({ category: category?.slug });
    else fatchProjects();
  };

  // const handleClickLeftArrow = (e) => {
  //     if (!categoriesRef || !categoriesRef.current) return;
  //     categoriesRef.current.scrollBy({
  //         top: 0,
  //         left: -200,
  //         behavior: "smooth",
  //     });
  // };

  // const handleClickRightArrow = (e) => {
  //     if (!categoriesRef || !categoriesRef.current) return;
  //     // categoriesRef.current.scrollLeft += 50;
  //     categoriesRef.current.scrollBy({
  //         top: 0,
  //         left: +200,
  //         behavior: "smooth",
  //     });
  // };

  const handleChangeContactFormFields = (e) => {
    setContactFormData({ ...contantFormData, [e.target.name]: e.target.value });
  };

  const handleSubmitContactForm = async (e) => {
    e.preventDefault();
    try {
      const { data } = await submitForm(contantFormData);
      if (data.status !== "success") throw new Error(data.message);
      setContactFormData({
        name: "",
        email: "",
        looking: " ",
        budget: " ",
        startTime: " ",
        requirement: "",
        description: "",
        file: "",
      });
    } catch (err) {
      console.error(err);
    }
  };

  // function stripHtmlTags(html) {
  //     // let con = html?.split(/\n/);

  //     // const anc = con?.map((paragraph) => {
  //     //     const tmp = document.createElement("p");
  //     //     tmp.innerHTML = `<p>${paragraph.split("\n")}</p>`;
  //     //     return tmp.textContent || tmp.innerText;
  //     // });
  //     // console.log(anc?.join("\n"));
  //     // return anc;

  //     const words = html?.split(" ");

  //     // Take the first 50 words
  //     const first50Words = words?.slice(0, 50);

  //     // Join the words back into a string
  //     const result = first50Words?.join(" ");

  //     // Add an ellipsis at the end

  //     return result;
  // }
  const getUniqueCategory = (data) => {
    const uniqueCategories = [];
    const uniqueSlugs = new Set();

    data?.forEach((p, i) => {
      const category = {
        id: i + 1,
        name: p?.catagory?.label,
        slug: p?.catagory?.value,
      };

      if (!uniqueSlugs.has(category.slug)) {
        uniqueCategories.push(category);
        uniqueSlugs.add(category.slug);
      }
    });
    return uniqueCategories;
  };
  const fatchProjects = async (params, headers) => {
    try {
      setLoading(true);
      const { data } = await getAllProject(params, headers);
      if (data.status !== "success") throw new Error(data.message);

      if (params?.activeCategory === 0)
        setProjectCategories([
          { id: 0, name: "All" },
          ...getUniqueCategory(data?.data?.Projects),
        ]);

      setProjectList(data?.data?.Projects);
      setLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  const OpenDatePicker = (e) => {
    dateRef.current.showPicker();
  };

  console.log(projectList);

  useEffect(() => {
    fatchProjects({ activeCategory: 0 });
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page portfolio-page">
      <div className="page-section pb2">
        <div className="products-section">
          <div className="product-categories-container">
            {/* <div className="arrow left-arrow" onClick={handleClickLeftArrow}>
                            <MdKeyboardArrowLeft />
                        </div> */}
            <div className="categories" ref={categoriesRef}>
              {projectCategories?.map((category) => (
                <div
                  key={category.id}
                  className={`category ${
                    category.id === activeCategory ? "active" : ""
                  }`}
                  onClick={(e) => handleClickChangeActiveCategory(e, category)}
                >
                  <p
                    className="category-name"
                    style={{
                      minWidth: "70px",
                      textAlign: "center",
                      fontFamily: "Gilroy-bold",
                    }}
                  >
                    {category.name}
                  </p>
                </div>
              ))}
            </div>
            {/* <div className="arrow right-arrow" onClick={handleClickRightArrow}>
                            <MdKeyboardArrowRight />
                        </div> */}
          </div>
          <div className="card-container">
            {loading === false &&
              projectList?.map((product) => (
                <div key={product._id} className="card">
                  {console.log(product)}
                  <div className="card-header">
                    <div className="card-image">
                      {Array.isArray(product.heroImage) &&
                      product.heroImage.length > 0 ? (
                        <img
                          src={`${config.apiUrl}${product.heroImage[0]}`}
                          alt={product?.title}
                        />
                      ) : (
                        <img
                          src={`${config.apiUrl}${product.heroImage}`}
                          alt={product?.title}
                        />
                      )}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-heading">
                      <Typography
                        variant="h5"
                        component="h4"
                        color={product.textColor || "text.main"}
                        fontWeight={600}
                        style={{ fontFamily: "Gilroy" }}
                      >
                        {product.name}
                      </Typography>
                    </div>
                    <Typography
                      className="card-description clr-pl poppins-fonts ellipsis-3line "
                      variant="bosy1"
                      component="p"
                      color="text.light"
                      dangerouslySetInnerHTML={{
                        __html: product?.desc?.split("</p>")[0],
                      }}
                    ></Typography>

                    {/* <p className="card-description">Read more...</p> */}
                    <div className="card-gallery">
                      {product?.techno?.map((tech) => (
                        <div key={tech.slug} className="tech-img">
                          <img src={tech.logo} alt={tech.name} />
                        </div>
                      ))}
                    </div>
                    <div className="card-action">
                      <AppButton1 text="Get Project" href={product.slug} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="page-section pt0">
        <Typography
          className="mb3 t-center"
          component="h4"
          variant="h4"
          fontWeight={600}
        >
          <span
            className="heading-highlighted clr-b"
            style={{ fontFamily: "Gilroy" }}
          >
            Send your
          </span>
          <span className="clr-pm" style={{ fontFamily: "Gilroy-bold" }}>
            {" "}
            idea
          </span>
        </Typography>
        <div className="contact-section">
          <form onSubmit={handleSubmitContactForm}>
            <Grid container spacing={2} justifyContent={"space-between"}>
              <Grid item md={12} lg={6}>
                <TextField
                  variant="outlined"
                  placeholder="Name"
                  label="Name"
                  name="name"
                  value={contantFormData.name}
                  fullWidth
                  required
                  onChange={handleChangeContactFormFields}
                />
              </Grid>
              <Grid item md={12} lg={6}>
                <TextField
                  variant="outlined"
                  placeholder="Email"
                  label="Email"
                  type="email"
                  name="email"
                  value={contantFormData.email}
                  fullWidth
                  required
                  onChange={handleChangeContactFormFields}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={12} lg={4}>
                <Select
                  name="looking"
                  value={contantFormData.looking}
                  fullWidth
                  required
                  onChange={handleChangeContactFormFields}
                >
                  <MenuItem value=" " disabled>
                    <div style={{ opacity: "0.38" }}>What are lookin for?</div>
                  </MenuItem>
                  <MenuItem value="mobile_app">Mobile App</MenuItem>
                  <MenuItem value="web_app">Web App</MenuItem>
                </Select>
              </Grid>
              <Grid item md={12} lg={4}>
                <Select
                  name="budget"
                  value={contantFormData.budget}
                  fullWidth
                  required
                  onChange={handleChangeContactFormFields}
                >
                  <MenuItem value=" " disabled>
                    <div style={{ opacity: "0.38" }}>Budget?</div>
                  </MenuItem>
                  <MenuItem value="10000-20000">10000₹ - 20000₹ </MenuItem>
                  <MenuItem value="25000-50000">25000₹ - 50000₹</MenuItem>
                  <MenuItem value="51000-100000">51000₹ - 100000₹</MenuItem>
                  <MenuItem value="100000-500000">100000₹ - 500000₹</MenuItem>
                </Select>
              </Grid>
              <Grid item md={12} lg={4} style={{ position: "relative" }}>
                {/* <label htmlFor="date" style={{ color: "grey" }}>
                                        How Soon you want to start ?
                                    </label> */}
                <Box position={"relative"}>
                  <TextField
                    variant="outlined"
                    placeholder="How Soon you want to start ? (dd-mm-yyyy)"
                    label="How Soon you want to start ? (dd-mm-yyyy)"
                    type="text"
                    name="startTime"
                    value={formatDate(contantFormData.startTime, "dd-mm-yyyy")}
                    fullWidth
                    required
                    onClick={OpenDatePicker}
                  />
                  <FaChevronDown
                    style={{ position: "absolute", right: "10", top: "20" }}
                    onClick={OpenDatePicker}
                  />
                </Box>
                <input
                  type="date"
                  aria-label="Start Date"
                  name="startTime"
                  ref={dateRef}
                  onChange={handleChangeContactFormFields}
                  style={{
                    visibility: "hidden",
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent={"space-between"}
              sx={{ marginTop: "-35px" }}
            >
              <Grid item md={12} lg={6}>
                <TextField
                  variant="outlined"
                  placeholder="Requirement"
                  label="Requirement"
                  name="requirement"
                  value={contantFormData.requirement}
                  fullWidth
                  required
                  onChange={handleChangeContactFormFields}
                />
              </Grid>
              <Grid item md={12} lg={6}>
                <TextField
                  variant="outlined"
                  placeholder="Want to share file/link?"
                  label="Want to share file/link?"
                  name="file"
                  value={contantFormData.file}
                  fullWidth
                  onChange={handleChangeContactFormFields}
                />
              </Grid>
            </Grid>
            <TextField
              variant="outlined"
              placeholder="Brief project description"
              label="Brief project description"
              name="description"
              value={contantFormData.description}
              multiline
              minRows={3}
              fullWidth
              required
              onChange={handleChangeContactFormFields}
            />
            <div className="f-center mt2">
              <AppButton1
                text="Submit"
                type="submit"
                // style={{ padding: "var(--app-base-spacing-2) var(--app-base-spacing-5)" }}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
