import axios from "axios";
import config from "../config/config";

const http = axios.create({
  baseURL: config.apiUrl,
});

http.interceptors.request.use((config) => {
  config.headers["Authorization"] = localStorage.getItem("tcn");
  return config;
});

// AUTH APIs
export const getMenu = (params, headers) =>
  http.get("/api/v1/menu/all", { params, headers });

//Blog APIs
export const getBlogs = (params, headers) =>
  http.get("/api/v1/blog/all", { params, headers });

//case Studt APIs
export const getCaseStudy = (params, headers) =>
  http.get("/api/v1/case/all", { params, headers });

export const submitForm = (data, params, headers) =>
  http.post("/api/v1/form/create", data, { params, headers });

export const getServicePages = (slug, params, headers) =>
  http.get(`/api/v1/servicePages/page/${slug}`, { params, headers });

export const getProject = (slug, params, headers) =>
  http.get(`/api/v1/projects/page/${slug}`, { params, headers });

export const getAllProject = (params, headers) =>
  http.get(`/api/v1/projects/all`, { params, headers });

export const getReviews = (params, headers) =>
  http.get(`/api/v1/review/all`, { params, headers });

export const createNewsLetter = (data, params, headers) =>
  http.post("/api/v1/form/news-letter", data, { params, headers });

export const getClients = (params, headers) =>
  http.get(`/api/v1/clients/all`, { params, headers });
