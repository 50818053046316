import { Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { NavLink } from "react-router-dom";
import ModelContext from "../../context/contactModelContext";
import AppButton1 from "../AppButton1/AppButton1";
import { PopupButton } from "react-calendly";

import "../../assets/css/menu.css";
import { getMenu } from "../../apis";

const Menu = ({ setOpen }) => {
    const MenuRef = useRef(null);
    const ourWorkingMenuRef = useRef(null);
    const [clickedNavLink, setClickedNavLink] = useState(false);
    console.log(clickedNavLink);
    const { setModal } = useContext(ModelContext);
    const scheduleMeetingModalRef = useRef(null);
    const handleClickScheduleMeeting = (e) => {
        // console.log(scheduleMeetingModalRef.current);
        scheduleMeetingModalRef.current.onClick(e);
    };

    const [megamenu, setmenuList] = useState([]);

    const fetchMenus = async (params, headers) => {
        try {
            const { data } = await getMenu(params, headers);
            if (data.status !== "success") throw new Error(data.message);

            const formattedApiData = data?.data?.menu.map((menu) => {
                if (menu.isMega) {
                    const children = {};

                    menu.children.forEach((subMenu) => {
                        if (children[subMenu.heading]) {
                            children[subMenu.heading].push(subMenu);
                        } else {
                            children[subMenu.heading] = [subMenu];
                        }
                    });

                    const data = Object.keys(children).map((child) => {
                        return { heading: child, data: children[child] };
                    });

                    menu.children = data;
                }

                return menu;
            });

            setmenuList(formattedApiData);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchMenus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickNavLink = (_e, submenu = false, menu = "") => {
        setClickedNavLink(true);

        if (submenu) {
            if (MenuRef.current && menu === "service") {
                MenuRef.current.classList.add("d-none");
                setTimeout(() => {
                    MenuRef.current.classList.remove("d-none");
                }, 400);
            }
            if (ourWorkingMenuRef.current && menu === "working") {
                ourWorkingMenuRef.current.classList.add("d-none");
                setTimeout(() => {
                    ourWorkingMenuRef.current.classList.remove("d-none");
                }, 400);
            }
        }
        window.scrollTo(0, 0);
        if (setOpen) {
            setOpen(false);
        }
    };

    const handleTransitionEnd = (menu) => {
        // console.log(menu);

        if (window.innerWidth > 400) setClickedNavLink(false);
    };

    return (
        <nav className="menu">
            <ul className="ul-menu">
                {megamenu?.map((menu) => (
                    <li
                        key={menu.position}
                        className={`menu-item ${menu?.isMega ? "service-menu" : "ourworking-menu has-submenu"} `}
                        onTransitionEnd={handleTransitionEnd}
                    >
                        {!(menu.hasSub || menu.isMega) ? (
                            <NavLink
                                className="menu-link d-flex align-items-center mb1 mt1"
                                to={menu.link}
                                onClick={(e) => handleClickNavLink(e, menu.hasSub || menu.isMega, menu)}
                            >
                                {menu.name} {(menu.hasSub || menu.isMega) && <MdOutlineKeyboardArrowDown />}
                            </NavLink>
                        ) : (
                            <div className="menu-link d-flex align-items-center mb1 mt1 px-2">
                                {menu.name} {(menu.hasSub || menu.isMega) && <MdOutlineKeyboardArrowDown />}
                            </div>
                        )}
                        {menu.isMega && (
                            <ul
                                className="mega-menu submenu-container"
                                ref={MenuRef}
                                style={{ backgroundImage: "url(/img/mega-menu-bg.png)" }}
                            >
                                <div className="megamenu-container z10">
                                    {menu?.children?.map((menu, i) => (
                                        <div key={i} className="submenu">
                                            <Typography
                                                className="submenu-headding"
                                                variant="h6"
                                                component="h4"
                                                color="primary.main"
                                            >
                                                {menu.heading}
                                            </Typography>
                                            <ul className="submenu-list">
                                                {menu?.data &&
                                                    menu?.data?.map((ele, i) => (
                                                        <li key={i} className="menu-item sub-menu-item">
                                                            <NavLink
                                                                className="d-flex align-items-center"
                                                                to={`/services/${ele?.link}`}
                                                                onClick={(e) => handleClickNavLink(e, true, "service")}
                                                            >
                                                                <span className="before-icon">
                                                                    <BsArrowRight
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: "large",
                                                                        }}
                                                                    />
                                                                </span>
                                                                {ele?.name}
                                                                <span className="after-icon">
                                                                    {/* <FaArrowAltCircleRight
                                                                        style={{ fontSize: "normal" }}
                                                                    /> */}

                                                                    <svg
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <g id="arrow-right">
                                                                            <path
                                                                                id="Vector"
                                                                                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM16.03 12.53L13.03 15.53C12.88 15.68 12.69 15.75 12.5 15.75C12.31 15.75 12.12 15.68 11.97 15.53C11.68 15.24 11.68 14.76 11.97 14.47L13.69 12.75H8.5C8.09 12.75 7.75 12.41 7.75 12C7.75 11.59 8.09 11.25 8.5 11.25H13.69L11.97 9.53C11.68 9.24 11.68 8.76 11.97 8.47C12.26 8.18 12.74 8.18 13.03 8.47L16.03 11.47C16.32 11.76 16.32 12.24 16.03 12.53Z"
                                                                                fill="#3776FF"
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </NavLink>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </ul>
                        )}
                        {menu.hasSub && !menu.isMega && (
                            <ul className="submenu-container" ref={ourWorkingMenuRef}>
                                {menu?.children?.map((menu, i) => (
                                    <li className="menu-item sub-menu-item" key={i}>
                                        <NavLink
                                            className="d-flex align-items-center"
                                            to={menu.link}
                                            onClick={(e) => handleClickNavLink(e, true, "working")}
                                        >
                                            <span className="before-icon">
                                                <BsArrowRight
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: "x-large",
                                                    }}
                                                />
                                            </span>
                                            {menu.name}
                                            <span className="after-icon pr1">
                                                <FaArrowAltCircleRight style={{ fontSize: "larger" }} />
                                            </span>
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
                <div className="action-button d-flex" style={{ gap: "22px" }}>
                    <AppButton1 text="Get Quote" onClick={(e) => setModal(true)} />
                    <AppButton1 text="Schedule Meeting" onClick={handleClickScheduleMeeting} />
                    <PopupButton
                        ref={scheduleMeetingModalRef}
                        url="https://calendly.com/appretive"
                        rootElement={document.getElementById("root")}
                        styles={{ display: "none" }}
                        text=""
                    />
                </div>
            </ul>
        </nav>
    );
};

export default Menu;
