import { Box, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
// import { MdMarkEmailUnread } from "react-icons/md";
// import { FaLocationDot } from "react-icons/fa6";
// import { BiSolidPhoneCall } from "react-icons/bi";

import "../../assets/css/footer.css";
import config from "../../config/config";
import { Link, NavLink } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { createNewsLetter, getMenu } from "../../apis";
import { MdLocationPin, MdMarkEmailUnread } from "react-icons/md";
import { BiSolidPhoneCall } from "react-icons/bi";
import {
    FaAngleRight,
    FaBehance,
    FaDribbble,
    FaFacebook,
    FaInstagram,
    FaLinkedin,
    FaPinterest,
    FaWhatsapp,
} from "react-icons/fa";
import { BsThreads, BsTwitter } from "react-icons/bs";

const contactPlaces = [
    {
        title: "Surat",
        content: "Surat, India",
        img: "/img/taj.png",
        flag: "/img/ind-flag.png",
    },
    {
        title: "USA",
        content: "New York, United States",
        img: "/img/usa.png",
        flag: "/img/usa-flag.png",
    },
    {
        title: "UAE",
        content: "Abu Dhabi, Dubai",
        img: "/img/dubai.png",
        flag: "/img/dubai.png",
    },
    // { title: "New Zeeland", content: "Auckland, New Zeeland", img: "/img/nz.png", flag: "/img/nz-flag.png" },
    {
        title: "Australia",
        content: "Melbourne, Australia",
        img: "/img/australia.png",
        flag: "/img/australia.png",
    },
    {
        title: "United Kingdom",
        content: "Cambridge, United Kingdom",
        img: "/img/usa.png",
        flag: "/img/usa.png",
    },
];

const services = [
    {
        title: "Mobile App ",
        link: "/service/mobile-app-development",
    },
    {
        title: "Web App",
        link: "service/web-development",
    },
    {
        title: "Software",
        link: "/service/software-development",
    },
    {
        title: "E-commerce ",
        link: "/service/e-commerce-devlopment",
    },
    {
        title: "SAP ",
        link: "/service/sap-services",
    },
    {
        title: "IOT",
        link: "/service/internet-of-things",
    },
    {
        title: "BlockChain",
        link: "/service/blockchain-development",
    },
    {
        title: "Shopify",
        link: "/service/shopify-app-development",
    },
    {
        title: "E-commerce Ads",
        link: "/service/e-commerce-shopping-ads",
    },
];

const Footer = () => {
    const [menus, setMenus] = useState([]);
    const [newsLetter, setNewsLetter] = useState([]);

    const [megamenu, setmenuList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const fetchMenus = async (params, headers) => {
        try {
            const { data } = await getMenu(params, headers);
            if (data.status !== "success") throw new Error(data.message);
            setMenus([]);
            let formattedApiData = data?.data?.menu.map((menu) => {
                if (menu.isMega) {
                    const children = {};

                    menu.children.forEach((subMenu) => {
                        if (children[subMenu.heading]) {
                            children[subMenu.heading].push(subMenu);
                        } else {
                            children[subMenu.heading] = [subMenu];
                        }
                    });

                    const data = Object.keys(children).map((child) => {
                        return { heading: child, data: children[child] };
                    });

                    menu.children = data;
                } else {
                    if (menu?.hasSub) menus.push(...menu.children);
                    else menus.push(menu);
                }
                if (menu.isMega) return menu.children;

                return null;
            });
            setMenus(menus);

            formattedApiData = formattedApiData.filter((f) => f !== null);

            setmenuList(...formattedApiData);
        } catch (err) {
            console.log(err);
        }
    };

    const validateEmail = (email) => {
        return String(email)
            ?.toLowerCase()
            ?.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handelNewsLetter = async (e) => {
        e.preventDefault();
        try {
            if (!validateEmail(newsLetter)) throw new Error("not a valid email");

            const { data } = await createNewsLetter({ email: newsLetter });
            setNewsLetter("");
            if (data.status !== "success") throw new Error(data.message);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchMenus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="footer" style={{ backgroundColor: "#010a21" }}>
            <div className="map-section" style={{ backgroundColor: "#010a21" }}>
                <div
                    className="footer-top"
                    style={{
                        backgroundImage: "url(/img/BG.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                    }}
                >
                    {/* <div className="t-center" style={{ fontSize: "1.5rem", fontWeight: "700" }}>
                        <span className="heading-highlighted clr-pm ">We provide our services</span> in this country or
                        many more.
                    </div> */}
                    <div className="page-section pt1 pb1">
                        <Typography className="mt1 mb2" variant="h4" component="h3" textAlign="left" fontWeight={600}>
                            <span className="heading-highlighted clr-w" style={{ fontFamily: "Gilroy-bold" }}>
                                {" "}
                                Our{" "}
                            </span>
                            <span className="clr-pm" style={{ fontFamily: "Gilroy-bold" }}>
                                {" "}
                                Global Presence
                            </span>
                        </Typography>
                        <div className="card-container ">
                            {contactPlaces.map((ele, i) => (
                                <div key={i} className="card ">
                                    <div className="card-head">
                                        <div className="card-img ">
                                            <img src={ele.flag} className="my-5" alt={ele.title} />
                                        </div>
                                    </div>
                                    <div className="card-body" style={{ marginTop: "-5px" }}>
                                        <Typography
                                            variant="h6"
                                            component="h2"
                                            className="card-heading t-center"
                                            style={{
                                                color: "white",
                                                fontWeight: "600",
                                                fontFamily: "GilRoy-bold",
                                            }}
                                        >
                                            {ele.title}
                                        </Typography>
                                        {/* <Typography variant="body1" component="p" className="card-description t-center">
                                            {ele.content}
                                        </Typography> */}
                                    </div>
                                </div>
                            ))}
                        </div>

                        <Grid container className="mt1">
                            <Grid item xs={12} sm={6} lg={3}>
                                <div className="widget p2">
                                    <div className="logo mb3">
                                        <img src="/img/white-logo.png" alt="logo" width="200px" />
                                    </div>
                                    <Typography
                                        className="mb3 clr-w poppins-font"
                                        variant="body1"
                                        component="p"
                                        color="#777"
                                        fontWeight="600"
                                    >
                                        Top learning experiences that create more talent in the world.
                                    </Typography>
                                    {/* <div className="d-flex flex-wrap">
                                        <IconButton>
                                            <a
                                                href="https://www.behance.net/appretive_infosoft"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img src="/img/be.png" alt="Behance" width="26px" />
                                            </a>
                                        </IconButton>
                                        <IconButton>
                                            <a
                                                href="https://www.linkedin.com/company/68280490/admin/feed/posts/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img src="/img/in.png" alt="LinkedIn" width="26px" />
                                            </a>
                                        </IconButton>
                                        <IconButton>
                                            <a
                                                href="https://in.pinterest.com/Appretive_Infosoft/"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img src="/img/pi.png" alt="Pinterest" width="26px" />
                                            </a>
                                        </IconButton>
                                        <IconButton>
                                            <a
                                                href="https://dribbble.com/Appretive_Infosoft"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img src="/img/dr.png" alt="dribbble" width="26px" />
                                            </a>
                                        </IconButton>
                                        <IconButton>
                                            <a
                                                href={`https://api.whatsapp.com/send?phone=${config.contact.mobile}&text=Hi, Appretive Infosoft`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img src="/img/wa.png" alt="Whatsapp" width="26px" />
                                            </a>
                                        </IconButton>
                                    </div> */}
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={6} lg={2}>
                                <div className="widget w-100 p2" style={{ maxWidth: "300px" }}>
                                    <Typography
                                        className="mb1"
                                        variant="body3"
                                        component="h5"
                                        color="primary.main"
                                        fontWeight={600}
                                        style={{ fontFamily: "GilRoy-bold" }}
                                    >
                                        Information
                                    </Typography>
                                    <div className="list">
                                        {menus?.map((ele, i) => {
                                            if (ele.link === "/") return null;
                                            return (
                                                <Link to={ele?.link} key={i}>
                                                    <Typography
                                                        className="mb1 d-flex items-center"
                                                        color="#777"
                                                        variant="body1"
                                                        component="div"
                                                        fontWeight="600"
                                                    >
                                                        <span className="mr1" style={{ color: "#777" }}>
                                                            <FaAngleRight fill="#777" />
                                                        </span>
                                                        <div className="text-white hover:text-[#777]">{ele?.name}</div>
                                                    </Typography>
                                                </Link>
                                            );
                                        })}
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={6} lg={4}>
                                <div className="widget w-100 p2">
                                    <Typography
                                        className="mb1"
                                        variant="body3"
                                        component="h5"
                                        color="primary.main"
                                        fontWeight={600}
                                        style={{ fontFamily: "GilRoy-bold" }}
                                    >
                                        Services
                                    </Typography>
                                    <div className="flex items-start gap-2">
                                        <div className="list">
                                            {services?.slice(0, 5)?.map((ele, i) => {
                                                if (ele.link === "/") return null;
                                                return (
                                                    <Link to={ele?.link} key={i}>
                                                        <Typography
                                                            className="mb1 d-flex items-center"
                                                            color="#777"
                                                            variant="body1"
                                                            component="div"
                                                            fontWeight="600"
                                                        >
                                                            <span className="mr1" style={{ color: "#777" }}>
                                                                <FaAngleRight fill="#777" />
                                                            </span>
                                                            <div className="text-white hover:text-[#777] text-nowrap">
                                                                {ele?.title}
                                                            </div>
                                                        </Typography>
                                                    </Link>
                                                );
                                            })}
                                        </div>
                                        <div className="list">
                                            {" "}
                                            {services?.slice(5)?.map((ele, i) => {
                                                if (ele.link === "/") return null;
                                                return (
                                                    <Link to={ele?.link} key={i}>
                                                        <Typography
                                                            className="mb1 d-flex items-center"
                                                            color="#777"
                                                            variant="body1"
                                                            component="div"
                                                            fontWeight="600"
                                                        >
                                                            <span className="mr1" style={{ color: "#777" }}>
                                                                <FaAngleRight fill="#777" />
                                                            </span>
                                                            <div className="text-white hover:text-[#777] text-nowrap">
                                                                {ele?.title}
                                                            </div>
                                                        </Typography>
                                                    </Link>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={6} lg={3}>
                                <div className="contact-details p2 ">
                                    <Typography
                                        className="mb1"
                                        variant="body3"
                                        component="p"
                                        color="primary.main"
                                        fontWeight={600}
                                        style={{ fontFamily: "GilRoy-bold" }}
                                    >
                                        Contact Us
                                    </Typography>

                                    <div className="d-flex">
                                        <MdLocationPin
                                            fontSize="30px"
                                            style={{
                                                fill: "var(--app-primary-main)",
                                                marginRight: "10px",
                                                width: "70px",
                                            }}
                                        />
                                        <Typography
                                            variant="body3"
                                            component="p"
                                            style={{ color: "#fff" }}
                                            className="mb2"
                                            fontWeight={600}
                                        >
                                            {config.contact.address}
                                        </Typography>
                                    </div>

                                    <a href={`tel:${config.contact.mobile}`}>
                                        <div className="d-flex">
                                            <BiSolidPhoneCall
                                                fontSize="30px"
                                                style={{
                                                    fill: "var(--app-primary-main)",
                                                    marginRight: "10px",
                                                }}
                                            />
                                            <Typography
                                                variant="body3"
                                                component="div"
                                                fontWeight={600}
                                                style={{ color: "#777", position: "relative" }}
                                                className="mb2"
                                            >
                                                <div className="text-white">{config.contact.mobile}</div>
                                            </Typography>
                                        </div>
                                    </a>
                                    <a
                                        href={`https://mail.google.com/mail/?view=cm&fs=1&to=${config.contact?.email}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <div className="d-flex">
                                            <MdMarkEmailUnread
                                                fontSize="30px"
                                                style={{
                                                    fill: "var(--app-primary-main)",
                                                    marginRight: "17px",
                                                }}
                                            />
                                            <Typography
                                                variant="body3"
                                                component="div"
                                                style={{ color: "#777" }}
                                                className="mb1"
                                                fontWeight={600}
                                            >
                                                <div className="text-white">{config.contact.email}</div>
                                            </Typography>
                                        </div>
                                    </a>
                                </div>
                            </Grid>
                        </Grid>

                        {/* <Grid item sm={12} md={6} lg={3}>
                            <div className="widget w-100 p2" style={{ maxWidth: "300px" }}>
                                <Typography
                                    className="mb2"
                                    variant="body3"
                                    component="h5"
                                    color="primary.main"
                                    fontWeight={600}
                                >
                                    We Offer Services
                                </Typography>
                                <div className="list">
                                    {[
                                        "App Development",
                                        "Web Development",
                                        "Software Development",
                                        "Graphics / UI-UX Designing",
                                        "EHR & EMR Development",
                                        "Programming",
                                    ].map((ele) => (
                                        <Typography className="mb2 clr-w" variant="body1" component="p" key={ele}>
                                            <span className="mr1 clr-w">{">"}</span> {ele}
                                        </Typography>
                                    ))}
                                </div>
                            </div>
                        </Grid>

                        <Grid item sm={12} md={6} lg={3}>
                            <div className="widget w-100 p2" style={{ maxWidth: "300px" }}>
                                <Typography
                                    className="mb2"
                                    variant="body3"
                                    component="h5"
                                    color="primary.main"
                                    fontWeight={600}
                                >
                                    Enterprise
                                </Typography>
                                <div className="list">
                                    {["Quality Policy", "Our Policy", "Careers", "Out Team", "FAQs"].map((ele) => (
                                        <Typography
                                            className="mb2 clr-w"
                                            variant="body1"
                                            component="p"
                                            color="text.main"
                                            key={ele}
                                        >
                                            <span className="mr1 clr-w">{">"}</span>
                                            {ele}
                                        </Typography>
                                    ))}
                                </div>
                            </div>
                        </Grid> */}

                        {/* <div className="map-img mt5 f-center">
                            <img src="/img/world-map.png" alt="map" style={{ maxWidth: "950px", width: "100%" }} />
                        </div> */}

                        <div className="xl:flex items-center justify-between gap-10 widget w-full p2">
                            <Typography
                                className="mb1 text-nowrap"
                                variant="body3"
                                component="h5"
                                color="primary.main"
                                fontWeight={600}
                                style={{ fontFamily: "GilRoy-bold" }}
                            >
                                Follow us on
                            </Typography>
                            <div className="list">
                                <div className="d-flex flex-wrap items-center">
                                    <IconButton className="social-links ">
                                        <a
                                            href="https://www.behance.net/appretive_infosoft"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FaBehance className="fab fill-white" />
                                            {/* <img src="/img/be.png" alt="Behance" width="26px" /> */}
                                        </a>
                                    </IconButton>
                                    <IconButton className="social-links ">
                                        <a
                                            href="https://www.linkedin.com/company/68280490/admin/feed/posts/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FaLinkedin className="fab fill-white" />
                                            {/* <img src="/img/in.png" alt="LinkedIn" width="26px" /> */}
                                        </a>
                                    </IconButton>

                                    <IconButton className="social-links ">
                                        <a
                                            href="https://dribbble.com/Appretive_Infosoft"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FaDribbble className="fab fill-white" />
                                            {/* <img src="/img/dr.png" alt="dribbble" width="26px" /> */}
                                        </a>
                                    </IconButton>
                                    <IconButton className="social-links ">
                                        <a
                                            href="https://in.pinterest.com/Appretive_Infosoft/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FaPinterest className="fab fill-white" />
                                            {/* <img src="/img/pi.png" alt="Pinterest" width="26px" /> */}
                                        </a>
                                    </IconButton>
                                    <IconButton className="social-links ">
                                        <a
                                            href="https://www.facebook.com/profile.php?id=61557593180078"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FaFacebook className="fab fill-white" />
                                            {/* <img src="/img/pi.png" alt="Pinterest" width="26px" /> */}
                                        </a>
                                    </IconButton>
                                    <IconButton className="social-links ">
                                        <a
                                            href="https://www.instagram.com/appretive_infosoft/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FaInstagram className="fab fill-white" />
                                            {/* <img src="/img/pi.png" alt="Pinterest" width="26px" /> */}
                                        </a>
                                    </IconButton>
                                    <IconButton className="social-links ">
                                        <a
                                            href="https://www.threads.net/@appretive_infosoft"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <BsThreads className="fab fill-white" />
                                            {/* <img src="/img/pi.png" alt="Pinterest" width="26px" /> */}
                                        </a>
                                    </IconButton>
                                    <IconButton className="social-links ">
                                        <a href="https://x.com/appretive_info" target="_blank" rel="noreferrer">
                                            <BsTwitter className="fab fill-white" />
                                            {/* <img src="/img/pi.png" alt="Pinterest" width="26px" /> */}
                                        </a>
                                    </IconButton>
                                    <IconButton className="social-links ">
                                        <a
                                            href={`https://api.whatsapp.com/send?phone=${config.contact.mobile}&text=Hi, Appretive Infosoft`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <FaWhatsapp className="fab fill-white" />
                                            {/* <img src="/img/wa.png" alt="Whatsapp" width="26px" className="" /> */}
                                        </a>
                                    </IconButton>
                                </div>
                            </div>
                        </div>

                        <div className="widget w-100 p2 d-flex justify-content-start gap-4 align-items-center">
                            <div className="d-flex gap-4 texts">
                                <Typography
                                    className="poppins-semibold uppercase"
                                    variant="body3"
                                    component="h5"
                                    color="primary.main"
                                    fontWeight={600}
                                >
                                    Newsletter
                                </Typography>
                                {/* <Typography className="mb3 clr-w" variant="body1" component="p" color="text.light">
                                    Appretive is a prominent service provider that works service provider that works to
                                    bring ideas to reality.
                                </Typography> */}
                                <Typography
                                    className="clr-w"
                                    variant="body1"
                                    component="p"
                                    color="text.light"
                                    style={{ width: "100%", textWrap: "nowrap" }}
                                >
                                    Subscribe To The Newsletter!
                                </Typography>
                            </div>
                            <Box
                                className="d-flex align-items-stretch gap-1"
                                sx={{
                                    boxShadow: "0px 6px 18px rgba(16, 24, 40, 0.08)",
                                    borderRadius: "var(--app-base-spacing-1)",
                                    overflow: "hidden",
                                    width: "100%",
                                }}
                            >
                                <input
                                    type="email"
                                    placeholder="Email Address"
                                    name="email"
                                    value={newsLetter}
                                    style={{
                                        border: 0,
                                        outline: 0,
                                        padding: "var(--app-base-spacing-2) var(--app-base-spacing-2)",
                                        width: "100%",
                                        marginRight: "-15px",
                                        backgroundColor: "#242933",
                                        color: "white",
                                    }}
                                    onChange={(e) => setNewsLetter(e.target?.value)}
                                />
                                {/* <Box
                                        className="f-center"
                                        sx={{
                                            padding: "var(--app-base-spacing-1) var(--app-base-spacing-2)",
                                            borderRadius: "var(--app-base-spacing-1)",
                                        }}
                                    >
                                        <img src="/img/telegram-blue.png" alt="" width="26px" height="30px" />
                                    </Box> */}
                                <button className="font-bold bg-m" onClick={handelNewsLetter}>
                                    <Typography variant={"body1"} className="font-bold clr-w uppercase" component="p">
                                        {"Subscribe"}
                                    </Typography>
                                </button>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-section pt0 pb1">
                <hr className="d-block" style={{ borderTop: "1px solid var(--app-primary-light)" }} />
            </div>

            <div
                style={{
                    backgroundRepeat: "no-repeat",
                    backgroundImage: "url(/img/image 471.png)",
                    zIndex: 2,
                }}
                className=" transition-all duration-1000 ease-in-out"
            >
                <div className="my-4 px-5 text-center text-white flex justify-center items-center  max-w-fit mx-auto border-gray-400 ">
                    <p className="text-white mx-4 text-[24px] ">Services we provide</p>
                    <div
                        className="cursor-pointer"
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                    >
                        <img src="/img/dropdownicon.png" alt="dropdown" />
                    </div>
                </div>
            </div>

            {isOpen && (
                <div className="page-section pt2 pb2">
                    <div className="d-flex flex-wrap gap-5 justify-content-center">
                        <Grid container spacing={2}>
                            {megamenu?.map((menu, i) => (
                                <Grid key={i} item sm={12} md={6} lg={3} style={{ paddingTop: "8px" }}>
                                    <div className="submenu">
                                        <Typography
                                            className="submenu-headding poppins-semibold uppercase mb1"
                                            variant="h6"
                                            component="h4"
                                            color="primary.main"
                                            style={{ fontSize: "20px", fontFamily: "Gilroy-bold" }}
                                        >
                                            {menu.heading}
                                        </Typography>
                                        <ul className="submenu-list">
                                            {menu?.data &&
                                                menu?.data?.map((ele, i) => (
                                                    <li
                                                        key={i}
                                                        className="menu-item sub-menu-item"
                                                        style={{ width: "fit-content" }}
                                                    >
                                                        <NavLink
                                                            className="d-flex align-items-center clr-w mb1 custom-anchor poppins-regular"
                                                            to={`/services/${ele?.link}`}
                                                            style={{ fontSize: "15px" }}
                                                        >
                                                            {ele?.name}
                                                        </NavLink>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </div>
            )}

            <div className="page-section pb1 pt1">
                <hr className="d-block" style={{ borderTop: "1px solid var(--app-primary-light)" }} />
            </div>

            <div className="footer-bottom pb2">
                {/* <div className="pos-left">
                    <img src="/img/footer-left.png" alt="overlay" />
                </div>
                <div className="pos-bottom">
                    <img src="/img/footer-bottom.png" alt="overlay" />
                </div>
                <div className="pos-right">
                    <img src="/img/footer-right.png" alt="overlay" />
                </div> */}
                <div className="page-section  p2 pl5 pr5">
                    <div className="copyright p1">
                        <div className="d-flex flex-wrap align-items-center justify-content-between justify-content-center-m gap-3 clr-w">
                            <p className="clr-w" style={{ width: "350px" }}>
                                © 2024{" "}
                                <Link
                                    to={"/"}
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                    }}
                                    style={{ fontWeight: 700 }}
                                    className="clr-pm poppins-font"
                                >
                                    Appretive Infosoft.
                                </Link>{" "}
                                All rights reserved.
                            </p>
                            <Stack
                                gap={2}
                                direction="row"
                                flexWrap="wrap"
                                divider={
                                    <Divider
                                        flexItem
                                        orientation="vertical"
                                        sx={{ borderColor: "var(--app-text-light)" }}
                                    />
                                }
                            >
                                {[
                                    { text: "About us", link: "/about-us" },
                                    { text: "Case study", link: "/case-study" },
                                    { text: "Blog", link: "/blog" },
                                    { text: "Term of use", link: "" },
                                    { text: "Privacy Policy", link: "" },
                                    { text: "Sitemap", link: "" },
                                ].map((ele, i) => (
                                    <Link to={ele.link} key={i}>
                                        <p className="clr-w poppins-font" key={i} style={{ fontWeight: "600" }}>
                                            {ele.text}
                                        </p>
                                    </Link>
                                ))}
                            </Stack>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;

// <Grid container className="mt1" gap={2}>
//             <Grid item sm={5} md={3} lg={3}>
//               <div className="widget p2">
//                 <div className="logo mb3">
//                   <img src="/img/white-logo.png" alt="logo" width="200px" />
//                 </div>
//                 <Typography
//                   className="mb3 clr-w poppins-font"
//                   variant="body1"
//                   component="p"
//                   color={"#777"}
//                   fontWeight={"600"}
//                   // style={{ fontFamily: "GilRoy-bold" }}
//                 >
//                   Top learning experiences that create more talent in the
//                   world.
//                 </Typography>
//                 <div className="d-flex flex-wrap">
//                   <IconButton>
//                     <a
//                       href="https://www.behance.net/appretive_infosoft"
//                       target="_blank"
//                       rel="noreferrer"
//                     >
//                       <img src="/img/be.png" alt="Behance" width="26px" />
//                     </a>
//                   </IconButton>
//                   <IconButton>
//                     <a
//                       href="https://www.linkedin.com/company/68280490/admin/feed/posts/"
//                       target="_blank"
//                       rel="noreferrer"
//                     >
//                       <img src="/img/in.png" alt="LinkedIn" width="26px" />
//                     </a>
//                   </IconButton>
//                   <IconButton>
//                     <a
//                       href="https://in.pinterest.com/Appretive_Infosoft/"
//                       target="_blank"
//                       rel="noreferrer"
//                     >
//                       <img src="/img/pi.png" alt="Pinterest" width="26px" />
//                     </a>
//                   </IconButton>
//                   {/* <IconButton>
//                                       <a href="https://medium.com/@appretive13" target="_blank" rel="noreferrer">
//                                           <img src="/img/me.png" alt="Medium" width="26px" />
//                                       </a>
//                                   </IconButton> */}
//                   <IconButton>
//                     <a
//                       href="https://dribbble.com/Appretive_Infosoft"
//                       target="_blank"
//                       rel="noreferrer"
//                     >
//                       <img src="/img/dr.png" alt="dribbble" width="26px" />
//                     </a>
//                   </IconButton>
//                   <IconButton>
//                     <a
//                       href={`https://api.whatsapp.com/send?phone=${config.contact.mobile}&text=Hi, Appretive Infosoft`}
//                       target="_blank"
//                       rel="noreferrer"
//                     >
//                       <img src="/img/wa.png" alt="Whatsapp" width="26px" />
//                     </a>
//                   </IconButton>
//                 </div>
//               </div>
//             </Grid>

//             <Grid item sm={5} md={2} lg={2}>
//               <div className="widget w-100 p2" style={{ maxWidth: "300px" }}>
//                 <Typography
//                   className="mb1"
//                   variant="body3"
//                   component="h5"
//                   color="primary.main"
//                   fontWeight={600}
//                   style={{ fontFamily: "GilRoy-bold" }}
//                 >
//                   Information
//                 </Typography>
//                 <div className="list ">
//                   {menus?.map((ele, i) => {
//                     if (ele.link === "/") return "";
//                     return (
//                       <Link to={ele?.link} key={i}>
//                         <Typography
//                           className="mb1 d-flex items-center  "
//                           color={"#777"}
//                           variant="body1"
//                           component="p"
//                           fontWeight={"600"}
//                         >
//                           <span className="mr1 " style={{ color: "#777" }}>
//                             <FaAngleRight fill="#777" />
//                           </span>{" "}
//                           <div className="text-white hover:text-[#777]">
//                             {" "}
//                             {ele?.name}
//                           </div>
//                         </Typography>
//                       </Link>
//                     );
//                   })}
//                 </div>
//               </div>
//             </Grid>

//             <Grid item sm={5} md={2} lg={2}>
//               <div className="widget w-100 p2" style={{ maxWidth: "300px" }}>
//                 <Typography
//                   className="mb1"
//                   variant="body3"
//                   component="h5"
//                   color="primary.main"
//                   fontWeight={600}
//                   style={{ fontFamily: "GilRoy-bold" }}
//                 >
//                   Services
//                 </Typography>
//                 <div className="list ">
//                   {services?.map((ele, i) => {
//                     if (ele.link === "/") return "";
//                     return (
//                       <Link to={ele?.link} key={i}>
//                         <Typography
//                           className="mb1 d-flex items-center  "
//                           color={"#777"}
//                           variant="body1"
//                           component="p"
//                           fontWeight={"600"}
//                         >
//                           <span className="mr1 " style={{ color: "#777" }}>
//                             <FaAngleRight fill="#777" />
//                           </span>{" "}
//                           <div className="text-white hover:text-[#777]">
//                             {" "}
//                             {ele?.title}
//                           </div>
//                         </Typography>
//                       </Link>
//                     );
//                   })}
//                 </div>
//               </div>
//             </Grid>

//             <Grid item sm={5} md={4} lg={4}>
//               <div className="contact-details p2">
//                 <Typography
//                   className="mb1"
//                   variant="body3"
//                   component="p"
//                   color="primary.main"
//                   fontWeight={600}
//                   style={{ fontFamily: "GilRoy-bold" }}
//                 >
//                   Contact Us
//                 </Typography>

//                 <div className="d-flex">
//                   <MdLocationPin
//                     fontSize={"30px"}
//                     style={{
//                       fill: "var(--app-primary-main)",
//                       marginRight: "10px",
//                       width: "70px",
//                     }}
//                   />

//                   <Typography
//                     variant="body3"
//                     component="p"
//                     style={{ color: "#fff" }}
//                     className="mb2"
//                     fontWeight={600}
//                   >
//                     {config.contact.address}
//                   </Typography>
//                 </div>

//                 <a href={`tel:${config.contact.mobile}`}>
//                   <div className="d-flex">
//                     <BiSolidPhoneCall
//                       fontSize={"30px"}
//                       style={{
//                         fill: "var(--app-primary-main)",
//                         marginRight: "10px",
//                         // width: "80px",
//                       }}
//                     />
//                     <Typography
//                       variant="body3"
//                       component="p"
//                       maxWidth={"fit-content"}
//                       fontWeight={600}
//                       style={{ color: "#777", position: "relative" }}
//                       className="mb2"
//                     >
//                       <div className="text-white">
//                         {config.contact.mobile}
//                       </div>
//                     </Typography>
//                   </div>
//                 </a>
//                 <a
//                   href={`https://mail.google.com/mail/?view=cm&fs=1&to=${config.contact?.email}`}
//                   target="_blank"
//                   rel="noreferrer"
//                 >
//                   <div className="d-flex">
//                     <MdMarkEmailUnread
//                       fontSize={"30px"}
//                       style={{
//                         fill: "var(--app-primary-main) ",
//                         marginRight: "17px",
//                       }}
//                     />
//                     <Typography
//                       variant="body3"
//                       component="p"
//                       style={{ color: "#777" }}
//                       className="mb1"
//                       fontWeight={600}
//                     >
//                       <div className="text-white">{config.contact.email}</div>
//                     </Typography>
//                   </div>
//                 </a>
//               </div>
//             </Grid>
//             {/* <Grid item sm={12} md={6} lg={3}>
//                               <div className="contact-details mt5">
//                                   <div className="item d-flex mt5 gap-2 mb2">
//                                       <div className="icon">
//                                           <BiSolidPhoneCall
//                                               style={{ fill: "var(--app-primary-main)", fontSize: "36px" }}
//                                           />
//                                       </div>
//                                       <div className="details">
//                                           <Typography
//                                               className="mb1"
//                                               variant="body3"
//                                               component="p"
//                                               color="primary.main"
//                                               fontWeight={600}
//                                           >
//                                               Phone/WhatsApp
//                                           </Typography>
//                                           <Typography variant="body3" component="p" style={{ color: "white" }}>
//                                               {config.contact.mobile}
//                                           </Typography>
//                                       </div>
//                                   </div>
//                                   <div className="item d-flex mt5 gap-2 mb2">
//                                       <div className="icon">
//                                           <MdMarkEmailUnread
//                                               style={{ fill: "var(--app-primary-main)", fontSize: "36px" }}
//                                           />
//                                       </div>
//                                       <div className="details">
//                                           <Typography
//                                               className="mb1"
//                                               variant="body3"
//                                               component="p"
//                                               color="primary.main"
//                                               fontWeight={600}
//                                           >
//                                               Email
//                                           </Typography>
//                                           <Typography variant="body3" component="p" style={{ color: "white" }}>
//                                               {config.contact.email}
//                                           </Typography>
//                                       </div>
//                                   </div>
//                                   <div className="item d-flex mt5 gap-2">
//                                       <div className="icon">
//                                           <FaLocationDot
//                                               style={{ fill: "var(--app-primary-main)", fontSize: "26px" }}
//                                           />
//                                       </div>
//                                       <div className="details">
//                                           <Typography
//                                               className="mb1"
//                                               variant="body3"
//                                               component="p"
//                                               color="primary.main"
//                                               fontWeight={600}
//                                           >
//                                               Address
//                                           </Typography>
//                                           <Typography variant="body3" component="p" style={{ color: "white" }}>
//                                               {config.contact.address}
//                                           </Typography>
//                                       </div>
//                                   </div>
//                               </div>
//                           </Grid> */}
//           </Grid>
