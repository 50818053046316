import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

import "../../assets/css/reviewSlider.css";
import config from "../../config/config";

const ReviewSlider = ({ data }) => {
  return (
    <div className="rating-slider">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        modules={[Autoplay, Pagination]}
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        pagination={{
          el: ".rating-slider-pagination",
          clickable: true,
          // renderBullet: (index, className) => {
          //     return `<span className="${className}"></span>`;
          // },
        }}
      >
        {data.map((data, index) => (
          <SwiperSlide key={index}>
            <div className="card-container">
              <div className="card">
                {/* <div className="card-image-container">
                                    <img className="card-image" src={config.apiUrl + data.image} alt="" />
                                </div> */}
                <div className="card-content">
                  <p className="review-text font-poppins">{data.review}</p>
                  <h4 className="reviewer-name" style={{ fontSize: "25px" }}>
                    {data.name}
                  </h4>
                  <div className="f-center">
                    <img
                      className="card-image"
                      src={config.apiUrl + data.image}
                      alt=""
                      width={30}
                    />
                    <div
                      className="placeholder ml1 "
                      style={{ fontSize: "22px" }}
                    >
                      {data.role}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="rating-slider-pagination f-center mb3"></div>
    </div>
  );
};

export default ReviewSlider;
