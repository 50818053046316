import { Typography } from "@mui/material";
import React, { useState } from "react";
import { BsClock } from "react-icons/bs";
import { FaRegCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { AppButton1 } from "../../components";
import "../../assets/css/blog.css";
import { getBlogs } from "../../apis";
import { useEffect } from "react";
import config from "../../config/config";
import formatDate from "../../utils/formatDate";

const categories = [
    { _id: 0, label: "All" },
    { _id: 1, value: "app-development", label: "App Development" },
    { _id: 2, value: "web-development", label: "Web Development" },
    { _id: 3, value: "crm-development", label: "CRM Development" },
    { _id: 4, value: "digital-marketing", label: "Digital Marketing" },
    { _id: 5, value: "artificial-intelligent", label: "Artificial Intelligent" },
    { _id: 6, value: "software-development", label: "Software Development" },
    { _id: 7, value: "iot", label: "IOT" },
];

const Blog = () => {
    const [activeCategory, setActiveCategory] = useState();
    const [page, setPage] = useState({
        page: 1,
        perPage: 10,
        totalPages: 1,
        loading: false,
    });
    const [blogList, setBlogList] = useState({ blogList: [] });

    const fetchBlogs = async (params, headers) => {
        setPage({ ...page, loading: true });
        try {
            const { data } = await getBlogs(params, headers);
            if (data.status !== "success") throw new Error(data.message);

            setBlogList({ blogList: data?.data?.blog });
            setPage({ ...page, page: 1, totalPages: data.data.totalPage });
        } catch (err) {
            console.log(err);
        } finally {
            setPage((prev) => ({ ...prev, loading: false }));
        }
    };

    const moreBlogs = async (params, headers) => {
        setPage({ ...page, loading: true });
        try {
            const { data } = await getBlogs(params, headers);
            if (data.status !== "success") throw new Error(data.message);

            setBlogList((prev) => ({
                blogList: [...prev.blogList, ...data?.data.blog],
            }));
            // setBlogList((prev) => ({ blogList: [...prev.blogList, ...data?.data.blog] }));
            setPage((prev) => ({ ...prev, totalPages: data.data.totalPage }));
        } catch (err) {
            console.log(err);
        } finally {
            setPage((prev) => ({ ...prev, loading: false }));
        }
    };

    // function stripHtmlTags(html) {
    //     // let con = html?.split(/\n/);

    //     // const anc = con?.map((paragraph) => {
    //     //     const tmp = document.createElement("p");
    //     //     tmp.innerHTML = `<p>${paragraph.split("\n")}</p>`;
    //     //     return tmp.textContent || tmp.innerText;
    //     // });
    //     // console.log(anc?.join("\n"));
    //     // return anc;

    //     const words = html?.split(" ");

    //     // Take the first 50 words
    //     const first50Words = words?.slice(0, 20);

    //     // Join the words back into a string
    //     const result = first50Words?.join(" ");

    //     // Add an ellipsis at the end
    //     const resultWithEllipsis = result;

    //     return resultWithEllipsis;
    // }

    useEffect(() => {
        fetchBlogs({ page: 1, tag: activeCategory, limit: 10 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCategory]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="blog-page" style={{ marginTop: "130px" }}>
            <div className="top-section">
                <div className="page-section pt0">
                    <Typography
                        variant="h3"
                        component="h2"
                        className="mb2 quicksand-fonts"
                        fontWeight={800}
                        textAlign={"center"}
                    >
                        Popular Categories
                    </Typography>
                    <Typography variant="p2" component="h2" className="mb5" fontWeight={400} textAlign={"center"}>
                        Fresh articles about Technology and Business
                    </Typography>
                    <div className="category-container">
                        {categories.map((category) => (
                            <div
                                key={category?._id}
                                className={["category", activeCategory === category.value ? "active" : ""].join(" ")}
                            >
                                <Typography
                                    variant="body3"
                                    component="p"
                                    onClick={(e) => setActiveCategory(category.value)}
                                >
                                    {category.label}
                                </Typography>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="blog-section">
                <div className="page-section pt0">
                    {blogList?.blogList?.length > 0 ? (
                        <div className="">
                            <div className="card-container">
                                {blogList?.blogList?.map((blog) => (
                                    <div className="card" key={blog?._id}>
                                        <div className="card-head">
                                            <Link
                                                to={`${blog?.title
                                                    ?.toLowerCase()
                                                    .replace(/[^a-z0-9]+/g, "-")
                                                    .replace(/(^-|-$)+/g, "")}`}
                                                state={blog}
                                            >
                                                <div className="card-image">
                                                    <img
                                                        src={config.apiUrl + blog?.blogLogo}
                                                        alt={blog?.title}
                                                        style={{ width: "100%" }}
                                                    />
                                                </div>
                                            </Link>
                                        </div>

                                        <div className="card-footer f-center">
                                            <div className="d-flex align-items-center gap-1">
                                                <BsClock />
                                                <Typography variant="body1" component="h3">
                                                    {blog?.readingDuration}
                                                </Typography>
                                            </div>
                                            <div className="d-flex align-items-center gap-1">
                                                <FaRegCalendarAlt />
                                                <Typography variant="body1" component="h3">
                                                    {formatDate(blog?.createdAt, "dd-mm-yyyy")}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="card-body t-center ellipsis-2line" style={{ height: "65px" }}>
                                            <Link
                                                to={`${blog?.title?.toLowerCase()?.split(" ").join("-")}`}
                                                state={blog}
                                            >
                                                <Typography variant="body3" component="h3">
                                                    {blog?.title}
                                                </Typography>
                                            </Link>
                                        </div>

                                        {/* <Typography variant="body1" component="p" color={"#596274"}>
                                            {stripHtmlTags(blog?.description)}
                                            <Link
                                                to={`${blog?.title?.toLowerCase()?.split(" ").join("-")}`}
                                                state={blog}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    fontWeight={500}
                                                    color={"#596274"}
                                                >
                                                    {" "}
                                                    Read more...
                                                </Typography>
                                            </Link>
                                        </Typography> */}
                                        <div className="f-center mt2">
                                            {/* <Link
                        to={`${blog?.title
                          ?.toLowerCase()
                          ?.split(" ")
                          .join("-")}`}
                        state={blog}
                      > */}
                                            <Link
                                                to={`${blog?.title
                                                    ?.toLowerCase()
                                                    .replace(/[^a-z0-9]+/g, "-")
                                                    .replace(/(^-|-$)+/g, "")}`}
                                                state={blog}
                                            >
                                                <AppButton1 text={"Read Blog"} />
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {console.log(page?.totalPages, page?.page)}
                            {page?.totalPages > page?.page && (
                                <div className="f-center mt4">
                                    <AppButton1
                                        text="Load More..."
                                        onClick={() => {
                                            moreBlogs({
                                                page: page.page + 1,
                                                tag: activeCategory,
                                                limit: 10,
                                            });
                                            setPage({ ...page, page: page?.page + 1 });
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div style={{ maxWidth: "40rem", margin: "auto" }}>
                            <img src="/img/Empty.gif" alt="empty" className="w-100" />
                            {/* <div>Sorry, No Result Found!</div> */}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Blog;
