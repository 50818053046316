import { Typography } from "@mui/material";
import { nanoid } from "nanoid";

import "../../assets/css/aboutUs.css";
import { AppButton1, ContactSection } from "../../components";
import ReviewSliderCards from "../../components/ReviewSlider/ReviewSliderCards";
// import { FaArrowRight } from "react-icons/fa";
// import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";
import { getReviews } from "../../apis";
import { useState } from "react";
import { PopupButton } from "react-calendly";

const numbersData = [
    { title: "8+", description: "Years of Experience", image: "/img/mountain.png" },
    { title: "250+", description: "Clients Satisfied", image: "/img/smile-.png" },
    { title: "300+", description: "Project Delivery", image: "/img/send-.png" },
    { title: "50+", description: "Experts", image: "/img/experience.png" },
];

const missionAndVisionData = [
    {
        title: "Mission",
        description:
            "The Mission of APPRETIVE is to become the first customer-focused business in the world, encouraging and assisting entrepreneurs in creating memorable brands that will endure for many generations.",
        image: "/img/target.png",
    },
    {
        title: "Vision",
        description:
            "Our Vision at APPRETIVE is to achieve extreme operational excellence by developing, designing  and delivering services and products that help our clients grow their businesses more quickly.",
        image: "/img/bulb.png",
    },
    {
        title: "Goal",
        description:
            "We are committed to excellence and have a passion for what we do. We are entirely focused on developing & Designing connections with our customers. Our passion is technology, and our main goal is to help your company.",
        image: "/img/goal.png",
    },
];

// const services = [
//     {
//         title: "Mobile Development",
//         description: "We are developing mobile experiences that serve the unique needs of your target audience.",
//         image: "/img/Mobile.png",
//     },
//     {
//         title: "Web Development",
//         description:
//             "We have a technically Strong development any type of web application. We have successfully delivered projects.",
//         image: "/img/web_site.png",
//     },
//     {
//         title: "Game Development",
//         description:
//             "We provide all type game development making web game or app game. android, ios, custom, html, etc. game development",
//         image: "/img/game.png",
//     },
//     {
//         title: "UI/UX Development",
//         description:
//             "we give best and most unique UI/UX design along with branding for your mobile application and website development.",
//         image: "/img/ui-ux.png",
//     },
//     {
//         title: "Digital Marketing",
//         description:
//             "We provide our clients with a strong creative digital marketing presence, through strategy, positioning, conversion",
//         image: "/img/marketing.png",
//     },
//     {
//         title: "SEO",
//         description:
//             "Achieve the highest possible rankings in Google and more customers by using the affordable best SEO services",
//         image: "/img/SEO.png",
//     },
// ];

const whyUs = [
    {
        title: "Customer Satisfaction",
        icon: "/img/smily-blue.png",
        description: "We provide to our client full satisfaction and on time project and we try to grow their business",
        color: "#3776FF",
    },
    {
        title: "Agile Procedure",
        icon: "/img/ways.png",
        description: "We are management project approach that involves breaking projects into smaller phases.",
        color: "#F25D4D",
    },
    {
        title: "Competitive Pricing",
        icon: "/img/pricing.png",
        description: "We provide less pricing less than other company’s in affordable budget",
        color: "#9836E5",
    },
    {
        title: "Integrity & Transparency",
        icon: "/img/document-cloud.png",
        description: "We provide friendly conversation and client satisfaction easy term and condition",
        color: "#F69511",
    },
];

const featuresData = [
    {
        title: "Our experience",
        icon: "/img/bag.png",
        color: "#FF9437",
        description: "We have 7+ experience in this field and we are growth together.",
    },
    {
        title: "IT Professionals",
        icon: "/img/users-group-2.png",
        color: "#5D52FD",
        description: "We have expert IT professionals who create their work properly and manage all issue",
    },
    {
        title: "Award received",
        icon: "/img/trophy.png",
        color: "#62BA38",
        description: "We're committed to achieving mastery in everything we do. We don't accept mediocre or average",
    },
    {
        title: "Happy clients",
        icon: "/img/smilly.png",
        color: "#F6589E",
        description: "We delivered 50+ android mobile application with out any issues and before timeline",
    },
    {
        title: "Projects delivered",
        icon: "/img/box.png",
        color: "#FF3D57",
        description: "We properly work on any projects and set all issues. We delivered project before timeline.",
    },
    {
        title: "Customer focused",
        icon: "/img/user-checked.png",
        color: "#3CB59F",
        description:
            "We deliver what our clients need and want. They are always the first priority. Even at the cost of profits.",
    },
    {
        title: "Responsibility",
        icon: "/img/thumbs-up.png",
        color: "#F0BE1B",
        description:
            "We take full responsibility for our jobs. We do what is needed even if no one tells us to do it or no credit is given.",
    },
    {
        title: "Community",
        icon: "/img/chat.png",
        color: "#813CF3",
        description: "We work as a community where everyone can thrive. We believe in giving back to people.",
    },
];

const About = () => {
    const [reviewsList, setReviewsList] = useState([]);
    const [read, setRead] = useState(false);
    const [heroExpand, setHeroExpand] = useState(false);
    const scheduleMeetingModalRef = useRef(null);
    const handleClickScheduleMeeting = (e) => {
        // console.log(scheduleMeetingModalRef.current);
        scheduleMeetingModalRef.current.onClick(e);
    };

    const fetchReviews = async (params, headers) => {
        try {
            const { data } = await getReviews(params, headers);
            if (data.status !== "success") throw new Error(data.message);
            console.log(data);
            setReviewsList(data?.data?.Review);
        } catch (err) {
            console.error(err.message);
        }
    };
    useEffect(() => {
        // window.scrollTo(0, 0);
        fetchReviews();
    }, []);

    function stripHtmlTags(html) {
        const words = html?.split(" ");

        const first50Words = words?.slice(0, !heroExpand ? 55 : words?.length);

        // Join the words back into a string
        const result = first50Words?.join(" ");

        return result;
    }

    return (
        <div className="page about-page">
            <div className="bg-el page-header">
                <div className="page-section" style={{ paddingBlock: "0.5rem" }}>
                    <div className="content d-flex flex-wrap-reverse md:flex-nowrap p2 p0-s">
                        <div className="details page-section" style={{ paddingBlock: "1rem" }}>
                            <Typography
                                className="page-heading mb2"
                                variant="h4"
                                component="h1"
                                fontWeight={700}
                                sx={{ maxWidth: "600px" }}
                                style={{ fontFamily: "poppins" }}
                            >
                                Transform your Business with best Technology with best IT Company
                            </Typography>
                            <Typography
                                className="page-description mb3"
                                variant="body1"
                                component="p"
                                color="text.light"
                                sx={{ lineHeight: "26px", fontSize: "17px", textAlign: "justify" }}
                                style={{ fontFamily: "poppins" }}
                            >
                                {stripHtmlTags(
                                    `Currently, one of the most famous software development companies in India is Appretive Infosoft. In 2012, a small team of dedicated individuals with creative goals to succeed in the tech industry provided web design as their main service. With their tireless efforts and passion to the job, the company experienced great growth and achievement in a matter of years. We currently employ more than 50 motivated team members and provide a broad range of services, including the development of mobile applications (iOS and Android), websites (custom, e-commerce, etc.), custom software, desktop applications, blockchain solutions, ERP solutions, FinTech solutions, and game development, addition to nearly everything related to computing and communication technology.`
                                )}
                                <span
                                    style={{
                                        cursor: "pointer",
                                        fontWeight: 600,
                                        fontFamily: "poppins",
                                        fontSize: "16px",
                                    }}
                                    onClick={() => setHeroExpand(!heroExpand)}
                                >
                                    {heroExpand ? " Read Less" : " Read More..."}
                                </span>
                            </Typography>
                            <AppButton1
                                text="Let's Talk"
                                // style={{ padding: "15px 40px", fontSize: "24px" }}
                                onClick={handleClickScheduleMeeting}
                            />
                            <PopupButton
                                ref={scheduleMeetingModalRef}
                                url="https://calendly.com/appretive"
                                rootElement={document.getElementById("root")}
                                styles={{ display: "none" }}
                                text=""
                            />
                        </div>
                        <div className="featured-image f-center">
                            <img src="/img/about.png" alt="Android" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="grow-section">
                <div className="page-section" style={{ paddingBlock: "1rem", fontFamily: "poppins" }}>
                    <Typography variant="h4" component="h3" className="mb5 t-center" fontWeight={600}>
                        <span className="heading-highlighted clr-b">Grow </span>
                        <span className="clr-pm">With Us</span>
                    </Typography>
                    <div className="bg-image">
                        <img src="/img/bg-2.png" alt="bg" />
                    </div>
                    <div className="content d-flex">
                        <div className="image f-center">
                            <img src="/img/grow-wth-us.png" alt="grow-wth-us" />
                        </div>
                        <div className="details">
                            <Typography
                                className="page-heading mb2"
                                variant="h5"
                                component="h2"
                                fontWeight={700}
                                // fontFamily={"Helvetica"}
                                style={{ fontFamily: "poppins" }}
                            >
                                Your digital partner for innovative design and trustworthy Web, Mobile, and Cloud
                                solutions.
                            </Typography>
                            <Typography
                                className="page-description mb3"
                                variant="body1"
                                component="p"
                                color="text.light"
                                sx={{ lineHeight: "28px", textAlign: "justify" }}
                                // fontFamily={"Helvetica"}
                                style={{ fontFamily: "poppins" }}
                            >
                                APPRETIVE INFOSOFT Technology Company is located in Surat. We have assisted startups and
                                businesses in BRIDGING their VISION and REALITY since the early 2010s. Our in-house
                                group of experts provides excellent software development and consulting services for
                                web, mobile, and cloud applications.
                            </Typography>
                            <Typography
                                className={`page-description mb3 ${read ? "" : "ellipsis-2line"}`}
                                variant="body1"
                                component="p"
                                color="text.light"
                                sx={{ lineHeight: "28px" }}
                                // fontFamily={"Helvetica"}
                                style={{ fontFamily: "poppins" }}
                            >
                                Designing, creating, and implementing smart technology solutions is what we do with our
                                clients. Employ our Agile teams to extend their present assets and speed their path to
                                success. With six locations globally and more than 150 employees, we are at the
                                forefront of technological disruption.
                            </Typography>
                            <AppButton1
                                text={read ? "Read less" : "Read More"}
                                // style={{ padding: "15px 40px", fontSize: "24px" }}
                                onClick={() => setRead(!read)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="numbers-section">
                <div className="page-section">
                    <div className="bg-w b-radius-2">
                        <Typography className="mb3" variant="h4" component="h3" textAlign="center" fontWeight={600}>
                            <span className="heading-highlighted clr-b"> We Believe in </span>{" "}
                            <span className="clr-pm">Numbers</span>
                        </Typography>
                        <div className="card-container pt5 flex-wrap-m gap-1 md:gap-3">
                            {numbersData.map((card) => (
                                <div className="card" key={card.title}>
                                    <div className="card-head">
                                        <img src={card.image} alt={card.title} className="card-img" />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-heading">
                                            <Typography variant="h5" component="h5" textAlign="center" fontWeight={600}>
                                                {card.title}
                                            </Typography>
                                        </div>
                                        <div className="card-description" style={{ maxWidth: "270px", margin: "auto" }}>
                                            {card.description}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="service-section">
                <div className="page-section">
                    <Typography className="mb5" variant="h4" component="h3" textAlign="center" fontWeight={600}>
                        <span className="heading-highlighted clr-b">Our </span>
                        <span className="clr-pm">Services</span>
                    </Typography>
                    <div className="card-container">
                        {services.map((service, i) => (
                            <div key={i} className="card">
                                <div className="card-head">
                                    <div className="card-img">
                                        <img src={service.image} alt={service.title} />
                                        <div className="card-img-bg">
                                            <svg
                                                width="130"
                                                height="132"
                                                viewBox="0 0 130 132"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M129.5 60C129.5 96.4508 100.951 116 64.5 116C28.0492 116 10 96.4508 10 60C10 23.5492 24.5492 0 61 0C97.4508 0 129.5 23.5492 129.5 60Z"
                                                    fill="#7FA7FE"
                                                />
                                                <path
                                                    className="logo-bg"
                                                    d="M119.5 76C119.5 112.451 90.9508 132 54.5 132C18.0492 132 0 112.451 0 76C0 39.5492 14.5492 16 51 16C87.4508 16 119.5 39.5492 119.5 76Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Typography variant="h6" component="h4" className="card-heading t-center">
                                        {service.title}
                                    </Typography>
                                    <Typography variant="body1" component="p" className="card-description t-center">
                                        {service.description}
                                    </Typography>
                                </div>
                                <div className="divider d-flex align-items-center justify-content-center gap-1 mt3">
                                    <span
                                        className="d-block w-75 bg-w"
                                        style={{
                                            height: "4px",
                                            maxWidth: "65px",
                                            borderRadius: "100px",
                                        }}
                                    ></span>
                                    <span
                                        className="d-block w-25 bg-w"
                                        style={{
                                            height: "4px",
                                            maxWidth: "15px",
                                            borderRadius: "100px",
                                        }}
                                    ></span>
                                    <Link to={"/"} style={{ marginBottom: "-3px" }}>
                                        <FaArrowRight fontWeight={900} fontSize={25} fill="white" cursor={"pointer"} />
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div> */}
            <div className="mission-section">
                <div className="page-section" style={{ paddingTop: "0" }}>
                    <div className="bg-w pt2 pl3 pr3 b-radius-2">
                        <Typography className="mb2" variant="h4" component="h3" textAlign="center" fontWeight={600}>
                            <span className="heading-highlighted clr-b"> Our </span>{" "}
                            <span className="clr-pm">Mission</span> and
                            <span className="clr-pm"> Vision</span>
                        </Typography>
                        <div className="card-container pt4 flex-wrap-m">
                            {missionAndVisionData.map((card) => (
                                <div className="card" key={card.title}>
                                    <div className="card-head">
                                        <img src={card.image} alt={card.title} className="card-img" width="100%" />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-heading">
                                            <Typography variant="h5" component="h5" textAlign="center" fontWeight={600}>
                                                {card.title}
                                            </Typography>
                                        </div>
                                        <Typography
                                            variant="body"
                                            component="h4"
                                            textAlign="center"
                                            fontWeight={400}
                                            className="card-description"
                                            style={{
                                                maxWidth: "300px",
                                                margin: "auto",
                                                fontFamily: "Helvetica",
                                                lineHeight: "24px",
                                            }}
                                        >
                                            {card.description}
                                        </Typography>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="whyus-section">
                <div className="page-section" style={{ paddingTop: 0 }}>
                    <Typography variant="h4" component="h3" className="mb4 t-center" fontWeight={600}>
                        <span className="heading-highlighted clr-b"> Why </span>
                        <span className="clr-pm">Choose Appretive</span>
                    </Typography>
                    <Typography
                        variant="body"
                        component="p"
                        className="mb5 t-center"
                        color="text.light"
                        sx={{ maxWidth: "351px", mx: "auto", fontSize: "18px", fontWeight: "500" }}
                    >
                        We believe to maintain integrity as well as transparency in our work.
                    </Typography>
                    <div className="card-container d-flex justify-content-center gap-3 flex-wrap">
                        {whyUs.map((feature, i) => (
                            <div className="card" key={feature.title}>
                                <div className="card-head">
                                    <div className="card-img">
                                        <img
                                            src={feature.icon}
                                            alt={feature.title}
                                            width="55px"
                                            height="55px"
                                            style={{ objectFit: "contain" }}
                                        />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Typography
                                        className="card-heading"
                                        variant="body3"
                                        component="h5"
                                        fontWeight={600}
                                        color={feature.color}
                                    >
                                        {feature.title}
                                    </Typography>
                                    <Typography className="card-description" variant="body1" component="p">
                                        {feature.description}
                                    </Typography>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="features-section">
                <div className="page-section">
                    <Typography className="mb5" variant="h4" component="h3" textAlign="center" fontWeight={600}>
                        <span className="heading-highlighted clr-b"> Our </span>{" "}
                        <span className="clr-pm">Features</span>
                    </Typography>
                    <div className="card-container d-flex justify-content-center gap-3 flex-wrap">
                        {featuresData.map((feature, i) => (
                            <div
                                className="card p2"
                                data-index={i + 1}
                                key={feature.title}
                                style={{ borderBottomColor: feature.color }}
                            >
                                <div
                                    className="card-head"
                                    style={{ marginLeft: "calc(var(--app-base-spacing-2)* -1)" }}
                                >
                                    <div className="card-img">
                                        <img src={feature.icon} alt={feature.title} width="70px" />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="card-heading mb1">
                                        <Typography
                                            variant="body3"
                                            component="h5"
                                            fontWeight={600}
                                            color={feature.color}
                                        >
                                            {feature.title}
                                        </Typography>
                                    </div>
                                    <div className="card-description clr-tl mt1">{feature.description}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="review-section">
                <div className="page-section">
                    <Typography className="mb5" variant="h4" component="h3" textAlign="center" fontWeight={600}>
                        <span className="heading-highlighted clr-b"> What's Our </span>{" "}
                        <span className="clr-pm">Client</span> Say
                    </Typography>
                    <ReviewSliderCards data={reviewsList} buttonsId={nanoid()} />
                </div>
            </div>
            <ContactSection text="Contact Us" />
        </div>
    );
};

export default About;
