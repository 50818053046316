import React, { useEffect, useRef, useState } from "react";
import { AppButton1, ReviewSlider } from "../../components";
import { PopupButton } from "react-calendly";
import "swiper/swiper-bundle.min.css";
import "swiper/css";
import "swiper/css/pagination";
import { Typography } from "@mui/material";

import "../../assets/css/mobileAppDev.css";
import { getReviews } from "../../apis";
// import ModelContext from "../../context/contactModelContext";

const data = [
    {
        title: "Safe Payment Gateways",
        description:
            "With our safe payment gateway solutions, you can guarantee your clients' transactions are trustworthy and safe.",
        image: "/img/Secure-Payment-Gateways.png",
    },
    {
        title: "Greater Scope",
        description:
            "With advanced, specially designed Shopify development solutions, you can give your online store more potential.",
        image: "/img/Wider-Reach.png",
    },
    {
        title: "Better classifications",
        description:
            "Improve user experience and product discoverability in your Shopify store by creating more logical and well-organized categories.",
        image: "/img/Refined-categories.png",
    },
];

const stepsData = [
    {
        title: "Connect",
        description:
            "With your Shopify store, you can effortlessly engage with your consumers by utilizing strong communication tools and smooth integrations.",
        image: "/img/Connect.png",
    },
    {
        title: "Build",
        description:
            "With the help of our qualified Shopify development services, create a strong and eye-catching online presence.",
        image: "/img/Build.png",
    },
    {
        title: "Launch",
        description:
            "With our all-inclusive Shopify development and support services, you can easily launch your online store.",
        image: "/img/Launch.png",
    },
];

const technologies = [
    {
        title: "React",
        img: "/img/React-service.png",
    },
    {
        title: "Tailwind Css",
        img: "/img/Tailwind-service.png",
    },
    {
        title: "React Query",
        img: "/img/react-query-logo.png",
    },
    {
        title: "Python",
        img: "/img/Python-service.png",
    },
    {
        title: "Django",
        img: "/img/django-service.png",
    },
    {
        title: "PostgreSql",
        img: "/img/Postgresql-service.png",
    },

    {
        title: "Redis",
        img: "/img/redislogo.svg",
    },
    {
        title: "Flask",
        img: "/img/flask-service.png",
    },
    {
        title: "Web Socket",
        img: "/img/websocketlogo.png",
    },
];

const Shopify = () => {
    const [reviewsList, setReviewsList] = useState([]);
    // const { setModal } = useContext(ModelContext);

    const handleClickScheduleMeeting = (e) => {
        scheduleMeetingModalRef.current.onClick(e);
    };
    const scheduleMeetingModalRef = useRef(null);
    const fetchReviews = async (params, headers) => {
        try {
            const { data } = await getReviews(params, headers);
            if (data.status !== "success") throw new Error(data.message);

            setReviewsList(data?.data?.Review);
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        fetchReviews();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="mt-16">
                <div className="flex flex-wrap-reverse items-center justify-evenly sm:mx-[15px] md:mx-[20px] lg:mx-[50px] px-4 lg:my-10 my-6 pt-12 bg-white">
                    <div className="w-full  lg:max-w-[50%]">
                        <h1 className=" text-[30px]  md:text-[2.5vw] font-bold font-poppins  text-start mb-4 my-5">
                            Give the Power of Mobility to your Shopify eCommerce.
                        </h1>
                        <p className="text-justify font-poppins font-[400] text-[18px] mb-6">
                            On the Shopify platform, our Shopify development service provides all-inclusive solutions
                            for building, customizing, and optimizing your online store. Our specialty is creating
                            visually stunning and intuitive shops that improve client experience and represent the
                            distinct essence of your brand. To optimize your company's operations, our skilled
                            developers can incorporate cutting-edge features, smooth payment channels, and necessary
                            third-party apps.
                        </p>

                        <div className="f-start">
                            <AppButton1
                                variant="h6"
                                style={{ fontFamily: "Gilroy-bold" }}
                                text={"Let's Talk"}
                                onClick={handleClickScheduleMeeting}
                            />
                            <PopupButton
                                ref={scheduleMeetingModalRef}
                                url="https://calendly.com/appretive"
                                rootElement={document.getElementById("root")}
                                styles={{ display: "none" }}
                                text=""
                            />
                        </div>
                    </div>
                    <div className="relative">
                        <img
                            src="/img/Want-to-excel-in-ecommerce.jpg"
                            alt="shopify img"
                            className="w-full max-w-md mx-auto"
                        />
                    </div>
                </div>

                <div className="my-12 max-w-[75%] mx-auto">
                    <h1 className="text-[30px] text-center  md:text-[2.5vw] font-bold font-poppins  my-6">
                        Shopify App Development Tech
                    </h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 mx-auto place-content-center">
                        {technologies.map((tech, index) => (
                            <div
                                key={index}
                                className="flex flex-col items-center justify-center gap-2 p-4 bg-white sm:w-full border-[1px] md:max-w-[250px] rounded-lg shadow-lg  transform transition-transform duration-300 hover:scale-105 hover:shadow-xl group"
                            >
                                <div className="mb-1">
                                    <img className="h-10 w-10" src={tech.img} alt="img" />
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-xl font-semibold font-poppins ">{tech.title}</h2>
                                    <div className="hidden group-hover:block transition-transform duration-300 ease-in-out border-b-2 border-blue-500"></div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="w-full">
                    <div className="text-center mx-auto max-w-[70%]">
                        <h1 className="text-[30px] lg:text-[2.5vw] font-bold mb-4 font-poppins">
                            In just a few hours, set up your Shopify mobile stores!
                        </h1>
                        <p className="text-[20px] font-poppins text-justify px-5 my-6 lg:my-10 mx-auto md:text-center leading-9">
                            Over 80% of your clientele makes purchases using a mobile device. Make a strong, intuitive,
                            and eye-catching mobile e-commerce app that will push your company to new heights. In a day,
                            our mobile specialists will launch your online store on mobile devices! Benefits of having a
                            Shopify mobile app for your online store include:
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-1 place-items-center md:grid-cols-2 lg:grid-cols-3 md:max-w-[75%] mx-auto gap-2">
                    <div className="flex w-full flex-col items-center justify-center border-2 border-blue-500 min-h-[135px] max-w-[350px] transition-transform duration-1000 ease-in-out hover:-translate-y-4 hover:shadow-lg rounded-md">
                        <p className="text-[28px] font-bold">3X</p>
                        <p className="text-[22px]">Transformation</p>
                    </div>
                    <div className="flex w-full flex-col items-center justify-center border-2 border-blue-500 min-h-[135px] max-w-[350px] transition-transform duration-1000 ease-in-out hover:-translate-y-4 hover:shadow-lg rounded-md">
                        <p className="text-[28px] font-bold">5X</p>
                        <p className="text-[22px]">Better Reach</p>
                    </div>
                    <div className="flex w-full flex-col items-center justify-center border-2 border-blue-500 min-h-[135px] max-w-[350px] transition-transform duration-1000 ease-in-out hover:-translate-y-4 hover:shadow-lg rounded-md">
                        <p className="text-[28px] font-bold">4X</p>
                        <p className="text-[22px]">Faster ROI</p>
                    </div>
                </div>

                <div className=" flex gap-2 flex-wrap justify-around  items-center text-center mt-10 mb-12 max-w-[70%] mx-auto p-7 border-[15px] border-blue-500 rounded-xl shadow-md round">
                    <p className="text-[22px] font-bold font-poppins">Request a free quote in less than a minute</p>
                    <div className="f-start">
                        <AppButton1
                            variant="h6"
                            style={{ fontFamily: "Gilroy-bold" }}
                            text={"Let's Talk"}
                            onClick={handleClickScheduleMeeting}
                        />
                        <PopupButton
                            ref={scheduleMeetingModalRef}
                            url="https://calendly.com/appretive"
                            rootElement={document.getElementById("root")}
                            styles={{ display: "none" }}
                            text=""
                        />
                    </div>
                </div>

                <div className="whychoose">
                    <div className="mx-auto text-center max-w-[80%]">
                        <h1 className="text-[30px] text-justify md:text-center lg:text-[2.5vw] font-bold font-poppins mb-4">
                            All Sale, Without Work <br /> Open the Shopify mobile app with almost no effort at all.
                        </h1>
                        <p className="text-[19px] text-[#696969] font-lato text-justify md:text-center  mx-auto">
                            Keep relaxed and settle in. You can just wait and observe while our Shopify specialists are
                            on work. We transform the Shopify store into an easily understood mobile app that helps
                            highlight and show your products, offering store integration easy to manage.
                        </p>
                    </div>
                    <div className="my-16 max-w-[75%] mx-auto">
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3  mx-auto place-content-center">
                            {data.map((service, index) => (
                                <div
                                    key={index}
                                    className="p-5 flex flex-col justify-center items-center text-center bg-white sm:w-full border-[1px] md:max-w-[400px] rounded-lg shadow-lg  transform transition-transform ease-in-out duration-300 hover:scale-105 hover:shadow-xl group"
                                >
                                    <div className="mb-[15px]">
                                        <img src={service.image} alt="img" className="h-[100px] w-[100px]" />
                                    </div>
                                    <div className="my-4">
                                        <h2 className="text-[24px]  font-semibold  capitalize">{service.title}</h2>
                                        <div className="hidden transition-transform duration-2000 ease-in-out border-b-2 border-blue-500 group-hover:block "></div>
                                    </div>
                                    <p className="capitalize font-poppins font-[500] my-3 text-[16px] text-center">
                                        {service.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row items-center space-x-10 w-full p-6  lg:max-w-[85%] mx-auto bg-white gap-5">
                    <div className="w-full md:w-[40%] flex justify-center">
                        <img
                            src="/img/Shopify-Benefits-you-cannot-ignore.png"
                            alt="Marketing Illustration"
                            //   className="max-w-[580px] max-h-[580px]"
                        />
                    </div>
                    <div className="w-[90%] mx-auto md:w-[60%] mt-6 md:mt-0 md:ml-6">
                        <h2 className="text-[30px] max-w-[80%] text-justify lg:text-[2.5vw] font-bold mb-4 font-poppins">
                            Turn Users Into Constant Customers
                        </h2>
                        <p className="text-[20px] font-poppins font-[400] text-justify my-10  mx-auto">
                            We are building a stunning Shopify mobile app for your online store can increase sales. Many
                            companies have seen incredible results from the introduction of their Shopify mobile app in
                            a relatively short period of time.
                        </p>
                        <ul className="list-none space-y-3 text-left mb-8 text-[20px]  font-poppins font-[400]">
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className="">Higher frequencies of conversation</span>
                            </li>
                            <li className="flex items-start">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className="">easier checkout </span>
                            </li>
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className=""> Spend less money on advertising </span>
                            </li>
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className=""> Targeted users receives free push notifications</span>
                            </li>
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className="">Remove back on the expense of acquiring new customers </span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="steps">
                    <div className="mx-auto text-center max-w-[80%]">
                        <h1 className="text-[30px] text-justify md:text-center lg:text-[2.5vw] font-poppins font-bold mb-4">
                            It's as simple as A, B and C !
                        </h1>
                        <p className="text-[20px] font-poppins font-[400] text-justify md:text-center  mx-auto">
                            Transform your Shopify store into a profitable mobile enterprise with an app. We provide you
                            with the greatest Shopify tools to take advantage of user-friendly features. Experience the
                            most efficient shopping platform in entire history.
                        </p>
                    </div>
                    <div className="my-16 max-w-[75%] mx-auto">
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3  mx-auto place-content-center">
                            {stepsData.map((service, index) => (
                                <div
                                    key={index}
                                    className="p-5 flex flex-col justify-center items-center text-center bg-white sm:w-full border-[1px] md:max-w-[400px] rounded-lg shadow-lg  transform transition-transform ease-in-out duration-300 hover:scale-105 hover:shadow-xl group"
                                >
                                    <div className="mb-[15px]">
                                        <img src={service.image} alt="img" className="h-[100px] w-[100px]" />
                                    </div>
                                    <div className="my-4">
                                        <h2 className="text-[24px]  font-semibold  capitalize">{service.title}</h2>
                                        <div className="hidden transition-transform duration-2000 ease-in-out border-b-2 border-blue-500 group-hover:block "></div>
                                    </div>
                                    <p className="capitalize font-poppins font-[500] my-3 text-[16px] text-center">
                                        {service.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row-reverse   items-center space-x-10 w-full p-6  lg:max-w-[85%] mx-auto bg-white">
                    <div className="w-full md:w-[40%] flex justify-center">
                        <img
                            src="/img/Small-store-Big-store.png"
                            alt="Marketing Illustration"
                            //   className="max-w-[580px] max-h-[580px]"
                        />
                    </div>
                    <div className="w-full md:w-[60%] mt-6 md:mt-0 md:mr-6">
                        <h2 className="text-[30px] text-justify lg:text-[2.5vw] font-bold mb-4 font-poppins capitalize">
                            small shop ? big retailer ? <br /> Not a problem !
                        </h2>
                        <p className="text-[20px] font-poppins text-justify my-10  mx-auto font-[400]">
                            You can tailor your Shopify store to work as a mobile app on any platform. Your product
                            listing is what matters, and we are great at it! The industry is irrelevant! Any vertical
                            Shopify store may be transformed into stunning Shopify mobile apps.
                        </p>
                        <ul className="list-none space-y-3 text-left mb-8 text-[20px]  font-poppins font-[400]">
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className="">Maintain your lead in the industry</span>
                            </li>
                            <li className="flex items-start">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className="">Amazing features of the Shopify app to wow your customers </span>
                            </li>
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className=""> Unlimited push notifications! </span>
                            </li>
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className=""> Include loyalty programs to attract new clients.</span>
                            </li>
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className="">Bright themes and settings for in-app chat</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className=" flex gap-2 flex-wrap justify-around  items-center text-center mt-10 mb-12 max-w-[70%] mx-auto p-7 border-[15px] border-blue-500 rounded-xl shadow-md round">
                    <p className="text-[22px] font-bold font-poppins">Request a free quote in less than a minute</p>
                    <div className="f-start">
                        <AppButton1
                            variant="h6"
                            style={{ fontFamily: "Gilroy-bold" }}
                            text={"Let's Talk"}
                            onClick={handleClickScheduleMeeting}
                        />
                        <PopupButton
                            ref={scheduleMeetingModalRef}
                            url="https://calendly.com/appretive"
                            rootElement={document.getElementById("root")}
                            styles={{ display: "none" }}
                            text=""
                        />
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row items-center space-x-10 w-full p-6  lg:max-w-[85%] mx-auto bg-white gap-5">
                    <div className="w-full md:w-[40%] flex justify-center">
                        <img
                            src="/img/no-time-to-design.png"
                            alt="Marketing Illustration"
                            //   className="max-w-[580px] max-h-[580px]"
                        />
                    </div>
                    <div className="w-[90%] mx-auto md:w-[60%] mt-6 md:mt-0 md:ml-6">
                        <h2 className="text-[30px] max-w-[80%] text-justify lg:text-[2.5vw] font-bold mb-4 font-poppins">
                            Too busy to create and carry out ? Give it to us!
                        </h2>
                        <p className="text-[19px] font-poppins font-[400] text-justify my-10  mx-auto">
                            Yes, you are quite correct! Our seasoned Shopify professionals will expertly "Shopify" your
                            company for mobile so you can take advantage of the limitless prospects available to you.
                        </p>
                        <ul className="list-none space-y-3 text-left mb-8 text-[18px]  font-poppins font-[400] w-[95%]">
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className="">Send us your application information and a query.</span>
                            </li>
                            <li className="flex items-start">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className="">We assess your Shopify store and provide a strategy </span>
                            </li>
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className="">
                                    {" "}
                                    Following the agreement, we present you with design prototypes.{" "}
                                </span>
                            </li>
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span>We create and transform your Shopify website into a mobile application.</span>
                            </li>
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className="">Get outstanding post-purchase assistance</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="clientReview my-10  mb-[-100px] lg:mb-[100px] ">
                    <div className="mx-auto text-center">
                        <h1 className="text-[26px] text-center lg:text-[2.5vw] font-bold font-poppins mb-4 capitalize">
                            Some of our happy clients
                        </h1>
                        <p className=" px-5 text-[19px] font-[400] font-poppins text-center">
                            Our clients' reviews demonstrate how satisfied they are with our services
                        </p>
                    </div>

                    <div className="contact-review ">
                        <div className="page-section contact-review-flex sm:flex flex-wrap justify-evenly">
                            <div className={"contact-section"}>
                                <div className={`page-section`}>
                                    <Typography
                                        className="mb3 t-start clr-w font-bold"
                                        variant="h4"
                                        component="h3"
                                        fontWeight={900}
                                        style={{ fontFamily: "Gilroy-bold" }}
                                    >
                                        Let’s Turn
                                        <span className="clr-pm">Your Ideas</span> into Reality
                                    </Typography>
                                    <Typography
                                        className="mb3 t-start clr-w"
                                        variant="h6"
                                        component="p"
                                        style={{ fontFamily: "Gilroy-bold" }}
                                    >
                                        Let's innovate for your technology needs with Appretive Infotech.
                                    </Typography>
                                    <div className="f-start">
                                        <AppButton1
                                            variant="h6"
                                            style={{ fontFamily: "Gilroy-bold" }}
                                            text={"Let's Talk"}
                                            onClick={handleClickScheduleMeeting}
                                        />
                                        <PopupButton
                                            ref={scheduleMeetingModalRef}
                                            url="https://calendly.com/appretive"
                                            rootElement={document.getElementById("root")}
                                            styles={{ display: "none" }}
                                            text=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="review-section review-section  lg:mb-[-180px]">
                                <div className="page-section" style={{ paddingTop: 0, paddingLeft: 0 }}>
                                    <div className="bg-w shadow review-box w-[95%]">
                                        {/* <h2
                                        className="mb2 t-center"
                                        style={{ fontSize: "36px", fontWeight: "600", padding: "20px" }}
                                    > */}
                                        <Typography
                                            className="mb1 mt1 t-center clr-w"
                                            variant="h4"
                                            component="h3"
                                            fontWeight={600}
                                            style={{ fontFamily: "Gilroy-bold" }}
                                        >
                                            <span className="heading-highlighted clr-b">
                                                {" "}
                                                What’s Our <span className="clr-pm">Client</span> Say
                                            </span>{" "}
                                        </Typography>
                                        <div className="slider f-center">
                                            <ReviewSlider data={reviewsList} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PopupButton
                        ref={scheduleMeetingModalRef}
                        url="https://calendly.com/appretive"
                        rootElement={document.getElementById("root")}
                        styles={{ display: "none" }}
                        text=""
                    />
                </div>
            </div>
        </>
    );
};

export default Shopify;
