const config = {
  contact: {
    email: "hr@appretive.com",
    mobile: "+91 79842 05484",
    address:
      "455, 4th Floor, Royal Arcade, near Sarthana Jakatnaka, Surat, Gujarat 395006",
  },
  // apiUrl: process.env.API_URL || "http://192.168.0.105:8002",
  apiUrl: process.env.API_URL || "https://api.appretive.com",
  //   apiUrl: process.env.API_URL || "http://localhost:8002",
};

export default config;
