import { Typography } from "@mui/material";
import React, { useEffect } from "react";

import { PageHeader } from "../../components";
import "../../assets/css/ourWorking.css";

const data = [
    {
        title: "Fitness App Development",
        logo: "/img/fitness-ui-logo.png",
        img: "/img/fitness-ui.png",
        description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy...",
        techs: ["/img/figma.png", "/img/apple.png", "/img/react.png"],
    },
    {
        title: "Coffs web Development",
        logo: "/img/coffie-web-logo.png",
        img: "/img/coffie-web.png",
        description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy...",
        techs: ["/img/figma.png", "/img/apple.png", "/img/react.png"],
    },
    {
        title: "Fitness App Development",
        logo: "/img/fitness-ui-logo.png",
        img: "/img/fitness-ui.png",
        description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy...",
        techs: ["/img/figma.png", "/img/apple.png", "/img/react.png"],
    },
    {
        title: "Coffs web Development",
        logo: "/img/coffie-web-logo.png",
        img: "/img/coffie-web.png",
        description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy...",
        techs: ["/img/figma.png", "/img/apple.png", "/img/react.png"],
    },
];

const OurWorking = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="page our-working-page">
            <div className="page-head">
                <div className="section-1240">
                    <PageHeader heading="Our Working" />
                </div>
            </div>
            <div className="technology-section">
                <div className="section-1240">
                    <div className="f-center mb5">
                        <Typography variant="body3">All</Typography>
                        <Typography variant="body3">Technology</Typography>
                        <Typography variant="body3">Categories</Typography>
                    </div>
                    <div className="card-container">
                        {data.map((card, i) => (
                            <div className="card" key={i}>
                                <div className="card-head">
                                    <div className="card-img-c">
                                        <img className="card-img" src={card.img} alt={card.title} />
                                    </div>
                                    <div className="card-avatar-c">
                                        <img className="card-avatar" src={card.logo} alt={card.title} />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-heading">{card.title}</h5>
                                    <p className="card-content">{card.description}</p>
                                    <div className="img-list d-flex gap-2">
                                        {card.techs.map((img) => (
                                            <img key={img} src={img} alt="" />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurWorking;
