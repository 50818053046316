import { ThemeProvider } from "@mui/material";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./App.css";
import "./assets/css/styles.css";
import "./assets/css/animation.css";
import theme from "./assets/js/theme";

import {
  About,
  Blog,
  BlogList,
  CaseStudy,
  CaseStudyList,
  ContactUs,
  Home,
  OurWorking,
  Page404,
  Portfolio,
  Project,
  Root,
  Services,
  MobileAppDev,
  WebDev,
  SoftwareDev,
  ECommerceDev,
  SAPservice,
  IOT,
  BlockChain,
  Shopify,
  EcommerceAds,
} from "./pages";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/services/:slug",
          element: <Services />,
        },
        {
          path: "/portfolio",
          element: <Portfolio />,
        },
        {
          path: "/portfolio/:slug",
          element: <Project />,
        },
        {
          path: "/about-us",
          element: <About />,
        },
        {
          path: "/contact-us",
          element: <ContactUs />,
        },
        {
          path: "/our-working",
          element: <OurWorking />,
        },
        {
          path: "/blog",
          element: <BlogList />,
        },
        {
          path: "/blog/:name",
          element: <Blog />,
        },
        // {
        //     path: "/gif",
        //     element: <Gif />,
        // },
        {
          path: "/case-study",
          element: <CaseStudyList />,
        },
        {
          path: "/case-study/:name",
          element: <CaseStudy />,
        },
        {
          path: "/schedule-meeting",
          element: <CaseStudy />,
        },

        {
          path: "/service/mobile-app-development",
          element: <MobileAppDev />,
        },
        {
          path: "/service/web-development",
          element: <WebDev />,
        },
        {
          path: "/service/software-development",
          element: <SoftwareDev />,
        },
        {
          path: "/service/e-commerce-devlopment",
          element: <ECommerceDev />,
        },
        {
          path: "/service/sap-services",
          element: <SAPservice />,
        },
        {
          path: "/service/internet-of-things",
          element: <IOT />,
        },
        {
          path: "/service/blockchain-development",
          element: <BlockChain />,
        },
        {
          path: "/service/shopify-app-development",
          element: <Shopify />,
        },
        {
          path: "/service/e-commerce-shopping-ads",
          element: <EcommerceAds />,
        },
      ],
    },
    {
      path: "*",
      element: <Page404 />,
    },
  ]);

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
