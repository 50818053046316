import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { AppButton1 } from "../../../components";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

const HeroSectionSwiperList = [
    {
        heroHeading: (
            <Typography
                className="heading"
                style={{ fontFamily: "poppins" }}
                component="h3"
                fontWeight={600}
                variant="h4"
            >
                <span>Best Solution For </span>
                <span className="d-block clr-pm" style={{ fontFamily: "Gilroy-Bold" }}>
                    Mobile App Design
                </span>
                <span>and Development</span>
            </Typography>
        ),
        heroDesc: `Our area of expertise is developing unique mobile applications for different operating systems, including iOS and Android. We provide a variety of services, such as strategy and consulting, UI/UX design and development, testing.`,
        imageUrl: "/img/hero_mobile_app.png",
    },
    {
        heroHeading: (
            <Typography
                className="heading"
                style={{ fontFamily: "poppins" }}
                component="h3"
                fontWeight={600}
                variant="h4"
            >
                <span>Top choice For </span>
                <span className="d-block clr-pm" style={{ fontFamily: "Gilroy-Bold" }}>
                    Website Design
                </span>
                <span>and Development</span>
            </Typography>
        ),
        heroDesc: `Our Website developing services include consultation and planning, UI/UX design, front-end and back-end development, e-commerce solutions, and search engine optimization (SEO). We provide a variety of services to fulfill the demands of our clients.`,
        imageUrl: "/img/hero_website.png",
    },
    {
        heroHeading: (
            <Typography
                className="heading"
                style={{
                    fontFamily: "poppins",
                }}
                component="h3"
                fontWeight={600}
                variant="h4"
            >
                <span>Best Option for </span>
                <span className="d-block clr-pm" style={{ fontFamily: "Gilroy-Bold" }}>
                    Game Design{" "}
                </span>
                <span>and Development</span>
            </Typography>
        ),
        heroDesc: `We specializes in creating interactive and engaging digital games for various platforms, including mobile, PC, and web. We typically offers a wide range of services to support clients throughout the entire game development process.`,
        imageUrl: "/img/hero_game_design.png",
    },
    {
        heroHeading: (
            <Typography
                className="heading"
                style={{ fontFamily: "poppins" }}
                component="h3"
                fontWeight={600}
                variant="h4"
            >
                <span>Best Choice for </span>
                <span className="d-block clr-pm" style={{ fontFamily: "Gilroy-Bold" }}>
                    Dashboard Design{" "}
                </span>
                and
                <span> Management</span>
            </Typography>
        ),
        heroDesc: `Our company specializes in developing user-friendly and functional dashboard solutions for companies and organizations in a range of sectors. In order to assist clients in effectively managing and visualizing their data, we provide a variety of services.`,
        imageUrl: "/img/hero_dashboard.png",
    },
];

const HeroSection = ({ handleClickScheduleMeeting }) => {
    return (
        <div className="hero-section min-w-[90%] px-2">
            <Swiper
                slidesPerView={"auto"}
                spaceBetween={30}
                modules={[Autoplay, Pagination]}
                autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    el: ".cards-paginations",
                    clickable: true,
                }}
            >
                {HeroSectionSwiperList &&
                    HeroSectionSwiperList?.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div className="pt1">
                                <Grid
                                    container
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    wrap="wrap-reverse"
                                    columnSpacing={4}
                                >
                                    <Grid item sm={12} md={6}>
                                        <div className="hero-left">
                                            <Box
                                                className="slide-right"
                                                sx={{
                                                    backgroundImage: "url('/img/home_hero_left_bg_app_logo.png')",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundPosition: "left center",
                                                    backgroundSize: "contain",
                                                }}
                                            >
                                                {item?.heroHeading}
                                                <Typography
                                                    className="description text-justify"
                                                    style={{ fontFamily: "poppins" }}
                                                    component="p"
                                                    fontWeight={500}
                                                    color="text.dark"
                                                    sx={{
                                                        // maxWidth: "580px",
                                                        mt: 5,
                                                        lineHeight: "36px",
                                                        fontSize: "22px",
                                                    }}
                                                >
                                                    {item?.heroDesc}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    mt: 4,
                                                    position: "relative",
                                                    display: "inline-block",
                                                }}
                                            >
                                                <AppButton1
                                                    className="shake-horizontal quicksand-fonts"
                                                    text="Let's Talk"
                                                    variant="body3"
                                                    onClick={handleClickScheduleMeeting}
                                                />
                                            </Box>
                                        </div>
                                    </Grid>
                                    <Grid item sm={12} md={5}>
                                        <div className="hero-right ">
                                            <Box className="hero-img-box slide-center xl:slide-left">
                                                <img className="hero-img" src={item?.imageUrl} alt="" />
                                            </Box>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </SwiperSlide>
                    ))}
            </Swiper>
            <div className="cards-paginations f-center mt-4 mb3 gap-1"></div>
        </div>
    );
};

export default HeroSection;
