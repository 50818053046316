import { useEffect, useState } from "react";
import { Typography, IconButton } from "@mui/material";

import { nanoid } from "nanoid";
import { BsArrowRightShort, BsFillTelephoneFill } from "react-icons/bs";
import "../../assets/css/popup.css";
import config from "../../config/config";
import { MdLocationPin, MdOutlineAlternateEmail } from "react-icons/md";
import AppButton1 from "../AppButton1/AppButton1";
import { submitForm } from "../../apis";
import { FaRegTimesCircle } from "react-icons/fa";

export const Input = ({
  type,
  required,
  label,
  name,
  value,
  id = nanoid(),
  ...props
}) => {
  return (
    <div className="input-field min-w">
      <label htmlFor={`popup-${id} capatalize`}>{label}</label>
      <input
        id={`popup-${id}`}
        type={type || "text"}
        name={name}
        className="input"
        required={required || true}
        value={value}
        {...props}
      />
    </div>
  );
};

const TextArea = ({
  type,
  required,
  label,
  name,
  value,
  id = nanoid(),
  ...props
}) => {
  return (
    <div className="input-field">
      <label htmlFor={`popup-${id}`}>{label}</label>
      <textarea
        id={`popup-${id}`}
        rows="6"
        name={name}
        required={required || true}
        value={value}
        {...props}
      />
    </div>
  );
};

const Select = ({
  children,
  required,
  label,
  value,
  name,
  onChange,
  id = nanoid(),
  ...props
}) => {
  return (
    <div className="input-field min-w">
      <label htmlFor={`popup-${id}`}>{label}</label>
      <select
        name={name}
        id={`popup-${id}`}
        value={value}
        onChange={onChange}
        required={required || true}
      >
        {children}
      </select>
    </div>
  );
};

const countryList = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan (Province of China)",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

const ContactPopup = ({ open = false, onClose }) => {
  const [popup, setPopup] = useState({ class: "popup" });
  const [formData, setFormData] = useState({
    formName: "Quotes",
    name: "",
    email: "",
    mobile: "",
    looking: " ",
    budget: " ",
    date: "",
    country: " ",
    description: "",
    file: "",
  });

  useEffect(() => {
    if (open) setPopup({ ...popup, class: "popup show" });
    if (!open) {
      setPopup({ ...popup, class: "popup" });
      if (onClose) onClose(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClickClose = (e) => {
    onClose(false);
  };
  const handleChangeFormFields = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    try {
      const { data } = await submitForm(formData);
      if (data.status !== "success") throw new Error(data.message);
      setFormData({
        formName: "Quotes",
        name: "",
        email: "",
        mobile: "",
        looking: " ",
        budget: " ",
        date: "",
        country: " ",
        description: "",
        file: "",
      });
    } catch (err) {
      console.error(err);
    }
  };

  const contactPlaces = [
    {
      title: "India (H/Q)",
      content: "Surat, India",
      img: "/img/taj.png",
      flag: "/img/ind-flag.png",
    },
    // { title: "New Zeeland", content: "Auckland, New Zeeland", img: "/img/nz.png", flag: "/img/nz_flag.png" },
    {
      title: "UAE",
      content: "Abu Dhabi, Dubai",
      img: "/img/dubai.png",
      flag: "/img/dubai.png",
    },
    {
      title: "Australia",
      content: "Melbourne, Australia",
      img: "/img/australia.png",
      flag: "/img/australia.png",
    },
    {
      title: "UK",
      content: "Cambridge, United Kingdom",
      img: "/img/usa.png",
      flag: "/img/usa.png",
    },
    {
      title: "USA",
      content: "New York, United States",
      img: "/img/usa.png",
      flag: "/img/usa-flag.png",
    },
  ];

  return (
    <div className={popup.class}>
      <div className="container bg-w">
        <div
          className="d-flex align-item-strach top"
          style={{ backgroundColor: "var(--app-primary-main)" }}
        >
          <div className="close-btn">
            <IconButton color="white" onClick={handleClickClose}>
              <FaRegTimesCircle
                fill="var(--app-color-white)"
                fontSize={"larger"}
                fontWeight={200}
              />
            </IconButton>
          </div>
          <div className="bg-w  form-container">
            <div className="contact-form mt4 ml3">
              <div className="details">
                <Typography
                  variant="h5"
                  component="h4"
                  fontWeight={600}
                  color="primary.main"
                  mb={1}
                >
                  Get A Perfect Quote
                </Typography>
                <Typography variant="body1" component="p">
                  Have questions about your idea? Drop in your details to
                  discuss with our expert
                </Typography>
              </div>
              <form onSubmit={handleSubmitForm}>
                <div className="field-group d-flex flex-wrap gap-2 justify-start">
                  <Input
                    label="Name"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChangeFormFields}
                  />
                  <Input
                    label="Email"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChangeFormFields}
                  />
                </div>
                <div className="field-group d-flex flex-wrap gap-2">
                  <Input
                    label="Contact no. / WhatsApp no."
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChangeFormFields}
                  />
                  <Select
                    variant="standard"
                    sx={{
                      pb: 2,
                      "& .MuiSelect-select": { bgcolor: "#FFFFFF !important" },
                    }}
                    name="looking"
                    value={formData.looking}
                    onChange={handleChangeFormFields}
                    label="What are you looking for ?"
                  >
                    <option value=" " disabled>
                      What are you looking for ?
                    </option>
                    <option value="mobile_app">Mobile App</option>
                    <option value="web_app">Web App</option>
                  </Select>
                </div>
                <div className="field-group d-flex flex-wrap gap-2">
                  <Select
                    variant="standard"
                    sx={{
                      pb: 2,
                      "& .MuiSelect-select": { bgcolor: "#FFFFFF !important" },
                    }}
                    name="budget"
                    value={formData.budget}
                    autoWidth
                    onChange={handleChangeFormFields}
                    label="Budget"
                  >
                    <option value=" " disabled>
                      Budget?
                    </option>
                    <option value="10000-20000">10000₹ - 20000₹ </option>
                    <option value="25000-50000">25000₹ - 50000₹</option>
                    <option value="51000-100000">51000₹ - 100000₹</option>
                    <option value="100000-500000">100000₹ - 500000₹</option>
                  </Select>
                  <Input
                    label="How Soon you want to start ?"
                    name="date"
                    type="date"
                    value={formData.date}
                    onChange={handleChangeFormFields}
                  />
                  <Select
                    variant="standard"
                    sx={{
                      pb: 2,
                      "& .MuiSelect-select": { bgcolor: "#FFFFFF !important" },
                    }}
                    name="country"
                    value={formData.country}
                    onChange={handleChangeFormFields}
                    label="Select Country"
                  >
                    <option value=" " disabled>
                      Select Country
                    </option>
                    {countryList?.map((country, i) => (
                      <option key={i} value={country}>
                        {country}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="w-100 mt2">
                  <TextArea
                    label="Brief Project Description / Requirement"
                    name="description"
                    value={formData.description}
                    onChange={handleChangeFormFields}
                    rows={2}
                  />
                </div>
                <div className="w-100 mt2">
                  <Input
                    label="Want to share file ?"
                    name="file"
                    placeholder="https://gdrive.com/link/to/file"
                    value={formData.file}
                    onChange={handleChangeFormFields}
                  />
                </div>
                <div className="w-100 mt4">
                  <AppButton1 text="Get A Quote" />
                </div>
              </form>
            </div>
          </div>
          <div className="details-banner ml3">
            <Typography
              variant="h5"
              component="h4"
              fontWeight={600}
              color="#ffffff"
              mb={1}
            >
              Here's what you will get after submitting yourproject details:
            </Typography>
            <div className="list pt3">
              <div className="d-flex align-items-start gap-1 mb1">
                <BsArrowRightShort
                  style={{ fontSize: "30px", fill: "var(--app-color-white)" }}
                  className="mt-1"
                />
                <Typography variant="h6" component="p" color="#ffffff">
                  A strict{" "}
                  <span style={{ color: "#F8DF00" }}>
                    non-disclosure policy.
                  </span>
                </Typography>
              </div>
              <div className="d-flex align-items-start gap-1 mb1">
                <BsArrowRightShort
                  style={{ fontSize: "30px", fill: "var(--app-color-white)" }}
                  className="mt-1"
                />
                <Typography variant="h6" component="p" color="#ffffff">
                  Get in discuss with our experts.
                </Typography>
              </div>
              <div className="d-flex align-items-start gap-1 mb1">
                <BsArrowRightShort
                  style={{ fontSize: "30px", fill: "var(--app-color-white)" }}
                  className="mt-1"
                />
                <Typography variant="h6" component="p" color="#ffffff">
                  Get a{" "}
                  <span style={{ color: "#F8DF00" }}>free consultation.</span>
                </Typography>
              </div>
              <div className="d-flex align-items-start gap-1 mb1">
                <BsArrowRightShort
                  style={{ fontSize: "30px", fill: "var(--app-color-white)" }}
                  className="mt-1"
                />
                <Typography variant="h6" component="p" color="#ffffff">
                  Turn your idea into an exceptional app.
                </Typography>
              </div>
              <div className="d-flex align-items-start gap-1 mb1">
                <BsArrowRightShort
                  style={{ fontSize: "30px", fill: "var(--app-color-white)" }}
                  className="mt-1"
                />
                <Typography variant="h6" component="p" color="#ffffff">
                  Suggestions on{" "}
                  <span style={{ color: "#F8DF00" }}>
                    revenue models & planning.
                  </span>
                </Typography>
              </div>
              <div className="d-flex align-items-start gap-1 mb1">
                <BsArrowRightShort
                  style={{ fontSize: "30px", fill: "var(--app-color-white)" }}
                  className="mt-1"
                />
                <Typography variant="h6" component="p" color="#ffffff">
                  No obligation proposal.
                </Typography>
              </div>
              <div className="d-flex align-items-start gap-1 mb1">
                <BsArrowRightShort
                  style={{ fontSize: "30px", fill: "var(--app-color-white)" }}
                  className="mt-1"
                />
                <Typography variant="h6" component="p" color="#ffffff">
                  Action plan to start your project.
                </Typography>
              </div>
              <div className="d-flex align-items-start gap-1 mb1">
                <BsArrowRightShort
                  style={{ fontSize: "30px", fill: "var(--app-color-white)" }}
                  className="mt-1"
                />
                <Typography variant="h6" component="p" color="#ffffff">
                  We respond to you{" "}
                  <span style={{ color: "#F8DF00" }}>within 8 hours.</span>
                </Typography>
              </div>
              <div className="d-flex align-items-start gap-1 mb1">
                <BsArrowRightShort
                  style={{ fontSize: "30px", fill: "var(--app-color-white)" }}
                  className="mt-1"
                />
                <Typography variant="h6" component="p" color="#ffffff">
                  Detailed articulate email updates{" "}
                  <span style={{ color: "#F8DF00" }}>within 24 hours.</span>
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bg bg-w">
          <div className="bg-el">
            <div className="page-section mb4  mt4" style={{ paddingBlock: 0 }}>
              <div className="card-container d-flex justify-content-between align-items-center pb5 flex-wrap">
                {contactPlaces.map((ele, i) => (
                  <div
                    key={i}
                    className="card d-flex flex-column align-items-center it"
                  >
                    <div className="card-head">
                      <div className="card-img">
                        <img
                          src={ele.flag}
                          className="my-6"
                          alt={ele.title}
                          width="100px"
                        />
                      </div>
                    </div>
                    <div className="card-body" style={{ marginTop: "-15px" }}>
                      <Typography
                        variant="h6"
                        component="h2"
                        className="card-heading t-center"
                      >
                        {ele.title}
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="page-section mb4  mt4" style={{ paddingBlock: 0 }}>
            <div className="contact-details d-flex  justify-content-between gap-2 mt5">
              <div className="item d-flex gap-2 mb2 flex-1">
                <div className="icon">
                  <BsFillTelephoneFill
                    style={{
                      fill: "var(--app-primary-main)",
                      fontSize: "30px",
                    }}
                  />
                </div>
                <div className="details">
                  <Typography
                    className="mb1"
                    variant="body3"
                    component="p"
                    color="primary.main"
                    fontWeight={600}
                  >
                    Phone/WhatsApp
                  </Typography>
                  <Typography variant="body3" component="p">
                    {config.contact.mobile}
                  </Typography>
                </div>
              </div>
              <div className="item d-flex gap-2 mb2 flex-1">
                <div className="icon">
                  <MdOutlineAlternateEmail
                    style={{
                      fill: "var(--app-primary-main)",
                      fontSize: "30px",
                    }}
                  />
                </div>
                <div className="details">
                  <Typography
                    className="mb1"
                    variant="body3"
                    component="p"
                    color="primary.main"
                    fontWeight={600}
                  >
                    Email
                  </Typography>
                  <Typography variant="body3" component="p">
                    {config.contact.email}
                  </Typography>
                </div>
              </div>
              <div className="item d-flex gap-2 mb-1 flex-1">
                <div className="icon">
                  <MdLocationPin
                    fontSize={"30px"}
                    style={{ fill: "var(--app-primary-main)" }}
                  />
                </div>
                <div className="details">
                  <Typography
                    className="mb1"
                    variant="body3"
                    component="p"
                    color="primary.main"
                    fontWeight={600}
                  >
                    Address
                  </Typography>
                  <Typography
                    variant="body3"
                    component="p"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {config.contact.address}
                  </Typography>
                </div>
              </div>
            </div>
            {/* <div className="map-img mt5 f-center">
                            <img src="/img/world-map.png" alt="map" style={{ maxWidth: "950px", width: "100%" }} />
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPopup;
