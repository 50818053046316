import { Typography } from "@mui/material";
import { BsArrowRight } from "react-icons/bs";

import "../../assets/css/services.css";
import { AppButton1, ContactSection } from "../../components";
import { useContext, useEffect, useRef, useState } from "react";
import { getServicePages } from "../../apis";
import { useParams } from "react-router-dom";
import config from "../../config/config";
import Page404 from "../Page404/Page404";
import { PopupButton } from "react-calendly";
import ModelContext from "../../context/contactModelContext";

const industries = [
  { icon: "/img/computer.png", heading: "Retail, Ecommerce" },
  { icon: "/img/e-learning.png", heading: "Education & E - Learning" },
  { icon: "/img/health.png", heading: "Healthcare & Fitness" },
  { icon: "/img/social.png", heading: "Social Networking" },
  { icon: "/img/house.png", heading: "Real Estate" },
  { icon: "/img/maps.png", heading: "Travel and Map" },
  { icon: "/img/food.png", heading: "Food & Restaurant" },
  { icon: "/img/games.png", heading: "Gaming" },
];

const expertise = [
  { icon: "/img/expert.png", title: "20+", description: "Experts" },
  {
    icon: "/img/experiance.png",
    title: "14+",
    description: "Year of  Experience",
  },
  {
    icon: "/img/projects.png",
    title: "180+",
    description: "Project Completed",
  },
  {
    icon: "/img/apps.png",
    title: "100+",
    description: "Custom Projects Completed",
  },
];

const whyus = [
  { title: "Advanced Development Approach" },
  { title: "User Oriented Ideology" },
  { title: "Better Performance Guarantee" },
  { title: "Post launch Support & Maintenance" },
  { title: "Flexible Engagement Model" },
  { title: "All in one - From Development to Hosting" },
  { title: "Assured Security Compliance" },
  { title: "Ready to Launch Solutions" },
];

const Services = () => {
  const { slug } = useParams();

  const [servicesPage, setservicesPage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [more, setMore] = useState(false);
  const scheduleMeetingModalRef = useRef(null);
  const handleClickScheduleMeeting = (e) => {
    // console.log(scheduleMeetingModalRef.current);
    scheduleMeetingModalRef.current.onClick(e);
  };
  const { setModal } = useContext(ModelContext);

  const fetchservicess = async (slug, params, headers) => {
    try {
      setLoading(true);
      const { data } = await getServicePages(slug, params, headers);
      if (data.status !== "success") throw new Error(data.message);
      if (data.data?.ServicePages === null) throw new Error("Page Not Found");

      setservicesPage(data?.data?.ServicePages);

      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };
  console.log(loading);
  useEffect(() => {
    fetchservicess(slug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  function stripHtmlTags(html) {
    // let con = html?.split(/\n/);

    // const anc = con?.map((paragraph) => {
    //     const tmp = document.createElement("p");
    //     tmp.innerHTML = `<p>${paragraph.split("\n")}</p>`;
    //     return tmp.textContent || tmp.innerText;
    // });
    // console.log(anc?.join("\n"));
    // return anc;

    const words = html?.split(" ");

    // Take the first 50 words
    const first50Words = words?.slice(0, 75);

    // Join the words back into a string
    const result = first50Words?.join(" ");

    // Add an ellipsis at the end
    const resultWithEllipsis = result + "...";

    return resultWithEllipsis;
  }

  const pageName = (type) => {
    if (type === "first") return servicesPage?.slug?.split("-")[0];
    if (type === "firstSec")
      return (
        servicesPage?.slug?.split("-")[0] +
        " " +
        servicesPage?.slug?.split("-")[1]
      );
    if (type === "firstLast")
      return servicesPage?.slug?.split("-")[2]
        ? servicesPage?.slug?.split("-")[0] +
            " " +
            servicesPage?.slug?.split("-")[2]
        : servicesPage?.slug?.split("-")[0] + " Development";

    if (type === "secLast")
      return servicesPage?.slug?.split("-")[1] &&
        servicesPage?.slug?.split("-")[2]
        ? servicesPage?.slug?.split("-")[1] +
            " " +
            servicesPage?.slug?.split("-")[2]
        : servicesPage?.slug?.split("-")[0] + " Development";

    if (type === "developer")
      return (
        servicesPage?.slug?.split("-")[0] +
        " " +
        servicesPage?.slug?.split("-")[1] +
        " Developer"
      );

    return servicesPage?.slug?.split("-").join(" ");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);
  return (
    <div className="">
      {loading === false ? (
        <div className="page services-page">
          <div className="bg-el page-header">
            <div className="page-section pt2 pb2">
              <div className="content d-flex p2 p0">
                <div className="details">
                  <Typography
                    className="page-heading mb2"
                    variant="h4"
                    component="h1"
                    fontWeight={600}
                    style={{ fontFamily: "Gilroy-bold" }}
                    sx={{ maxWidth: "382px" }}
                  >
                    {servicesPage?.heroHeading}
                  </Typography>
                  <Typography
                    className="page-description mb3 poppins-font"
                    variant="body3"
                    component="p"
                    color="text.light"
                    sx={{ lineHeight: "33px" }}
                  >
                    {stripHtmlTags(servicesPage.heroContant)}
                    {/* <Typography className="ml1" variant="body3" component="span" color="text.light">
                                            Read More...
                                        </Typography> */}
                  </Typography>
                  <AppButton1
                    text="Let's Talk"
                    // style={{ padding: "15px 40px", fontSize: "24px" }}
                    onClick={handleClickScheduleMeeting}
                  />
                  <PopupButton
                    ref={scheduleMeetingModalRef}
                    url="https://calendly.com/appretive"
                    rootElement={document.getElementById("root")}
                    styles={{ display: "none" }}
                    text=""
                  />
                </div>
                <div className="featured-image f-center">
                  <img
                    src={config?.apiUrl + servicesPage?.heroImage}
                    alt="hero"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="about-section">
            <div className="page-section">
              <Typography
                variant="h4"
                component="h3"
                className="mb5 t-center"
                fontWeight={600}
              >
                <span className="heading-highlighted clr-b">What</span>{" "}
                <span className="clr-pm">we do</span>
              </Typography>
              <div className="bg-image">
                <img src="/img/bg-2.png" alt="bg" />
              </div>
              <div className="content d-flex">
                <div className="image f-center">
                  <img
                    src={config?.apiUrl + servicesPage?.weDoImage}
                    alt="Android"
                  />
                </div>
                <div className="details">
                  <Typography
                    className="page-heading mb2"
                    variant="h45"
                    component="h2"
                    fontWeight={600}
                  >
                    {servicesPage?.weDoHeading}
                  </Typography>

                  <Typography
                    className={`page-description mb3 ${
                      !more && "ellipsis-6line"
                    }`}
                    variant="body1"
                    component="p"
                    color="text.light"
                    sx={{ lineHeight: "28px" }}
                  >
                    {servicesPage.weDoContant}
                  </Typography>
                  <AppButton1
                    text={`Read ${more ? "Less" : "More"}`}
                    onClick={() => setMore(!more)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="industry-section mt3">
            <div className="page-section pb1" style={{ paddingTop: "0" }}>
              <Typography
                variant="h4"
                component="h3"
                className="mb5 pb0-s t-center"
                fontWeight={600}
              >
                <span className="heading-highlighted clr-pm">Industries</span>{" "}
                We Serve
              </Typography>
              <div className="card-container">
                {industries?.map((ind, i) => (
                  <div key={i} className="card f-center flex-column">
                    <div className="card-image mb2">
                      <img src={ind?.icon} alt="" />
                    </div>
                    <div className="card-heading">
                      <h4
                        style={{
                          fontWeight: "500",
                          fontSize: "20px",
                          lineHeight: "25px",
                        }}
                      >
                        {ind?.heading}
                      </h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="expertise-section bg-m">
            <div className="page-section  pt3 pb3">
              <Typography
                className="t-center mb3"
                textTransform={"capitalize"}
                variant="h5"
                color="text.white"
                fontWeight={600}
              >
                Raising The bar For {pageName(slug)} Expertise
              </Typography>
              <div className="card-container">
                {expertise.map((ele) => (
                  <div key={ele?.title} className="card">
                    <div className="card-header">
                      <div className="card-image">
                        <img src={ele?.icon} alt={ele?.title} />
                      </div>
                    </div>
                    <div className="card-body">
                      <Typography
                        variant="h5"
                        fontWeight={600}
                        className="card-heading"
                      >
                        {ele?.title}
                      </Typography>
                      <Typography variant="h6" className="card-description">
                        {ele?.description}
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="our-offering  mt0-s">
            <div className="page-section">
              <Typography
                variant="h4"
                component="h3"
                className="mb5 pb0-s t-center"
                fontWeight={600}
              >
                <span className="heading-highlighted clr-b">What</span>
                <span className="clr-pm"> We Offer</span>
              </Typography>
              <div className="content d-flex p2 p0-s">
                <div className="details">
                  <Typography
                    className="page-heading mb2"
                    variant="h4"
                    component="h2"
                    fontWeight={600}
                  >
                    {servicesPage?.weOfferHeading}
                  </Typography>
                  <Typography
                    className="page-description mb3"
                    variant="body3"
                    component="p"
                    color="text.light"
                    sx={{ lineHeight: "28px" }}
                  >
                    {stripHtmlTags(servicesPage.weOfferContant)}
                  </Typography>
                  <AppButton1
                    text="Let's Talk"
                    // style={{ padding: "15px 40px", fontSize: "24px" }}
                    onClick={handleClickScheduleMeeting}
                  />
                  <PopupButton
                    ref={scheduleMeetingModalRef}
                    url="https://calendly.com/appretive"
                    rootElement={document.getElementById("root")}
                    styles={{ display: "none" }}
                    text=""
                  />
                </div>
                <div className="image f-center">
                  <img
                    src={config?.apiUrl + servicesPage?.weOfferImage}
                    alt="i"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="banner-section bg-l">
            <div className="page-section d-flex align-items-center gap-5 flex-wrap-m">
              <div className="left" style={{ width: "100%" }}>
                <Typography
                  variant="h4"
                  color="primary.main"
                  fontWeight={500}
                  sx={{ maxWidth: "650px" }}
                >
                  Want to build a bold view of the future with our
                  <span className="clr-pm" style={{ fontWeight: "600" }}>
                    {" "}
                    expert team?
                  </span>
                </Typography>
              </div>
              <div
                className="right f-center flex-column"
                style={{ width: "100%" }}
              >
                <Typography
                  className="t-center mb2"
                  variant="h6"
                  color="primary.main"
                  fontWeight={500}
                >
                  Quick Support
                </Typography>
                <button
                  className="btn btn-primary p1 f-center bg-m clr-w"
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    borderRadius: "var(--app-base-spacing-1)",
                    textTransform: "capitalize",
                  }}
                  onClick={handleClickScheduleMeeting}
                >
                  Hire {pageName("developer")}
                  <BsArrowRight
                    className="ml1"
                    style={{ fill: "var(--app-color-white)", fontSize: "24px" }}
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="whyus-section">
            <div className="page-section">
              {/* <div className="bg-blob">
                        <img src="/img/blob.svg" alt="bg" />
                    </div> */}
              <div className="bg-image">
                <img src="/img/why-us-bg.png" alt="bg" />
              </div>
              <div className="one">
                <Typography
                  variant="h4"
                  component="h3"
                  className="mb3 t-center"
                  fontWeight={600}
                >
                  <span className="heading-highlighted clr-b">Why </span>
                  <span className="clr-pm">Us</span>
                </Typography>
                <div className="content d-flex  justify-content-center pb4">
                  <div className="details">
                    <Typography
                      className="mb3"
                      variant="h45"
                      component="h2"
                      fontWeight={600}
                      textTransform={"capitalize"}
                    >
                      Why Choose {pageName("first")} for {pageName("secLast")}?
                    </Typography>
                    {whyus?.map((data, i) => (
                      <div className="d-flex" key={i}>
                        <span
                          className="pointer d-block mr2 clr-pm"
                          style={{
                            lineHeight: "36px",
                            fontWeight: "900",
                            fontSize: "25px",
                          }}
                        >
                          *
                        </span>
                        <Typography
                          className="page-description mb1"
                          variant="body1"
                          component="p"
                          color="text.light"
                          sx={{ lineHeight: "28px" }}
                        >
                          {data.title}
                        </Typography>
                      </div>
                    ))}
                  </div>
                  <div className="image f-center">
                    <img src="/img/why-us.png" alt="Android" />
                  </div>
                </div>
              </div>
              <div className="two">
                <div className="leftImage">
                  <img src="/img/whyappLeft.png" alt="" />
                </div>
                <div className="rightImage">
                  <img src="/img/whyappRight.png" alt="" />
                </div>
                <Typography
                  variant="h4"
                  component="h3"
                  className="mb3 t-center"
                  fontWeight={600}
                >
                  <span className="heading-highlighted clr-b">Why </span>{" "}
                  <span className="clr-pm">Appretive</span>
                </Typography>
                <Typography
                  className="mb2 t-center"
                  variant="h45"
                  component="h4"
                  fontWeight={600}
                  textTransform={"capitalize"}
                  sx={{ maxWidth: "600px", mx: "auto" }}
                  position={"relative"}
                  zIndex={10}
                >
                  Why Choose Appretive infosoft for {pageName(slug)} Services?
                </Typography>
                <div className="flex justify-center items-center">
                  <div
                    className="mx-auto flex flex-col"
                    style={{ position: "relative", zIndex: 10 }}
                  >
                    <div className="flex">
                      <span
                        className="pointer d-block mr2 clr-pm"
                        style={{
                          lineHeight: "36px",
                          fontWeight: "900",
                          fontSize: "25px",
                        }}
                      >
                        *
                      </span>
                      <Typography
                        className="page-description mb1"
                        variant="body1"
                        component="p"
                        color="text.light"
                        sx={{ lineHeight: "28px" }}
                        textTransform={"capitalize"}
                      >
                        We provide best technology for made {pageName(slug)}
                      </Typography>
                    </div>
                    <div className="d-flex">
                      <span
                        className="pointer d-block mr2 clr-pm"
                        style={{
                          lineHeight: "36px",
                          fontWeight: "900",
                          fontSize: "25px",
                        }}
                      >
                        *
                      </span>
                      <Typography
                        className="page-description mb1"
                        variant="body1"
                        component="p"
                        color="text.light"
                        sx={{ lineHeight: "28px" }}
                      >
                        We easily find your problem and get a quick solution for
                        your app
                      </Typography>
                    </div>
                    <div className="d-flex">
                      <span
                        className="pointer d-block mr2 clr-pm"
                        style={{
                          lineHeight: "36px",
                          fontWeight: "900",
                          fontSize: "25px",
                        }}
                      >
                        *
                      </span>
                      <Typography
                        className="page-description mb3"
                        variant="body1"
                        component="p"
                        color="text.light"
                        sx={{ lineHeight: "28px" }}
                      >
                        Fast return of your project within the timeline
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center my-4">
                  <AppButton1
                    className="mt1"
                    text="Get A Free Quote"
                    // style={{ padding: "15px 40px", fontSize: "24px" }}
                    onClick={() => setModal(true)}
                  />
                </div>
              </div>
            </div>
          </div>
          <ContactSection />
          {/* <div className="contactus-section  m0-s">
                <div className="page-section f-center flex-column">
                    <Typography variant="h4" component="h3" className="mb4 t-center" fontWeight={600}>
                        Let's Turn <span className="heading-highlighted clr-pm">Your Idea</span> into Reality
                    </Typography>
                    <Typography className="mb4 t-center" variant="h5" component="p" color="text.light">
                        Let's innovate for your technology needs with Appretive Infotech.
                    </Typography>
                    <AppButton1 text="Let's Talk" style={{ padding: "15px 40px", fontSize: "24px" }} />
                </div>
            </div> */}
        </div>
      ) : (
        <Page404 />
      )}
    </div>
  );
};

export default Services;
