import { useState } from "react";
import CountUp from "react-countup";

const achieved = [
  {
    Number: "500",
    desc: "Happy Clients",
  },
  {
    Number: "1000",
    desc: "Solutions Developed",
  },
  {
    Number: "25",
    desc: "Countries",
  },
  {
    Number: "50",
    desc: "Developers",
  },
];

const Achieved = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  return (
    <div className="flex flex-wrap max-w-[80%] mx-auto my-12">
      {achieved.map((data, index) => (
        <div
          key={index}
          className="relative w-[200px]  mx-auto text-center my-4 py-2"
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <h1 className="text-center text-[24px] lg:text-[2.7vw] font-semibold ">
            <CountUp end={data.Number} duration={6} />
            <p className="inline">+</p>
          </h1>
          <p className="text-[18px] my-2 font-poppins font-normal">
            {data.desc}
          </p>
          <div
            className={`border-[#3776FF] border-b-2 max-w-[60%]  mx-auto my-1 transition-all duration-300 ease-in-out ${
              hoveredIndex === index
                ? "transform rotate-180  translate-y-[-50px]"
                : ""
            }`}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default Achieved;
