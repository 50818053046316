import React, { useEffect, useRef, useState } from "react";
import { AppButton1, ReviewSlider } from "../../components";
import { PopupButton } from "react-calendly";
import "swiper/swiper-bundle.min.css";
import "swiper/css";
import "swiper/css/pagination";
import { Typography } from "@mui/material";

import "../../assets/css/mobileAppDev.css";
import { getReviews } from "../../apis";

const data = [
  {
    title: "Integration Providers",
    description:
      "Our seasoned professionals handle the UI design, front-end development, node deployment, third-party integrations, and interoperability difficulties by utilizing their practical knowledge in developing Blockchain Technology applications.",
    image: "/img/Integration-Services.svg",
  },
  {
    title: "Technology Guidance",
    description:
      "With custom blockchain application development designed to solve business problems with the ideal combination of technology, tools, platforms, infrastructure, and tech talent, we assist clients in realizing the promise of blockchain apps.",
    image: "/img/Technology-Advisory.svg",
  },
  {
    title: "Advice and guidance",
    description:
      "Our team of specialists recommends the best solution that satisfies the business's ultimate goals following a detailed review of the business processes, comprehension of the complexities and effectiveness of blockchain applicability, and assessment of the deployment strategy's business ramifications.",
    image: "/img/Consulting-Services.svg",
  },
];

const sections = [
  {
    title: "What is Blockchain?",
    content: `Blockchain is a distributed and decentralized digital ledger technology that maintains data security, immutability, and transparency by recording transactions across several computers. All transactions are compiled into blocks, which are then connected chronologically to create a continuous, unchangeable record. With this structure, there is no need for a central authority because transactions are validated and verified by the network using consensus procedures. A high degree of trust and security is provided by the use of cryptographic procedures, which guarantee that once information is recorded, it cannot be changed or tampered with. The foundation of cryptocurrencies like Bitcoin is blockchain technology, but its uses go well beyond virtual money and have an impact on a number of sectors, including supply chains, banking, healthcare, and more by enabling secure and transparent record-keeping and transactions.`,
  },
  {
    title: "How Does the Initiative for Blockchain Technology Come To Succeed?",
    content: `Major companies are poised to lead the way in the innovative use of technology due to the benefits that decentralized applications give to the industry as a whole and their high usability. Our team of experts adds and links blocks, or nodes organized chunks of a record's data with one another through a cryptographic validation to form an uninterrupted chain that is arranged in a decentralized structure, which in turn makes the transactions robust, secure, and expedient. This helps businesses push forward with integrating blockchain technology into their core business processes. Only the world's leading blockchain development firms can match our services.`,
  },
  {
    title: "Your trusted partner for Blockchain Software Development",
    content: `Our skilled blockchain programmers create software that is compatible and thoroughly tested by quality assurance to precisely and securely meet your company demands. We operate proficiently on all blockchain platforms. We can help our clients become more familiar with blockchain technology by providing them with up-to-date information and in-depth understanding of the field. And make good use of it to address the upcoming issues and the popular technical business viewpoints. We carefully examine the needs of the customer and apply our knowledge to create blockchain applications tailored to their industry that will help you reach the next level of safe company operations.`,
  },
];

const technologies = [
  {
    title: "Solidity",
    img: "/img/Solidity.webp",
  },
  {
    title: "Go lang",
    img: "/img/golang_logo_icon.webp",
  },
  {
    title: "Javascript",
    img: "/img/JavaScript-logo.webp",
  },
  {
    title: "Python",
    img: "/img/Python-service.png",
  },
  {
    title: "Rust",
    img: "/img/Rust_programming_language_black_logo.webp",
  },
  {
    title: "C++",
    img: "/img/c-logo.png",
  },
];

const BlockChain = () => {
  const [reviewsList, setReviewsList] = useState([]);

  const scheduleMeetingModalRef = useRef(null);
  const handleClickScheduleMeeting = (e) => {
    scheduleMeetingModalRef.current.onClick(e);
  };
  const fetchReviews = async (params, headers) => {
    try {
      const { data } = await getReviews(params, headers);
      if (data.status !== "success") throw new Error(data.message);

      setReviewsList(data?.data?.Review);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="mt-16">
        <div className="flex flex-wrap-reverse items-center justify-evenly sm:mx-[15px] md:mx-[20px] lg:mx-[50px] px-4 lg:my-10 my-6 pt-12 bg-white">
          <div className="w-full  lg:max-w-[50%]">
            <h1 className=" text-[30px]  md:text-[2.5vw] font-bold font-poppins  text-start mb-4 my-5">
              Blockchain Development <br /> Company
            </h1>
            <p className="text-justify font-poppins font-[400] text-[18px] mb-6">
              Our blockchain development service offers cutting-edge, safe
              solutions that revolutionize the way your company runs. Our
              expertise lies in developing bespoke blockchain networks, smart
              contracts, and decentralized software applications (dApps) that
              meet the specific needs you have. Our use of cutting-edge
              blockchain technology guarantees improved efficiency, security,
              and transparency throughout all of your operations. Our blockchain
              development service has the know-how and backing to realize your
              ideas, whether they include enhancing supply chain management,
              putting safe payment methods in place, or producing tokenized
              assets.
            </p>

            <div className="f-start">
              <AppButton1
                variant="h6"
                style={{ fontFamily: "Gilroy-bold" }}
                text={"Let's Talk"}
                onClick={handleClickScheduleMeeting}
              />
              <PopupButton
                ref={scheduleMeetingModalRef}
                url="https://calendly.com/appretive"
                rootElement={document.getElementById("root")}
                styles={{ display: "none" }}
                text=""
              />
            </div>
          </div>
          <div className="relative">
            <img
              src="/img/blockchain.png"
              alt="blockchain services"
              className="w-full max-w-md mx-auto"
            />
          </div>
        </div>

        <div className="my-12 max-w-[75%] mx-auto">
          <h1 className="text-[30px] text-center  md:text-[2.5vw] font-bold font-poppins  my-6">
            BlockChain Development Tech
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 mx-auto place-content-center">
            {technologies.map((tech, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center gap-2 p-4 bg-white sm:w-full border-[1px] md:max-w-[250px] rounded-lg shadow-lg  transform transition-transform duration-300 hover:scale-105 hover:shadow-xl group"
              >
                <div className="mb-1">
                  <img className="h-10 w-10" src={tech.img} alt="img" />
                </div>
                <div className="mb-2">
                  <h2 className="text-xl font-semibold font-poppins ">
                    {tech.title}
                  </h2>
                  <div className="hidden group-hover:block transition-transform duration-300 ease-in-out border-b-2 border-blue-500"></div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full">
          {sections.map((section, index) => (
            <div className="text-center mx-auto max-w-[65%]" key={index}>
              <h1 className="text-[30px] lg:text-[2.5vw] font-bold font-poppins mb-4">
                {section.title}
              </h1>
              <p
                className="text-[18px]  font-poppins text-justify px-1 my-6 lg:my-10 mx-auto font-[400]
               md:text-center leading-9"
              >
                {section.content}
              </p>
            </div>
          ))}
        </div>

        <div className="text-center mx-auto max-w-[85%]  lg:max-w-[65%]">
          <h1 className="text-[30px] lg:text-[2.5vw] font-poppins font-bold mb-4">
            Primary Benefits Provided by Our Blockchain App Development Services
          </h1>

          <div>
            <ul className="list-none space-y-4 text-left mb-8 text-[18px]  font-poppins font-[400] w-[95%]">
              <li className="flex items-start ">
                <span className="inline-block mt-[6px] mr-3 w-5 h-4 ">
                  <img
                    className="min-w-5 min-h-4"
                    src="/img/checkmark.png"
                    alt="img"
                  />
                </span>
                <span className="">
                  The unchangeability of records improves security and fosters
                  confidence in your ability to speak effectively and
                  methodically about your firm. Blockchain will fortify the
                  future of your company and make it more technologically
                  sophisticated and dominant.
                </span>
              </li>
              <li className="flex items-start ">
                <span className="inline-block mt-[6px] mr-3 w-5 h-4 ">
                  <img
                    className="min-w-5 min-h-4"
                    src="/img/checkmark.png"
                    alt="img"
                  />
                </span>
                <span className="">
                  Increased accessibility and an open ledger preserve the
                  accuracy and caliber of the data. As a result, more data will
                  be available for processing, which will simplify the ecosystem
                  when data and processes are automated based on particular
                  circumstances.
                </span>
              </li>
              <li className="flex items-start ">
                <span className="inline-block mt-[6px] mr-3 w-5 h-4 ">
                  <img
                    className="min-w-5 min-h-4"
                    src="/img/checkmark.png"
                    alt="img"
                  />
                </span>
                <span className="">
                  Money transfers made around-the-clock reduce transaction times
                  by 50%. As a result, there won't be as many lengthy lines for
                  deposits and currency exchange. The transaction cost will be
                  reduced by expedited transactions and the lack of third-party
                  authentication.
                </span>
              </li>
              <li className="flex items-start ">
                <span className="inline-block mt-[6px] mr-3 w-5 h-4 ">
                  <img
                    className="min-w-5 min-h-4"
                    src="/img/checkmark.png"
                    alt="img"
                  />
                </span>
                <span className="">
                  Promote distributed transaction visibility throughout the
                  business network to preserve transparency. In the long run,
                  this will strengthen business relationships and raise the
                  level of politeness between the business partners.
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className=" flex gap-2 flex-wrap justify-around  items-center text-center mt-10 mb-12 max-w-[70%] mx-auto p-7 border-[15px] border-blue-500 rounded-xl shadow-md round">
          <p className="text-[22px] font-bold font-poppins">
            Request a free quote in less than a minute
          </p>
          <div className="f-start">
            <AppButton1
              variant="h6"
              style={{ fontFamily: "Gilroy-bold" }}
              text={"Let's Talk"}
              onClick={handleClickScheduleMeeting}
            />
            <PopupButton
              ref={scheduleMeetingModalRef}
              url="https://calendly.com/appretive"
              rootElement={document.getElementById("root")}
              styles={{ display: "none" }}
              text=""
            />
          </div>
        </div>

        <div className="whychoose">
          <div className="mx-auto text-center max-w-[80%]">
            <h1 className="text-[30px] text-justify md:text-center lg:text-[2.5vw] font-bold font-poppins mb-4">
              How can you join the blockchain train with the support of
              Appretive Infosoft?
            </h1>
          </div>
          <div className="my-10 max-w-[75%] mx-auto">
            <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mx-auto place-content-center">
              {data.map((service, index) => (
                <div
                  key={index}
                  className="p-5 flex flex-col justify-center items-center text-center bg-white sm:w-full border-[1px] md:max-w-[400px] rounded-lg shadow-lg  transform transition-transform ease-in-out duration-300 hover:scale-105 hover:shadow-xl group"
                >
                  <div className="mb-[15px]">
                    <img
                      src={service.image}
                      className="h-[100px] w-[100px]"
                      alt="img"
                    />
                  </div>
                  <div className="my-4">
                    <h2 className="text-[24px]  font-semibold  capitalize">
                      {service.title}
                    </h2>
                    <div className="hidden transition-transform duration-2000 ease-in-out border-b-2 border-blue-500 group-hover:block "></div>
                  </div>
                  <p className="capitalize font-poppins font-[500] my-3 text-[16px] text-center">
                    {service.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="clientReview my-10  mb-[-100px] lg:mb-[100px]">
          <div className="mx-auto text-center">
            <h1 className="text-[26px] text-center lg:text-[2.5vw] font-bold font-poppins mb-4 capitalize">
              Some of our happy clients
            </h1>
            <p className=" px-5 text-[19px] font-[400] font-poppins text-center">
              Our clients' reviews demonstrate how satisfied they are with our
              services
            </p>
          </div>
          <div className="contact-review mb-[50px] md:mb-[70px] lg:mb-[130px]">
            <div className="page-section contact-review-flex sm:flex flex-wrap justify-evenly">
              <div className={"contact-section"}>
                <div className={`page-section`}>
                  <Typography
                    className="mb3 t-start clr-w font-bold"
                    variant="h4"
                    component="h3"
                    fontWeight={900}
                    style={{ fontFamily: "Gilroy-bold" }}
                  >
                    Let’s Turn
                    <span className="clr-pm">Your Ideas</span> into Reality
                  </Typography>
                  <Typography
                    className="mb3 t-start clr-w"
                    variant="h6"
                    component="p"
                    style={{ fontFamily: "Gilroy-bold" }}
                  >
                    Let's innovate for your technology needs with Appretive
                    Infotech.
                  </Typography>
                  <div className="f-start">
                    <AppButton1
                      variant="h6"
                      style={{ fontFamily: "Gilroy-bold" }}
                      text={"Let's Talk"}
                      onClick={handleClickScheduleMeeting}
                    />
                    <PopupButton
                      ref={scheduleMeetingModalRef}
                      url="https://calendly.com/appretive"
                      rootElement={document.getElementById("root")}
                      styles={{ display: "none" }}
                      text=""
                    />
                  </div>
                </div>
              </div>
              <div className="review-section review-section lg:mb-[-180px]">
                <div
                  className="page-section"
                  style={{ paddingTop: 0, paddingLeft: 0 }}
                >
                  <div className="bg-w shadow review-box w-[95%]">
                    {/* <h2
                                        className="mb2 t-center"
                                        style={{ fontSize: "36px", fontWeight: "600", padding: "20px" }}
                                    > */}
                    <Typography
                      className="mb1 mt1 t-center clr-w"
                      variant="h4"
                      component="h3"
                      fontWeight={600}
                      style={{ fontFamily: "Gilroy-bold" }}
                    >
                      <span className="heading-highlighted clr-b">
                        {" "}
                        What’s Our <span className="clr-pm">Client</span> Say
                      </span>{" "}
                    </Typography>
                    <div className="slider f-center">
                      <ReviewSlider data={reviewsList} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PopupButton
            ref={scheduleMeetingModalRef}
            url="https://calendly.com/appretive"
            rootElement={document.getElementById("root")}
            styles={{ display: "none" }}
            text=""
          />
        </div>
      </div>
    </>
  );
};

export default BlockChain;
