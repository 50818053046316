import React, { useEffect, useRef, useState } from "react";
import { AppButton1, ReviewSlider } from "../../components";
import { PopupButton } from "react-calendly";
import "swiper/swiper-bundle.min.css";
import "swiper/css";
import "swiper/css/pagination";
import { Typography } from "@mui/material";

import "../../assets/css/mobileAppDev.css";
import { getReviews } from "../../apis";

const data = [
  {
    title: "Services for Custom app development",
    description:
      "Create unique solutions with our application development team to help you thrive in the digital market. Create, plan, develop, and manage unique apps that run on any platform, in the cloud, or in hybrid environments.",
    image: "/img/Custom-Application-Development-Services.svg",
  },
  {
    title: "Modify According To Your Budget",
    description:
      "Being conscious of the importance of cost and having value, they can offer services that optimize productivity without requiring you to sign long-term contracts. Our long-term solutions, created with a committed team, are meant to advance your company.",
    image: "/img/Adapt-To-Your-Budget.svg",
  },
  {
    title: "Reliable Services and Solutions",
    description:
      "Never think that one size fits all. With our industry-leading knowledge of precise sectors, we will collaborate with you to develop a strategy that meets your unique company needs and allows you to grow up or down as needed.",
    image: "/img/Flexible-Solutions-and-Services.svg",
  },
];

const sections = [
  {
    title: "Using SAP technology for moving digital innovation",
    content: `In the age of digital technology, it is critical for enterprises to apply standardized solutions. In a circumstance like this, a SAP consulting business can help you rise to the occasion and transform your business using cutting edge technologies. With the help of software, Appretive Infosoft can handle your company's evolving needs and enable you to provide the kind of lifetime customer experience that businesses desperately need. With a wealth of SAP consulting services expertise, we can help you implement, enhance, and transform your applications to create a winning business.`,
  },
  {
    title:
      "Develop a solid basis for your IT system, and use it to position yourself for development.",
    content: `The plan is to expand on easily accessible IT infrastructure by implementing solutions that are customized to meet your needs. Our advisors concentrate on producing measurable outcomes for you. Our ability to work in a variety of industries helps us provide detailed industry solutions based on track records of success. We support businesses in implementing and managing applications using the essential SAP Solution Manager platforms. Organizations can benefit from revolutionary functionality that is simpler to use by integrating it into their current SAP enterprise solutions with the help of our team of skilled SAP consultants.`,
  },
  {
    title: "Improve your business transformation by hiring a SAP consultant.",
    content: `Our customers receive everything from a single source, starting with the strategy and industry-specific execution of our skilled SAP technology consultants and continuing with steady operation with Appretive Infosoft, the leading general full-service partner for end-to-end incorporation. Because of their expertise, you may be confident that current and future requirements will be executed as best they can in challenging application contexts. Our skilled SAP business workflow consultants ensure expert guidance and assistance grounded on the most recent advancements in platforms and technology.`,
  },
  {
    title:
      "Exceptionally cooperative method to produce optimal business outcomes",
    content: `Being a proficient SAP consulting firm, we emphasize a highly cooperative approach in our work. We consistently employ best practices to manage crucial technologies that, when combined with efficient big data service utilization, improve corporate performance. This strategy has been repeatedly shown to increase the client's return on investment. Our believe that successful projects start with senior consultants who gain in-depth knowledge of the sector is the direct cause of our 100% customer success rate. Reduced consultant-to-client ratios have an impact on our developments, which leads to improved information sharing, increased client autonomy, and higher levels of customer satisfaction. Our senior-level consultants can successfully guide a team through the project because they possess the ideal blend of technical expertise, business management, management, and project knowledge.`,
  },
];

const Technologies = [
  {
    title: "ABAP",
    img: "/img/ABAPSaplogo.jpeg",
  },
  {
    title: "Groovy",
    img: "/img/groovylogo.png",
  },
  {
    title: "SAP Hana",
    // img: "/img/saphanalogoimage.png",
    img: "/img/saphana.jpg",
  },
  {
    title: "SQLScript",
    img: "/img/sqlscriptlogo.png",
  },
  {
    title: "Nodejs",
    img: "/img/node-service.png",
  },
  {
    title: "Java",
    img: "/img/java-service.png",
  },
  {
    title: "SQL",
    img: "/img/Mysql-service.png",
  },
  {
    title: "Python",
    img: "/img/Python-service.png",
  },
];

const SAPservice = () => {
  const [reviewsList, setReviewsList] = useState([]);

  const scheduleMeetingModalRef = useRef(null);
  const handleClickScheduleMeeting = (e) => {
    scheduleMeetingModalRef.current.onClick(e);
  };
  const fetchReviews = async (params, headers) => {
    try {
      const { data } = await getReviews(params, headers);
      if (data.status !== "success") throw new Error(data.message);

      setReviewsList(data?.data?.Review);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="mt-16">
        <div className="flex flex-wrap-reverse items-center justify-evenly sm:mx-[15px] md:mx-[20px] lg:mx-[50px] px-4 lg:my-10 my-6 pt-12 bg-white">
          <div className="w-full  lg:max-w-[50%]">
            <h1 className=" text-[30px]  md:text-[2.5vw] font-bold font-poppins  text-start mb-4 my-5">
              SAP Consulting Services
            </h1>
            <p className="text-justify  font-poppins font-[400] text-[18px] mb-6">
              Using SAP's cutting-edge software suite, our SAP consulting
              services provide complete solutions to assist businesses in
              streamlining their operations and achieving their strategic
              objectives. Our team of skilled SAP consultants works directly
              with customers to comprehend their particular requirements and
              difficulties before offering specialized integration,
              modification, and implementation services. Our expertise spans
              across multiple SAP modules, such as SAP S/4HANA, SAP ERP, SAP
              SCM, and SAP CRM, guaranteeing optimal efficiency and streamlining
              of all your business's activities. Our SAP consulting services
              guarantee smooth transitions and little disturbance to your
              organization, starting with early planning and system design and
              continuing with deployment and support. We help you increase
              operational effectiveness, boost data visibility, and spur
              innovation by utilizing SAP's strength, allowing your company to
              remain competitive in the rapidly evolving market landscape.
            </p>

            <div className="f-start">
              <AppButton1
                variant="h6"
                style={{ fontFamily: "Gilroy-bold" }}
                text={"Let's Talk"}
                onClick={handleClickScheduleMeeting}
              />
              <PopupButton
                ref={scheduleMeetingModalRef}
                url="https://calendly.com/appretive"
                rootElement={document.getElementById("root")}
                styles={{ display: "none" }}
                text=""
              />
            </div>
          </div>
          <div className="relative">
            <img
              src="/img/SAP.png"
              alt="Sap services"
              className="w-full max-w-md mx-auto"
            />
          </div>
        </div>

        <div className="my-12 max-w-[75%] mx-auto">
          <h1 className="text-[30px] text-center  md:text-[2.5vw] font-bold font-poppins  my-6">
            SAP Development Tech
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 mx-auto place-content-center">
            {Technologies.map((tech, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center gap-2 p-4 bg-white sm:w-full border-[1px] md:max-w-[250px] rounded-lg shadow-lg  transform transition-transform duration-300 hover:scale-105 hover:shadow-xl group"
              >
                <div className="mb-1">
                  <img className="h-10 w-10" src={tech.img} alt="img" />
                </div>
                <div className="mb-2">
                  <h2 className="text-xl font-semibold font-poppins ">
                    {tech.title}
                  </h2>
                  <div className="hidden group-hover:block transition-transform duration-300 ease-in-out border-b-2 border-blue-500"></div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full">
          {sections.map((section, index) => (
            <div className="text-center mx-auto max-w-[70%]" key={index}>
              <h1 className="text-[30px] lg:text-[2.5vw] font-bold font-poppins mb-4">
                {section.title}
              </h1>
              <p
                className="text-[18px]  font-poppins text-justify px-1 my-6 lg:my-10 mx-auto font-[400]
               md:text-center leading-9"
              >
                {section.content}
              </p>
            </div>
          ))}
        </div>

        <div className=" flex gap-2 flex-wrap justify-around  items-center text-center mt-10 mb-12 max-w-[70%] mx-auto p-7 border-[15px] border-blue-500 rounded-xl shadow-md round">
          <p className="text-[22px] font-bold font-poppins">
            Request a free quote in less than a minute
          </p>
          <div className="f-start">
            <AppButton1
              variant="h6"
              style={{ fontFamily: "Gilroy-bold" }}
              text={"Let's Talk"}
              onClick={handleClickScheduleMeeting}
            />
            <PopupButton
              ref={scheduleMeetingModalRef}
              url="https://calendly.com/appretive"
              rootElement={document.getElementById("root")}
              styles={{ display: "none" }}
              text=""
            />
          </div>
        </div>

        <div className="whychoose">
          <div className="mx-auto text-center max-w-[75%]">
            <h1 className="text-[30px] text-justify md:text-center lg:text-[2.5vw] font-bold font-poppins mb-4">
              How can you join the blockchain train with the support of
              Appretive Infosoft?
            </h1>
          </div>
          <div className="my-10 max-w-[75%] mx-auto">
            <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mx-auto place-content-center">
              {data.map((service, index) => (
                <div
                  key={index}
                  className="p-5 flex flex-col justify-center items-center text-center bg-white sm:w-full border-[1px] md:max-w-[400px] rounded-lg shadow-lg  transform transition-transform ease-in-out duration-300 hover:scale-105 hover:shadow-xl group"
                >
                  <div className="mb-[15px]">
                    <img
                      src={service.image}
                      className="h-[100px] w-[100px]"
                      alt="img"
                    />
                  </div>
                  <div className="my-4">
                    <h2 className="text-[24px]  font-semibold  capitalize">
                      {service.title}
                    </h2>
                    <div className="hidden transition-transform duration-2000 ease-in-out border-b-2 border-blue-500 group-hover:block "></div>
                  </div>
                  <p className="capitalize font-poppins font-[500] my-3 text-[16px] text-center">
                    {service.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="clientReview my-10 mb-[-100px] lg:mb-[100px]">
          <div className="mx-auto text-center">
            <h1 className="text-[26px] text-center lg:text-[2.5vw] font-bold font-poppins mb-4 capitalize">
              Some of our happy clients
            </h1>
            <p className=" px-5 text-[19px] font-[400] font-poppins text-center">
              Our clients' reviews demonstrate how satisfied they are with our
              services
            </p>
          </div>
          <div className="contact-review mb-[50px] md:mb-[70px] lg:mb-[130px]">
            <div className="page-section contact-review-flex sm:flex flex-wrap justify-evenly">
              <div className={"contact-section"}>
                <div className={`page-section`}>
                  <Typography
                    className="mb3 t-start clr-w font-bold"
                    variant="h4"
                    component="h3"
                    fontWeight={900}
                    style={{ fontFamily: "Gilroy-bold" }}
                  >
                    Let’s Turn
                    <span className="clr-pm">Your Ideas</span> into Reality
                  </Typography>
                  <Typography
                    className="mb3 t-start clr-w"
                    variant="h6"
                    component="p"
                    style={{ fontFamily: "Gilroy-bold" }}
                  >
                    Let's innovate for your technology needs with Appretive
                    Infotech.
                  </Typography>
                  <div className="f-start">
                    <AppButton1
                      variant="h6"
                      style={{ fontFamily: "Gilroy-bold" }}
                      text={"Let's Talk"}
                      onClick={handleClickScheduleMeeting}
                    />
                    <PopupButton
                      ref={scheduleMeetingModalRef}
                      url="https://calendly.com/appretive"
                      rootElement={document.getElementById("root")}
                      styles={{ display: "none" }}
                      text=""
                    />
                  </div>
                </div>
              </div>
              <div className="review-section review-section lg:mb-[-180px]">
                <div
                  className="page-section"
                  style={{ paddingTop: 0, paddingLeft: 0 }}
                >
                  <div className="bg-w shadow review-box w-[95%]">
                    {/* <h2
                                        className="mb2 t-center"
                                        style={{ fontSize: "36px", fontWeight: "600", padding: "20px" }}
                                    > */}
                    <Typography
                      className="mb1 mt1 t-center clr-w"
                      variant="h4"
                      component="h3"
                      fontWeight={600}
                      style={{ fontFamily: "Gilroy-bold" }}
                    >
                      <span className="heading-highlighted clr-b">
                        {" "}
                        What’s Our <span className="clr-pm">Client</span> Say
                      </span>{" "}
                    </Typography>
                    <div className="slider f-center">
                      <ReviewSlider data={reviewsList} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PopupButton
            ref={scheduleMeetingModalRef}
            url="https://calendly.com/appretive"
            rootElement={document.getElementById("root")}
            styles={{ display: "none" }}
            text=""
          />
        </div>
      </div>
    </>
  );
};

export default SAPservice;
