import React from "react";
import Menu from "./Menu";

import "../../assets/css/menu.css";
import AppButton1 from "../AppButton1/AppButton1";
import config from "../../config/config";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

const OffScreen = ({ open, setOpen }) => {
    return (
        <div className={`offscreen-menu ${open ? "active" : ""}`}>
            <div className="d-flex align-items-center">
                <div className="bg-w w-full max-w-80" style={{ height: "100vh" }}>
                    <div className="close-menu p2 bg-w" style={{ borderBottom: "1px solid var(--app-text-light)" }}>
                        <Link to="/">
                            <div className="" onClick={() => setOpen(false)}>
                                <img src="/img/logo.png" className="w-40 sm:w-50" alt="logo" />
                            </div>
                        </Link>
                    </div>
                    <Menu setOpen={setOpen} />
                    <div className="bg-w pb2 pl2 mt1">
                        <a href={`tel:${config.contact.mobile}`} className="">
                            <AppButton1 text={`Call ${config.contact.mobile}`} />
                        </a>
                    </div>
                </div>
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        padding: "0.8rem",
                        cursor: "pointer",
                    }}
                    onClick={() => setOpen(false)}
                >
                    <FaTimes fontSize="2rem" className="fill-[var(--app-primary-dark)]" />
                </div>
            </div>
        </div>
    );
};

export default OffScreen;
