import { Typography } from "@mui/material";

import "../../assets/css/catestudy.css";
import { useEffect, useState } from "react";
import { getCaseStudy } from "../../apis";
import config from "../../config/config";
import { Link } from "react-router-dom";
import { AppButton1 } from "../../components";

// const caseStudys = [
//     {
//         _id: 0,
//         title: "The Key to E-commerce Success",
//         featuredImage: "/img/caseStudy.png",
//         description: "Let’s see the example, which works for both horizontal and vertical borders. Example of increasing the space between the dots of dotted borders: The file given below contains CSS property. This file saves with .css extension.",
//         categories: [
//             { _id: 0, title: "Latest" },
//             { _id: 1, title: "Engineering" },
//             { _id: 2, title: "Business" },
//             { _id: 3, title: "Design" },
//             { _id: 4, title: "Company News" },
//         ],
//     },
//     {
//         _id: 1,
//         title: "The Key to E-commerce Success",
//         featuredImage: "/img/caseStudy.png",
//         description: "Let’s see the example, which works for both horizontal and vertical borders. Example of increasing the space between the dots of dotted borders: The file given below contains CSS property. This file saves with .css extension.",
//         categories: [
//             { _id: 0, title: "Latest" },
//             { _id: 1, title: "Engineering" },
//             { _id: 2, title: "Business" },
//             { _id: 3, title: "Design" },
//             { _id: 4, title: "Company News" },
//         ],
//     },
// ];

const CaseStudyList = () => {
    const [page, setPage] = useState({ page: 1, perPage: 50, totalPages: 1, loading: true });
    const [caseStudyList, setcaseStudyList] = useState({ caseStudyList: [] });

    const fetchcaseStudys = async (params, headers) => {
        try {
            const { data } = await getCaseStudy(params, headers);
            if (data.status !== "success") throw new Error(data.message);

            setcaseStudyList((prev) => ({ caseStudyList: [...prev.caseStudyList, ...data?.data.case] }));
            setPage((prev) => ({ ...prev, totalPages: data.data.totalPage }));
            setPage((prev) => ({ ...prev, loading: false }));
        } catch (err) {
            console.log(err);
        }
    };

    function stripHtmlTags(html) {
        const words = html?.split(" ");

        const first50Words = words?.slice(0, 50);

        const result = first50Words?.join(" ");

        const resultWithEllipsis = result + "...";

        return resultWithEllipsis;
    }

    useEffect(() => {
        fetchcaseStudys({ page: page.page });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page.page]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="casestudy-page" style={{ marginTop: "130px" }}>
            <div className="page-section pt0">
                <Typography variant="h4" component="h2" className="mb5" fontWeight={600}>
                    Top Mobile App Development Case Study
                </Typography>
                {!page.loading && caseStudyList?.caseStudyList?.length > 0 ? (
                    <div className="">
                        <div className="card-container">
                            {!page.loading &&
                                caseStudyList?.caseStudyList?.map((caseStudy) => (
                                    <div className="card" key={caseStudy._id}>
                                        <div className="card-head">
                                            <Link
                                                to={`${caseStudy?.name?.toLowerCase()?.split(" ").join("-")}`}
                                                state={caseStudy}
                                            >
                                                <div className="card-image">
                                                    <img
                                                        src={config.apiUrl + caseStudy.heroImage}
                                                        alt={caseStudy.name}
                                                    />
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="card-body">
                                            <Typography variant="h5" component="h3" fontWeight={600}>
                                                {caseStudy?.name}
                                            </Typography>
                                            <Typography
                                                variant="body3"
                                                component="p"
                                                color="text.light"
                                                lineHeight="40px"
                                            >
                                                {stripHtmlTags(caseStudy?.desc)}
                                            </Typography>
                                            {/* <div className="card-footer">
                                            {caseStudy?.techno?.map((tech) => (
                                                <div key={tech.slug} className="tech-img">
                                                    <img src={tech.logo} alt={tech.name} />
                                                </div>
                                            ))}
                                        </div> */}

                                            <div className="card-footer">
                                                <Link
                                                    to={`${caseStudy?.name?.toLowerCase()?.split(" ").join("-")}`}
                                                    state={caseStudy}
                                                >
                                                    <AppButton1 text={"Get Details"} />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>

                        {page.totalPages > page.page && (
                            <div className="f-center mt4">
                                <AppButton1
                                    text="Load More..."
                                    onClick={() => setPage({ ...page, page: page.page + 1 })}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <div style={{ maxWidth: "40rem", margin: "auto" }}>
                        <img src="/img/Empty.gif" alt="empty" className="w-100" />
                        {/* <div>Sorry, No Result Found!</div> */}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CaseStudyList;
