import { useContext } from "react";
import { AppButton1 } from "../../../components";
import ModelContext from "../../../context/contactModelContext";

const Components = ({ activeTab }) => {
    const { setModal } = useContext(ModelContext);

    const components = [
        {
            name: "Fintech",
            description:
                "These solutions aim to improve the efficiency, convenience, and accessibility of financial services for customers. We focus on developing innovative approaches to assist the FinTech Industry in exploring and growing in the new digital era.",
            list: ["Digital Banking", "Payment", "Trading and Cryptocurrency"],
            img: ["/img/industries/4.png", "/img/industries/3.png", "/img/industries/2.png", "/img/industries/1.png"],
        },
        {
            name: "E-commerce",
            description:
                "We create products for the E-Commerce Industry. Our goal is to assist businesses in growing by allowing them to reach a larger audience, increase their online revenue, and maintain a positive online experience.",
            list: ["Multi-vendor Retail App", "Sales Tracker", "Customized E-Commerce solutions"],
            img: ["/img/industries/5.png", "/img/industries/6.png", "/img/industries/7.png", "/img/industries/8.png"],
        },
        {
            name: "Education",
            description:
                "We specialize in IT solutions for educational institutions, colleges, and non-profit organizations. an innovative website and application development firm that provides an engaging and dynamic learning environment. Our developers are capable of providing excellent educational technology solutions.",
            list: ["E-learning Platform", "School and Collage Management", "Online Examinations"],
            img: [
                "/img/industries/9.png",
                "/img/industries/10.png",
                "/img/industries/11.png",
                "/img/industries/12.png",
            ],
        },
        {
            name: "Healthcare",
            description:
                "We offer a variety of IT solutions for the Healthcare industry. This allows businesses to run more efficiently and provide a better experience for their customers. They can be used in a variety of combinations and configurations to meet the requirements of various healthcare businesses.",
            list: ["Hospital Management", "Online Consulting", "Medical Apps"],
            img: [
                "/img/industries/13.png",
                "/img/industries/14.png",
                "/img/industries/15.png",
                "/img/industries/16.png",
            ],
        },
        {
            name: "Hospitality",
            description:
                "We provide a wide range of IT solutions for the Hospitality industry. That helps businesses manage their operations more efficiently and provide a better experience for their customers. They can be used in various combinations and configurations to suit the needs of different hospitality businesses.",
            list: ["Restaurants & Hotel Management", "Online Food Delivery", "POS System"],
            img: [
                "/img/industries/17.png",
                "/img/industries/18.png",
                "/img/industries/19.png",
                "/img/industries/20.png",
            ],
        },
        {
            name: "Real Estate",
            description:
                "Real estate firms have the most complex information technology systems in the world. We create IT solutions that assist real estate businesses in lowering costs, increasing productivity, and managing their operations more effectively.",
            list: ["Quick Property Listing", "Search Module", "Buy and Sell and Rent Platform"],
            img: [
                "/img/industries/21.png",
                "/img/industries/22.png",
                "/img/industries/23.png",
                "/img/industries/24.png",
            ],
        },
        {
            name: "Manufacturing",
            description:
                "Our business applications and websites are ideal for companies looking to enter the manufacturing industry. Our experts will design a website or application that is tailored to your specific needs. To help you, we have a number of techniques and technologies at our disposal.",
            list: ["Billing Software", "Stock Management", "ERP Solutions"],
            img: [
                "/img/industries/25.png",
                "/img/industries/26.png",
                "/img/industries/27.png",
                "/img/industries/28.png",
            ],
        },
        {
            name: "Entertainment",
            description:
                "Our solutions are designed to provide a new level of entertainment to your audience. We create high-quality interactive, engaging, and entertaining apps. Our team consists of highly qualified developers who are passionate about their work.",
            list: ["Social Media", "OTT Platform", "Messaging/Calling Application"],
            img: [
                "/img/industries/29.png",
                "/img/industries/30.png",
                "/img/industries/31.png",
                "/img/industries/32.png",
            ],
        },
        {
            name: "Travel & Tourism",
            description:
                "Providing IT solutions for travel and tourism businesses is a lucrative opportunity. IT solutions can help this type of business operate more efficiently in a variety of ways.",
            list: ["Online Booking System", "CRM Software", "Destination Management System"],
            img: [
                "/img/industries/33.png",
                "/img/industries/34.png",
                "/img/industries/35.png",
                "/img/industries/36.png",
            ],
        },
        {
            name: "Logistics & Transport",
            description:
                "Our solutions can be tailored to the specific needs of logistics and transport companies, assisting in the streamlining and improvement of operations. Our goal is to provide our clients with cost-effective, dependable, and innovative solutions.",
            list: ["Shipment/ Order Tracking", "Vehicle Tracking", "Logistics Management"],
            img: [
                "/img/industries/37.png",
                "/img/industries/38.png",
                "/img/industries/39.png",
                "/img/industries/40.png",
            ],
        },
    ];

    const component = components.find((item) => item.name === activeTab);

    return (
        <div className="p-5 min-w-full">
            <div className="flex justify-between items-center place-content-baseline">
                <p className="sm:text-[30px] md:text-[35px] xl:text-[44px] text-[22px] xl:leading-[54px] font-Montserrat font-bold text-start w-full">
                    {component.name}
                </p>

                <AppButton1 text="Get Quote" className="w-full text-nowrap z-10" onClick={(e) => setModal(true)} />
            </div>
            <div className="text-start py-3">
                <p className="text-[22px] font-[400] text-justify">{component.description}</p>
            </div>
            <div className="text-start py-5">
                <ul className="text-[20px] leading-[23px] font-semibold font-Montserrat text-black">
                    {component.list.map((item, index) => (
                        <li key={index} className="py-2">
                            <img className="inline-block mr-2" src="/img/industry-dots.webp" alt="disc" />
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="flex flex-wrap gap-10 justify-center items-center mx-auto">
                {component.img.map((src, index) => (
                    <div key={index}>
                        <img className="sm:min-w-[340px] w-full h-auto md:h-80 rounded-3xl" src={src} alt="" />
                    </div>
                ))}
            </div>
            {/* <AppButton1 text={"Explore More"} className="mt-6 sm:hidden  font-bold  text-white"> */}
            {/* <span className="inline-block mx-2 align-middle font-bold">
                    <img src="/img/industries/rightarrow.svg" alt="" />
                </span> */}
            {/* </AppButton1> */}
            <AppButton1
                className="mt-6 sm:hidden  font-bold  text-white text-nowrap"
                text="Get Quote"
                onClick={(e) => setModal(true)}
            />
        </div>
    );
};

export default Components;
