import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
    palette: {
        primary: {
            exlight: "#eff5fd",
            light: "#d4e2ff",
            main: "#3776ff",
            dark: "#0034de",

            // light: "#FFECD4",
            // main: "#FF9437",
            // dark: "#de6700",
            contrastText: "#000000",
        },
        // secondary: {
        //     exlight: "#f1fffb",
        //     light: "#d5fff4",
        //     main: "#00b081",
        //     dark: "#006c4f",
        //     contrastText: "#ffffff",
        // },
        white: {
            light: "#eeeeee",
            main: "#000000",
            dark: "#bababa",
            contrastText: "#000000",
        },
        text: {
            white: "#ffffff",
            light: "#596274",
            main: "#171C26",
            dark: "#000000",
            contrastText: "#ffffff",
        },
    },
    typography: {
        fontFamily: ["Outfit", "-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(","),
        h45: {
            fontSize: "1.75rem",
            "@media (max-width:720px)": {
                fontSize: "1.3rem",
            },
        },
        body3: {
            fontSize: "1.2rem",
            "@media (max-width:720px)": {
                fontSize: "1rem",
            },
        },
    },
});

theme = responsiveFontSizes(theme, { factor: 3 });

export default theme;
