import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BsClock } from "react-icons/bs";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import formatDate from "../../utils/formatDate";
import config from "../../config/config";
import { AiFillCaretDown } from "react-icons/ai";

const Blog = () => {
    const location = useLocation();
    const [blog, setBlog] = useState({});
    const [blogContant, setBlogContant] = useState([]);
    const [ContantModel, setContantModel] = useState(true);
    const navigate = useNavigate();
    let headings = [];

    blogContant?.map((h) => {
        headings?.push(h?.heading);
        return headings;
    });

    useEffect(() => {
        const data = location?.state;
        console.log(data);
        if (!data) navigate("/blog");
        const { content, ...blg } = data;
        setBlogContant(content);
        setBlog(blg);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="blog-view-page" style={{ marginTop: "130px" }}>
            <div className="top-section bg-el">
                <div className="page-section bg-el t-center">
                    <Typography className="heading t-center mb2" variant="h4" component="h2" fontWeight={600}>
                        {blog?.title}
                    </Typography>
                    <div className="f-center gap-4">
                        <div className="d-flex align-items-center gap-1">
                            <BsClock style={{ fill: "var(--app-primary-main)", fontSize: "18px" }} />
                            <Typography variant="body1" component="h3" className="clr-tl">
                                Reading Time : {blog.readingDuration}
                            </Typography>
                        </div>
                        <div className="d-flex align-items-center gap-1">
                            <FaRegCalendarAlt style={{ fill: "var(--app-primary-main)", fontSize: "18px" }} />
                            <Typography variant="body1" component="h3" className="clr-tl">
                                {formatDate(blog.createdAt, "dd-mm-yyyy")}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt5  mb5">
                <div className="f-center">
                    <img
                        src={config.apiUrl + blog.blogLogo}
                        alt={blog.title}
                        style={{
                            maxWidth: "40rem",
                            overflow: "hidden",
                            objectFit: "contain",
                            width: "100%",
                            paddingInline: "20px",
                        }}
                    />
                </div>
            </div>
            <div className="page-section contant">
                <div className="d-flex gap-4 ">
                    <div className="w-100 table" style={{ minWidth: "400px" }}>
                        <div
                            className="bg-w w-100 b-radius-2"
                            style={{
                                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.05)",
                            }}
                        >
                            <div
                                className="f-center align-items-center gap-1 bg-l "
                                style={{
                                    WebkitBorderTopLeftRadius: "10px",
                                    WebkitBorderTopRightRadius: "10px",
                                }}
                            >
                                <Typography variant="h5" component="h3" className="clr-pm p2 ">
                                    Table of Content
                                </Typography>
                                <AiFillCaretDown
                                    className="clr-pm c-pointer"
                                    style={{ fill: "var(--app-primary-main)", fontSize: "25px" }}
                                    onClick={() => setContantModel(!ContantModel)}
                                />
                            </div>
                            {ContantModel && (
                                <div className="" style={{ overflow: "hidden" }}>
                                    <ul>
                                        {headings?.map(
                                            (h, i) =>
                                                h && (
                                                    <a key={i} href={`#${i}`}>
                                                        <li className="p1 font-bold" style={{ fontSize: "18px" }}>
                                                            {h}
                                                        </li>
                                                    </a>
                                                )
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mb5">
                        <div className="mb3" style={{ fontSize: "18px", color: "#7B7B7B", fontWeight: "300" }}>
                            {blog?.description}
                        </div>
                        <div className="">
                            {blogContant?.map((bContant, i) => (
                                <div className="" key={i}>
                                    {bContant?.heading && (
                                        <a href={`#${i}`} id={i} className="navigate-before">
                                            <Typography variant="h6" component="h3" className="clr-pm mt2 mb2">
                                                {i + 1}.{bContant?.heading}
                                            </Typography>
                                        </a>
                                    )}
                                    <Typography
                                        variant="body1"
                                        component="h2"
                                        className="mb2 "
                                        color={"#7B7B7B"}
                                        fontWeight={400}
                                        fontSize={"18px"}
                                    >
                                        {bContant?.contant}
                                    </Typography>

                                    {parseInt(blogContant?.length / 2) === i && (
                                        <div className="mt5 mb5 d-flex justify-content-center align-items-center w-full">
                                            <img
                                                src={config?.apiUrl + blog?.blogImage}
                                                alt=""
                                                style={{ maxWidth: "30rem", borderRadius: "10px", width: "100%" }}
                                                className="shadow"
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blog;
