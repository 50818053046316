import { Typography } from "@mui/material";
import React, { useRef } from "react";
import AppButton1 from "../AppButton1/AppButton1";
import { PopupButton } from "react-calendly";

const ContactSection = ({ text = "Let's Talk", className = "" }) => {
    const scheduleMeetingModalRef = useRef(null);
    const handleClickScheduleMeeting = (e) => {
        // console.log(scheduleMeetingModalRef.current);
        scheduleMeetingModalRef.current.onClick(e);
    };
    return (
        <div className={"contact-section"}>
            <div className={`${className} pb1`}>
                <Typography className="mb1 t-center" variant="h4" component="h3" fontWeight={600}>
                    Let’s Turn <span className="clr-pm">Your Ideas</span> into Reality
                </Typography>
                <Typography className="mb2 t-center" variant="h6" component="p">
                    Let's innovate for your technology needs with Appretive Infotech.
                </Typography>
                <div className="f-center">
                    <AppButton1
                        variant="h6"
                        text={text}
                        // style={{ padding: "15px 50px 10px" }}
                        onClick={handleClickScheduleMeeting}
                    />
                    <PopupButton
                        ref={scheduleMeetingModalRef}
                        url="https://calendly.com/appretive"
                        rootElement={document.getElementById("root")}
                        styles={{ display: "none" }}
                        text=""
                    />
                </div>
            </div>
        </div>
    );
};

export default ContactSection;
