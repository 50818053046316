import React from "react";

const style = {
    container: {
        // width: "100%",
        // height: "176px",
        position: "relative",
        borderRadius: "18px",
    },
    heading: {
        position: "absolute",
        top: "40%",
        left: "50px",
    },
    image: {
        maxWidth: "1240px",
        width: "100%",
        height: "176px",
        borderRadius: "18px",
        objectFit: "cover",
    },
};

const PageHeader = ({ heading }) => {
    return (
        <div className="p3 p0-m" style={style.container}>
            <img src="/img/page-bg.png" alt="" style={style.image} />
            <h1 className="heading clr-w" style={style.heading}>
                {heading || "Page"}
            </h1>
        </div>
    );
};

export default PageHeader;
