import { Box, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import "../../assets/css/home.css";

import { MdLocationPin, MdMarkEmailUnread } from "react-icons/md";
// import { IoLogoLinkedin } from "react-icons/io";
// import { makeStyles } from "@mui/styles";
import "../../assets/css/contactUs.css";
import { AppButton1, ReviewSlider } from "../../components";
// import config from "../../config/config";
import { getReviews, submitForm } from "../../apis";

import { PopupButton } from "react-calendly";
import { useRef } from "react";
import { useEffect } from "react";
import { BiSolidPhoneCall } from "react-icons/bi";
import config from "../../config/config";
// import { Link } from "react-router-dom";
const contactPlaces = [
    {
        title: "India (H/Q)",
        content: "Surat, India",
        img: "/img/india-flag-rect.png",
        flag: "/img/ind-flag.png",
    },
    {
        title: "Australia",
        img: "/img/flag.png",
        flag: "/img/aus-flag.png",
    },
    {
        title: "New Zealand",
        img: "/img/nz-flag-rect.png",
        flag: "/img/nz-flag.png",
    },
    {
        title: "Dubai",
        img: "/img/dubai-flag-rect.png",
        flag: "/img/dubai-flag.png",
    },
    { title: "UK", img: "/img/uk-flag-rect.png", flag: "/img/usa-flag.png" },
    { title: "USA", img: "/img/usa-flag-rect.png", flag: "/img/usa-flag.png" },
];

// const useStyles = makeStyles((theme) => ({
//     filter: {
//         textAlign: "center",
//         marginBottom: theme.spacing(2),
//     },
//     galleryItem: {
//         padding: theme.spacing(2),
//         textAlign: "center",
//         width: 150,
//     },
//     itemImg: {
//         maxWidth: "100%",
//         // height: "55px",
//         width: "55px",
//     },
//     technoTitle: {
//         marginTop: theme.spacing(1),
//     },
// }));

const countryList = [
    "Afghanistan",
    "Åland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas (the)",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory (the)",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands (the)",
    "Central African Republic (the)",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands (the)",
    "Colombia",
    "Comoros (the)",
    "Congo (the Democratic Republic of the)",
    "Congo (the)",
    "Cook Islands (the)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic (the)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories (the)",
    "Gabon",
    "Gambia (the)",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (the)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic (the)",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands (the)",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (the Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands (the)",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger (the)",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands (the)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines (the)",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of North Macedonia",
    "Romania",
    "Russian Federation (the)",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan (the)",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan (Province of China)",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands (the)",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (the)",
    "United Kingdom of Great Britain and Northern Ireland (the)",
    "United States Minor Outlying Islands (the)",
    "United States of America (the)",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
];

const ContactUs = () => {
    const [contactFormData, setContactFormData] = useState({
        formName: "expert",
        fname: "",
        lname: "",
        email: "",
        looking: " ",
        budget: " ",
        country: " ",
        requirement: "",
        description: "",
        file: "",
    });

    const [reviewsList, setReviewsList] = useState([]);

    const fetchReviews = async (params, headers) => {
        try {
            const { data } = await getReviews(params, headers);
            if (data.status !== "success") throw new Error(data.message);

            setReviewsList(data?.data?.Review);
        } catch (err) {
            console.error(err.message);
        }
    };
    // const classes = useStyles();

    const handleChangeContactFormFields = (e) => {
        setContactFormData({ ...contactFormData, [e.target.name]: e.target.value });
    };

    const handleSubmitContactForm = async (e) => {
        e.preventDefault();
        try {
            const formDataValues = Object.values(contactFormData);

            if (formDataValues.some((value) => !value.trim())) {
                throw new Error("Please fill out all required fields.");
            }
            const { data } = await submitForm({
                ...contactFormData,
                name: contactFormData.fname + " " + contactFormData.lname,
            });
            if (data.status !== "success") throw new Error(data.message);
            setContactFormData({
                fname: "",
                lname: "",
                email: "",
                looking: " ",
                budget: " ",
                country: " ",
                requirement: "",
                description: "",
                file: "",
            });
        } catch (err) {
            console.error(err);
        }
    };

    const scheduleMeetingModalRef = useRef(null);
    const handleClickScheduleMeeting = (e) => {
        // console.log(scheduleMeetingModalRef.current);
        scheduleMeetingModalRef.current.onClick(e);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchReviews();
    }, []);

    return (
        <>
            <div className="page contact-us-page ">
                <div className="contact-us-section">
                    <div className="page-section" style={{ paddingTop: 0, position: "relative" }}>
                        {/* <div
                        style={{
                            backgroundImage: "url('/img/contect_bg.png')",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "left center",
                            backgroundSize: "contain",
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: "30%",
                            zIndex: 0,
                        }}
                        className="contect-bg"
                    ></div> */}

                        <Typography className="mb2" variant="h3" component="h3" textAlign="center" fontWeight={600}>
                            Talk to an <span className="heading-highlighted clr-pm">expert</span>
                        </Typography>
                        <Typography variant="h6" component="p" textAlign="center" color="text.light" fontWeight={500}>
                            Just Share Your Idea. It's Time to Let Your Ideas Grow.
                        </Typography>
                        <div className="content">
                            <div className="left">
                                <form
                                    style={{
                                        padding: "20px",
                                        backgroundColor: "#ffffff",
                                        "& .MuiInputBaseInput": {
                                            backgroundColor: "#FFFFFF !important",
                                        },
                                        borderRadius: "24px",
                                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                        "& .MuiInputBaseRoot": {
                                            backgroundColor: "#FFFFFF !important",
                                        },
                                        border: "1px solid #f1f1f1",
                                    }}
                                    onSubmit={handleSubmitContactForm}
                                >
                                    <p className="font-bold">
                                        Fill up the form and our Team will get back to you within 24 hours.
                                    </p>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={6}>
                                            <TextField
                                                variant="filled"
                                                label="First Name"
                                                name="fname"
                                                value={contactFormData.fname}
                                                fullWidth
                                                onChange={handleChangeContactFormFields}
                                                InputProps={{
                                                    style: {
                                                        background: "#FFFFFF",
                                                        paddingInline: "1px",
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        left: "-8px",
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <TextField
                                                variant="filled"
                                                label="Last Name"
                                                type="text"
                                                name="lname"
                                                value={contactFormData.lname}
                                                fullWidth
                                                onChange={handleChangeContactFormFields}
                                                sx={{
                                                    backgroundColor: "white",
                                                }}
                                                InputProps={{
                                                    style: {
                                                        background: "#FFFFFF",
                                                        paddingInline: "1px",
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        left: "-8px",
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={6}>
                                            <TextField
                                                variant="filled"
                                                label="Email"
                                                type="email"
                                                name="email"
                                                value={contactFormData.email}
                                                fullWidth
                                                onChange={handleChangeContactFormFields}
                                                InputProps={{
                                                    style: {
                                                        background: "#FFFFFF",
                                                        paddingInline: "1px",
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        left: "-8px",
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <TextField
                                                variant="filled"
                                                label="Contact no. / WhatsApp Id"
                                                type="text"
                                                name="mobile"
                                                value={contactFormData.mobile}
                                                fullWidth
                                                onChange={handleChangeContactFormFields}
                                                InputProps={{
                                                    style: {
                                                        background: "#FFFFFF",
                                                        paddingInline: "1px",
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        left: "-8px",
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Select
                                        variant="standard"
                                        name="looking"
                                        value={contactFormData.looking}
                                        fullWidth
                                        onChange={handleChangeContactFormFields}
                                        sx={{ paddingBlock: "8px" }}
                                    >
                                        <MenuItem value=" " disabled>
                                            <div className="" style={{ opacity: "0.65" }}>
                                                What are lookin for?
                                            </div>
                                        </MenuItem>{" "}
                                        <MenuItem value="mobile_app">Mobile App</MenuItem>
                                        <MenuItem value="web_app">Web App</MenuItem>
                                    </Select>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={6}>
                                            <Select
                                                variant="standard"
                                                name="budget"
                                                value={contactFormData.budget}
                                                fullWidth
                                                onChange={handleChangeContactFormFields}
                                                sx={{ paddingBlock: "8px" }}
                                            >
                                                <MenuItem value=" " disabled>
                                                    <div className="" style={{ opacity: "0.65" }}>
                                                        Budget?
                                                    </div>
                                                </MenuItem>{" "}
                                                <MenuItem value="10000-20000">10000₹ - 20000₹ </MenuItem>
                                                <MenuItem value="25000-50000">25000₹ - 50000₹</MenuItem>
                                                <MenuItem value="51000-100000">51000₹ - 100000₹</MenuItem>
                                                <MenuItem value="100000-500000">100000₹ - 500000₹</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <Select
                                                variant="standard"
                                                name="country"
                                                value={contactFormData.country}
                                                fullWidth
                                                onChange={handleChangeContactFormFields}
                                                sx={{ paddingBlock: "8px" }}
                                            >
                                                <MenuItem value=" " disabled>
                                                    <div className="" style={{ opacity: "0.65" }}>
                                                        Select Country
                                                    </div>
                                                </MenuItem>
                                                {countryList?.map((country, i) => (
                                                    <MenuItem key={i} value={country}>
                                                        {country}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <TextField
                                        variant="filled"
                                        label="Brief project description"
                                        name="description"
                                        value={contactFormData.description}
                                        multiline
                                        minRows={2}
                                        fullWidth
                                        onChange={handleChangeContactFormFields}
                                        InputProps={{
                                            style: {
                                                background: "#FFFFFF",
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                left: "-8px",
                                            },
                                        }}
                                    />
                                    <Typography className="" variant="body1" component="p">
                                        {/* By submitting this form you agree to our{" "} */}
                                        *We ensure the complete protection of your personal information. Rest assured,
                                        we will not disclose the details you provide to any third parties. Your email
                                        address will never be used for spam.
                                        {/* <span
                    className="clr-pm"
                    style={{ fontWeight: "bold", cursor: "pointer" }}
                  >
                    Privacy policy.
                  </span> */}
                                    </Typography>
                                    <div className="f-center mt3">
                                        <AppButton1
                                            text="Send Now"
                                            type="submit"
                                            style={{
                                                padding: "var(--app-base-spacing-1) var(--app-base-spacing-5)",
                                            }}
                                        />
                                    </div>
                                </form>
                                {/* <div className="contact-details mt3">
                                <div className="d-flex align-items-center gap-2 mb2">
                                    <div className="icon">
                                        <BiSolidPhoneCall
                                            style={{ fill: "var(--app-primary-main)", fontSize: "20px" }}
                                        />
                                    </div>
                                    <div className="details">
                                        <Typography
                                            className="mb1"
                                            variant="body1"
                                            component="p"
                                            color="primary.main"
                                            fontWeight={600}
                                        >
                                            Phone/WhatsApp
                                        </Typography>
                                        <Typography variant="body1" component="p">
                                            {config.contact.mobile}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center gap-2 mb2">
                                    <div className="icon">
                                        <MdOutlineAlternateEmail
                                            style={{ fill: "var(--app-primary-main)", fontSize: "26px" }}
                                        />
                                    </div>
                                    <div className="details">
                                        <Typography
                                            className="mb1"
                                            variant="body1"
                                            component="p"
                                            color="primary.main"
                                            fontWeight={600}
                                        >
                                            Email
                                        </Typography>
                                        <Typography variant="body1" component="p">
                                            {config.contact.email}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    <div className="icon">
                                        <BsFillTelephoneFill
                                            style={{ fill: "var(--app-primary-main)", fontSize: "26px" }}
                                        />
                                    </div>
                                    <div className="details">
                                        <Typography
                                            className="mb1"
                                            variant="body1"
                                            component="p"
                                            color="primary.main"
                                            fontWeight={600}
                                        >
                                            Address
                                        </Typography>
                                        <Typography variant="body1" component="p">
                                            {config.contact.address}
                                        </Typography>
                                    </div>
                                </div>
                            </div> */}
                            </div>
                            <div className="right">
                                <div className="image">
                                    <img src="/img/animationn.gif" alt="apprative" />

                                    {/* <Gif /> */}
                                    {/* <img src="/img/contact-us.png" alt="apprative" /> */}
                                </div>
                                {/* <div className="contact-details" style={{ marginTop: "6rem" }}>
                                <div className="d-flex align-items-center gap-2 mb2">
                                    <div className="icon">
                                        <BiSolidPhoneCall
                                            style={{ fill: "var(--app-primary-main)", fontSize: "35px" }}
                                        />
                                    </div>
                                    <div className="details">
                                        <Typography
                                            className="mb1"
                                            variant="body1"
                                            component="p"
                                            color="primary.main"
                                            fontWeight={600}
                                        >
                                            Phone/WhatsApp
                                        </Typography>
                                        <Typography variant="body1" component="p" position={"relative"} zIndex={0}>
                                            {config.contact.mobile}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center gap-2 mb2">
                                    <div className="icon">
                                        <MdMarkEmailUnread
                                            style={{ fill: "var(--app-primary-main)", fontSize: "30px" }}
                                        />
                                    </div>
                                    <div className="details">
                                        <Typography
                                            className="mb1"
                                            variant="body1"
                                            component="p"
                                            color="primary.main"
                                            fontWeight={600}
                                        >
                                            Email
                                        </Typography>
                                        <Typography variant="body1" component="p" position={"relative"} zIndex={0}>
                                            {config.contact.email}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    <div className="icon">
                                        <MdLocationPin style={{ fill: "var(--app-primary-main)", fontSize: "30px" }} />
                                    </div>
                                    <div className="details">
                                        <Typography
                                            className="mb1"
                                            variant="body1"
                                            component="p"
                                            color="primary.main"
                                            fontWeight={600}
                                        >
                                            Address
                                        </Typography>
                                        <Typography variant="body1" component="p" position={"relative"} zIndex={0}>
                                            {config.contact.address}
                                        </Typography>
                                    </div>
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="contact-review mb-[50px] md:mb-[70px] lg:mb-[130px]  ">
                        <div className="page-section contact-review-flex sm:flex flex-wrap justify-evenly  ">
                            <div className={"contact-section"}>
                                <div className={`page-section`}>
                                    <Typography
                                        className="mb3 t-start clr-w font-bold"
                                        variant="h4"
                                        component="h3"
                                        fontWeight={900}
                                        style={{ fontFamily: "Gilroy-bold", fontSize: "45px" }}
                                    >
                                        Schedule
                                        <span className="clr-pm">
                                            {" "}
                                            Your <br /> Meeting
                                        </span>
                                    </Typography>
                                    <Typography
                                        className="mb3 t-start clr-w"
                                        variant="h6"
                                        component="p"
                                        style={{ fontFamily: "Gilroy-bold" }}
                                    >
                                        Schedule An Initial FREE 30 Minute Consulting Call To <br />
                                        Discuss Your Needs.
                                    </Typography>
                                    <div className="f-start ">
                                        {/* <AppButton1
                      variant="h6"
                      //   style={{
                      //     fontFamily: "Gilroy-bold",
                      // backgroundColor: "#3776FF",
                      // color: "white",
                      //   }}
                    /> */}
                                        <div className={`app-button-container-1 `}>
                                            <AppButton1
                                                variant="h6"
                                                style={{ fontFamily: "Gilroy-bold" }}
                                                text={"Let's Talk"}
                                                onClick={handleClickScheduleMeeting}
                                            />

                                            <span></span>
                                        </div>

                                        <PopupButton
                                            ref={scheduleMeetingModalRef}
                                            url="https://calendly.com/appretive"
                                            rootElement={document.getElementById("root")}
                                            styles={{ display: "none" }}
                                            text=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="review-section lg:mb-[-180px] ">
                                <div className="page-section" style={{ paddingTop: 0, paddingLeft: 0 }}>
                                    <div className="bg-w shadow review-box w-[95%]  ">
                                        {/* <h2
                                        className="mb2 t-center"
                                        style={{ fontSize: "36px", fontWeight: "600", padding: "20px" }}
                                    > */}
                                        <Typography
                                            className="mb1 mt1 t-center clr-w"
                                            variant="h4"
                                            component="h3"
                                            fontWeight={600}
                                            style={{ fontFamily: "Gilroy-bold" }}
                                        >
                                            <span className="heading-highlighted clr-b">
                                                {" "}
                                                What’s Our <span className="clr-pm">Client</span> Say
                                            </span>{" "}
                                        </Typography>
                                        <div className="slider f-center">
                                            <ReviewSlider data={reviewsList} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PopupButton
                        ref={scheduleMeetingModalRef}
                        url="https://calendly.com/appretive"
                        rootElement={document.getElementById("root")}
                        styles={{ display: "none" }}
                        text=""
                    />
                </div>
            </div>

            <div className="contact-places-section ">
                <div className="page-section !p-3">
                    <Typography className="mb1" variant="h4" component="h3" textAlign="center" fontWeight={600}>
                        <span className="heading-highlighted clr-b"> Our </span>
                        <span className="clr-pm"> Global Presence</span>
                    </Typography>
                    <div className="card-container mt5">
                        {contactPlaces.map((card) => (
                            <div className="card" key={card.title}>
                                <div className="card-head f-center mb2">
                                    <img src={card.img} alt="" className="card-img" />
                                </div>
                                <div className="card-body f-center flex-column">
                                    <Typography
                                        variant="h5"
                                        component="h6"
                                        textAlign="center"
                                        fontWeight={600}
                                        className="title"
                                    >
                                        {card.title}
                                    </Typography>
                                    {card.content && <div className="card-content clr-tl t-center">{card.content}</div>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="making-feature">
                <div className="page-section">
                    <Typography
                        variant="h4"
                        component="p"
                        textAlign="center"
                        color="text.dark"
                        fontWeight={700}
                        fontFamily={"Helvetica"}
                    >
                        Making the future is the best way
                    </Typography>
                    <Typography
                        variant="body1"
                        component="p"
                        textAlign="center"
                        color="text.light"
                        fontWeight={600}
                        fontFamily={"Helvetica"}
                        maxWidth={300}
                        margin={"auto"}
                        marginBlock={"18px"}
                    >
                        Join Appretive Infosoft, and together we can achieve success!
                    </Typography>

                    <div className="d-flex  card-container mt5 mb2">
                        <div className="card min-w-[230px] lg:min-w-[350px]">
                            <div className="card-head f-center mb1">
                                <BiSolidPhoneCall
                                    fontSize={"40px"}
                                    // className="text-[40px]"
                                    style={{ fill: "var(--app-primary-main)" }}
                                />
                            </div>
                            <Box className="card-body f-center flex-column">
                                <Typography
                                    className="card-content clr-pm t-center"
                                    fontWeight={"600"}
                                    fontSize={"16px"}
                                    fontFamily={"Helvetica"}
                                >
                                    Phone/WhatsApp
                                </Typography>
                                <Typography
                                    variant="h5"
                                    component="h6"
                                    textAlign="center"
                                    color={"text.light"}
                                    fontWeight={600}
                                    className="title mt1 mb2"
                                    fontFamily={"Helvetica"}
                                >
                                    {config.contact?.mobile}
                                </Typography>
                            </Box>
                        </div>
                        <div className="card min-w-[230px] lg:min-w-[400px]">
                            <div className="card-head f-center mb1">
                                <MdLocationPin fontSize={"40px"} style={{ fill: "var(--app-primary-main)" }} />
                            </div>
                            <Box className="card-body f-center flex-column">
                                <Typography
                                    className="card-content clr-pm t-center"
                                    fontWeight={"600"}
                                    fontSize={"16px"}
                                    fontFamily={"Helvetica"}
                                >
                                    Address
                                </Typography>
                                <Typography
                                    variant="body1"
                                    component="h6"
                                    textAlign="center"
                                    color={"text.light"}
                                    fontWeight={600}
                                    className="title"
                                    fontFamily={"Helvetica"}
                                >
                                    {/* 455, 4th Floor, Royal Arcade, Surat, India. */}
                                    {config.contact?.address}
                                </Typography>
                            </Box>
                        </div>
                        <div className="card min-w-[230px] lg:min-w-[350px]">
                            <div className="card-head f-center mb1">
                                <MdMarkEmailUnread fontSize={"40px"} style={{ fill: "var(--app-primary-main)" }} />
                            </div>
                            <Box className="card-body f-center flex-column">
                                <Typography
                                    className="card-content clr-pm t-center"
                                    fontWeight={"600"}
                                    fontSize={"16px"}
                                    fontFamily={"Helvetica"}
                                >
                                    Email
                                </Typography>
                                <Typography
                                    variant="h5"
                                    component="h6"
                                    textAlign="center"
                                    color={"text.light"}
                                    fontWeight={600}
                                    className="title mt1 mb2"
                                    fontFamily={"Helvetica"}
                                >
                                    {config.contact?.email}
                                </Typography>
                            </Box>
                        </div>

                        {/* <div className="card min-w-[230px]">
              <div className="card-head f-center mb1">
                <IoLogoLinkedin
                  fontSize={"40px"}
                  style={{ fill: "var(--app-primary-main)" }}
                />
              </div>
              <Box className="card-body f-center flex-column">
                <Typography
                  className="card-content clr-pm t-center"
                  fontWeight={"600"}
                  fontSize={"16px"}
                  fontFamily={"Helvetica"}
                >
                  LinkedIn
                </Typography>
                <Typography
                  variant="h5"
                  component="h6"
                  textAlign="center"
                  color={"text.light"}
                  fontWeight={600}
                  className="title mt1 mb2"
                  fontFamily={"Helvetica"}
                >
                  Appretive Infosoft
                </Typography>
              </Box>
            </div> */}
                    </div>
                </div>
            </div>
            <div></div>

            {/* <div className="page-section pt1 pb1">
        <div className="meet-section">
          <div className="f-center flex-column p4">
            <Typography
              className="mb2"
              variant="h4"
              component="h4"
              color="primary.main"
              textAlign="center"
              fontWeight={600}
            >
              Schedule Meeting
            </Typography>
            <Typography
              className="mb3"
              variant="h6"
              component="p"
              textAlign="center"
              fontWeight={600}
            >
              Schedule An Initial FREE 30 Minute Consulting Call To Discuss Your
              Needs.
            </Typography>
            <AppButton1
              text="Let's Talk"
              style={{
                padding: "var(--app-base-spacing-1) var(--app-base-spacing-4)",
              }}
              onClick={handleClickScheduleMeeting}
            />
            <PopupButton
              ref={scheduleMeetingModalRef}
              url="https://calendly.com/appretive"
              rootElement={document.getElementById("root")}
              styles={{ display: "none" }}
              text=""
            />
          </div>
        </div>
      </div> */}
        </>
    );
};

export default ContactUs;
