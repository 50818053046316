import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { ContactPopup, Footer, Header } from "../../components";
import ModelContext from "../../context/contactModelContext";
import config from "../../config/config";
import { MdPictureAsPdf } from "react-icons/md";

const Root = () => {
  const [modal, setModal] = useState(false);

  return (
    <ModelContext.Provider value={{ modal, setModal }}>
      <div className="main-section">
        <Header />
        <Outlet />
        <div className="contact-buttons">
          <div className="btn btn-blue">
            <MdPictureAsPdf fontSize={"28px"} fill="white" />
            <span>Appretive Presentation</span>
          </div>
          <a href={`tel:${config.contact.mobile}`}>
            <div className="btn btn-yellow">
              <img src="/img/call-calling.png" alt="" width="28px" />
              <span>
                {config.contact.mobile} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </div>
          </a>
        </div>
        <Footer />
        <ContactPopup open={modal} onClose={(val) => setModal(val)} />
      </div>
    </ModelContext.Provider>
  );
};

export default Root;
