import { Typography } from "@mui/material";
import React from "react";

const Page404 = () => {
    return (
        <div style={{ maxWidth: "40rem", margin: "auto", marginBlock: "4rem", height: "80vh" }} className="">
            <img src="/img/Empty.gif" alt="empty" className="w-100" />
            <Typography className="mb5" variant="h4" component="h3" textAlign="center" fontWeight={600}>
                Page Not Found!
            </Typography>
        </div>
    );
};

export default Page404;
