/* eslint-disable jsx-a11y/no-distracting-elements */
import { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
// import { GoDash } from "react-icons/go";
import Components from "./Component";
import { Typography } from "@mui/material";

const Industries = () => {
    const [activeTab, setActiveTab] = useState("Fintech");
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        if (isOpen) {
            setIsOpen(false);
        } else {
            setIsOpen(false);
        }
    };

    const menubar = [
        "Fintech",
        "E-commerce",
        "Education",
        "Healthcare",
        "Hospitality",
        "Real Estate",
        "Manufacturing",
        "Entertainment",
        "Travel & Tourism",
        "Logistics & Transport",
    ];

    return (
        <div className="page-section w-full">
            <Typography className="mb2" variant="h4" component="h3" textAlign="center" fontWeight={600}>
                <span className="heading-highlighted clr-b" style={{ fontFamily: "Gilroy-bold" }}>
                    Industries
                </span>{" "}
                <span className="clr-pm" style={{ fontFamily: "Gilroy-bold" }}>
                    we serve
                </span>
            </Typography>

            <div className="w-full text-center lg:max-w-[40%] mx-auto mt-6 mb-4">
                <p className="sm:text-xl md:text-2xl  px-2 text-xl font-bold font-Montserrat text-[rgb(42, 40, 41)]  tracking-normal pb-5 text-pretty">
                    IT Technology Services Build Specifically For Your Business
                </p>
            </div>
            <div className="sm:hidden border-[2px] mx-5 w-[250px]  sm:max-w-[300px] bg-sky-200 border-[#7db2da] sm:py-2 text-start flex justify-between">
                <div>
                    <p className=" mx-4 text-[19px] sm:text-2xl text-[#2273b6]"> {activeTab} </p>
                </div>

                <div className="flex">
                    <div
                        className="inline-block  border-[1px] mx-2 border-slate-300 sm:static "
                        style={{ width: "1px" }}
                    ></div>
                    <div className="mx-2 text-2xl">
                        <span className="inline-block align-middle" onClick={toggle}>
                            <RiArrowDropDownLine className="text-3xl" />
                        </span>
                    </div>
                    {isOpen && (
                        <div className=" absolute left-8 mt-10 w-64 h-80 overflow-auto rounded-md shadow-lg py-2 bg-sky-100 ring-1 ring-black z-50 ring-opacity-5 focus:outline-none">
                            {menubar.map((menu) => (
                                <div
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-sky-200 hover:cursor-pointer"
                                    role="menuitem"
                                    key={menu}
                                >
                                    <p
                                        className={`mx-4 text-xl`}
                                        onClick={() => {
                                            handleTabChange(menu);
                                        }}
                                    >
                                        {menu}
                                    </p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className=" xl:w-full xl:mx-auto sm:flex sm:rounded-3xl  mx-5 bg-sky-50">
                <div className=" sm:py-5 md:w-[40%] xl:w-[30%]">
                    <ul className="hidden sm:flex sm:flex-col gap-[40px]  sm:text-[20px] sm:text-start sm:px-2 md:px-3 xl:px-5 font-Montserrat font-[600] leading-[23px] z-50  tracking-wide">
                        {menubar.map((menu) => (
                            <li key={menu}>
                                <p
                                    className={`hover:cursor-pointer  
                ${activeTab === menu && "text-[#056AB2]"}`}
                                    onClick={() => handleTabChange(menu)}
                                >
                                    {menu}
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>

                <div
                    className="sm:min-h-full sm:border-[1px] sm:border-slate-300 sm:static bg-sky-200"
                    style={{ width: "1px" }}
                >
                    &nbsp;
                </div>

                <div className="xl:w-full bg-sky-50">
                    <Components activeTab={activeTab} />
                </div>
            </div>
        </div>
    );
};

export default Industries;
