import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { IconButton, Typography } from "@mui/material";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";

import "swiper/css";
import "swiper/css/pagination";

import "../../assets/css/reviewSlider.css";
import config from "../../config/config";

const ReviewSliderCards = ({ data, buttonsId }) => {
    if (!buttonsId) throw new Error("Button Id for Swiper is missing. buttonsId={YOUR_ID_HERE}");
    if (typeof buttonsId !== "string") throw new Error("Buttons Id must be a string");

    data = data || [1, 2, 3, 4, 5, 6, 7];

    return (
        <div className="rating-slider-cards">
            <Swiper
                slidesPerView={1}
                spaceBetween={20}
                modules={[Autoplay, Navigation]}
                navigation={{ prevEl: `.gotToPrevSlide-${buttonsId}`, nextEl: `.gotToNextSlide-${buttonsId}` }}
                autoplay={{ delay: 2500, disableOnInteraction: false }}
                breakpoints={{
                    1600: { slidesPerView: 4 },
                    1200: { slidesPerView: 3 },
                    960: { slidesPerView: 2 },
                    480: { slidesPerView: 1 },
                }}
            >
                {data.map((ele, index) => (
                    <SwiperSlide key={index}>
                        <div className="card-container">
                            <div className="card" style={{ height: "19rem" }}>
                                {/* <div className="card-image-container">
                                    <img className="card-image" src={config.apiUrl + ele.image} alt="" />
                                </div> */}
                                <div className="card-content mt1">
                                    <Typography
                                        className="review-text t-center ellipsis-8line quicksand-fonts"
                                        variant="body1"
                                        component="p"
                                    >
                                        {ele.review}
                                    </Typography>
                                    <Typography
                                        className="reviewer-name t-center clr-pm mt1 "
                                        variant="body3"
                                        component="h4"
                                        fontWeight={600}
                                    >
                                        {ele.name}
                                    </Typography>
                                    <div className="f-center mt2">
                                        <div className="f-center">
                                            <img
                                                className="card-image"
                                                src={config.apiUrl + ele?.image}
                                                alt=""
                                                width={30}
                                            />
                                            <div className="placeholder ml1 " style={{ fontSize: "22px" }}>
                                                {ele?.role}
                                            </div>
                                        </div>
                                        {/* <Typography
                                        className="placeholder "
                                        variant="body2"
                                        component="span"
                                    >
                                        {ele.role}
                                    </Typography> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="slider-navigation d-flex align-items-center justify-content-center mt3">
                <IconButton className={`gotToPrevSlide-${buttonsId}`} color="primary" size="large" sx={{ mr: 5 }}>
                    <BsArrowLeftCircleFill style={{ fill: "var(--app-primary-main)" }} />
                </IconButton>
                <IconButton className={`gotToNextSlide-${buttonsId}`} color="primary" size="large">
                    <BsArrowRightCircleFill style={{ fill: "var(--app-primary-main)" }} />
                </IconButton>
            </div>
        </div>
    );
};

export default ReviewSliderCards;
