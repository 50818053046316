import React, { useEffect, useRef, useState } from "react";
import { AppButton1, ReviewSlider } from "../../components";
import { PopupButton } from "react-calendly";
import "swiper/swiper-bundle.min.css";
import "swiper/css";
import "swiper/css/pagination";
import { Typography } from "@mui/material";

import "../../assets/css/mobileAppDev.css";
import { getReviews } from "../../apis";
// import ModelContext from "../../context/contactModelContext";

const stepsData = [
    {
        title: "Target for Intent-Based Customers",
        description:
            "Increase conversions by using data-driven, strategic advertising campaigns to target intent-based consumers who are actively looking for products similar to yours.",

        image: "/img/Target-Consumers-With-Intent.png",
    },
    {
        title: "Start Slow, Test & Grow",
        description:
            "Develop your eCommerce business gradually by starting small, testing several approaches, and identifying what works best through ongoing optimization and data analysis.",
        image: "/img/Start-Slow-Test-Grow.png",
    },
    {
        title: "Who | Where | When | How",
        description:
            "Learn about the demographics of your audience, their locations, peak activity times, and best ways to approach them using our data-driven marketing techniques.",
        image: "/img/Who-Where-When-How.png",
    },
];

const technologies = [
    {
        title: "React JS",
        img: "/img/React-service.png",
    },
    {
        title: "Vue Js",
        img: "/img/Vue-service.png",
    },
    {
        title: "Node Js",
        img: "/img/node-service.png",
    },
    {
        title: "Python",
        img: "/img/Python-service.png",
    },
    {
        title: "Dotnet",
        img: "/img/dot-net-service.png",
    },
    {
        title: "PHP",
        img: "/img/PHP-service.png",
    },
    {
        title: "Ruby",
        img: "/img/Ruby-service.png",
    },
    {
        title: "BootStrap",
        img: "/img/Bootstrap-service.png",
    },
    {
        title: "Material UI",
        img: "/img/mui-service.png",
    },
    {
        title: "Tailwind Css",
        img: "/img/Tailwind-service.png",
    },
];

const EcommerceAds = () => {
    const [reviewsList, setReviewsList] = useState([]);
    // const { setModal } = useContext(ModelContext);

    const handleClickScheduleMeeting = (e) => {
        scheduleMeetingModalRef.current.onClick(e);
    };
    const scheduleMeetingModalRef = useRef(null);
    const fetchReviews = async (params, headers) => {
        try {
            const { data } = await getReviews(params, headers);
            if (data.status !== "success") throw new Error(data.message);

            setReviewsList(data?.data?.Review);
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        fetchReviews();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="mt-16">
                <div className="flex flex-wrap-reverse items-center justify-evenly sm:mx-[15px] md:mx-[20px] lg:mx-[50px] px-4 lg:my-10 my-6 pt-12 bg-white">
                    <div className="w-full  lg:max-w-[50%]">
                        <h1 className=" text-[30px]  md:text-[2.5vw] font-bold font-poppins  text-start mb-4 my-5">
                            Do you want to use Google Ads and Meta to grow your eCommerce business?
                        </h1>
                        <p className="text-justify font-poppins font-[400] text-[18px] mb-6">
                            Boost your eCommerce company's visibility and expand your customer base by utilizing Google
                            Ads and Meta. You may improve sales, brand awareness, and traffic to your online store by
                            utilizing targeted advertising on these popular platforms. With Google AdWords, you can
                            design extremely targeted campaigns that show up online and in search results, making sure
                            you connect with potential clients at the correct time.
                        </p>

                        <div className="f-start">
                            <AppButton1
                                variant="h6"
                                style={{ fontFamily: "Gilroy-bold" }}
                                text={"Let's Talk"}
                                onClick={handleClickScheduleMeeting}
                            />
                            <PopupButton
                                ref={scheduleMeetingModalRef}
                                url="https://calendly.com/appretive"
                                rootElement={document.getElementById("root")}
                                styles={{ display: "none" }}
                                text=""
                            />
                        </div>
                    </div>
                    <div className="relative">
                        <img
                            src="/img/D2C-E-Commerce-Marketing-Services.png"
                            alt="e-commerce-ads services"
                            className="w-full max-w-md mx-auto"
                        />
                    </div>
                </div>
                <div className="my-12 max-w-[75%] mx-auto">
                    <h1 className="text-[30px] text-center  md:text-[2.5vw] font-bold font-poppins  my-6">
                        E-commerce Ads Development Tech
                    </h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 mx-auto place-content-center">
                        {technologies.map((tech, index) => (
                            <div
                                key={index}
                                className="flex flex-col items-center justify-center gap-2 p-4 bg-white sm:w-full border-[1px] md:max-w-[250px] rounded-lg shadow-lg  transform transition-transform duration-300 hover:scale-105 hover:shadow-xl group"
                            >
                                <div className="mb-1">
                                    <img className="h-10 w-10" src={tech.img} alt="img" />
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-xl font-semibold font-poppins ">{tech.title}</h2>
                                    <div className="hidden group-hover:block transition-transform duration-300 ease-in-out border-b-2 border-blue-500"></div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="w-full">
                    <div className="text-center mx-auto max-w-[70%]">
                        <h1 className="text-[30px] lg:text-[2.5vw] font-bold mb-4 font-poppins">
                            We customize your advertisements to increase sales
                        </h1>
                        <p className="text-[20px] font-poppins text-justify px-5 my-6 lg:my-10 mx-auto md:text-center leading-9">
                            Use the amazing features of Google and Meta ads to your advantage to optimize your return on
                            investment while we assist you in customizing your copy to appeal to the intended audience
                            and highlight your goods or services. Our team of professionals in shopping advertisements
                            helps you make the most out of your direct-to-consumer (D2C) campaign by attracting
                            consumers and turning them into devoted clients.
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-1 place-items-center md:grid-cols-2 lg:grid-cols-3 md:max-w-[75%] mx-auto gap-2">
                    <div className="flex w-full flex-col items-center justify-center border-2 border-blue-500 min-h-[135px] max-w-[350px] transition-transform duration-1000 ease-in-out hover:-translate-y-4 hover:shadow-lg rounded-md">
                        <p className="text-[28px] font-bold">5X</p>
                        <p className="text-[22px]">Growth of Average Revenue</p>
                    </div>
                    <div className="flex w-full flex-col items-center justify-center border-2 border-blue-500 min-h-[135px] max-w-[350px] transition-transform duration-1000 ease-in-out hover:-translate-y-4 hover:shadow-lg rounded-md">
                        <p className="text-[28px] font-bold">3X</p>
                        <p className="text-[22px]">More Reach</p>
                    </div>
                    <div className="flex w-full flex-col items-center justify-center border-2 border-blue-500 min-h-[135px] max-w-[350px] transition-transform duration-1000 ease-in-out hover:-translate-y-4 hover:shadow-lg rounded-md">
                        <p className="text-[28px] font-bold">6X</p>
                        <p className="text-[22px]">Faster ROI</p>
                    </div>
                </div>

                <div className=" flex gap-2 flex-wrap justify-around  items-center text-center mt-10 mb-12 max-w-[70%] mx-auto p-7 border-[15px] border-blue-500 rounded-xl shadow-md round">
                    <p className="text-[22px] font-bold font-poppins">Request a free quote in less than a minute</p>
                    <div className="f-start">
                        <AppButton1
                            variant="h6"
                            style={{ fontFamily: "Gilroy-bold" }}
                            text={"Let's Talk"}
                            onClick={handleClickScheduleMeeting}
                        />
                        <PopupButton
                            ref={scheduleMeetingModalRef}
                            url="https://calendly.com/appretive"
                            rootElement={document.getElementById("root")}
                            styles={{ display: "none" }}
                            text=""
                        />
                    </div>
                </div>

                <div className="w-[90%] md:max-w-[70%] mt-6 mx-5 md:mx-auto ">
                    <h2 className="text-[30px] text-center  lg:text-[2.5vw] font-bold mb-4 font-poppins">
                        Searching for eCommerce Shopping Ads Experts?
                    </h2>
                    <p className="text-[19px]  font-poppins font-[400] text-justify px-5 my-6 lg:my-10 mx-auto md:text-center leading-9">
                        Every day, billions of dollars are spent on marketing globally, yet most companies find it
                        difficult to accurately assess its success, particularly in direct-to-consumer (D2C) firms where
                        consumer behavior and industry trends are changing quickly. We are industry-savvy professionals
                        with years of experience in eCommerce shopping ads, and we can help you nail the campaign.
                    </p>
                </div>

                <div className="flex lg:max-w-[75%] flex-col lg:flex-row items-center lg:space-x-10 w-full p-6 mx-auto bg-white">
                    <div className="flex flex-col items-start justify-start gap-2 w-full lg:w-[48%] transition-transform duration-1000 ease-in-out hover:-translate-y-4 hover:shadow-lg ">
                        <h2 className="text-[30px] font-bold font-poppins text-justify  mx-4">Google Ads</h2>
                        <p className="text-[19px] font-[400] font-poppins text-justify my-5 mx-4">
                            You can grow your online market for your eCommerce store by using our advertising solution.
                            Using Google Ads, we are able to :
                        </p>
                        <ul className="list-none space-y-3 text-left mb-8 text-[18px]  font-poppins font-[400] w-[95%]">
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className="">Design and maintain online marketing.</span>
                            </li>
                            <li className="flex items-start">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className="">Concentrate on your target markets. </span>
                            </li>
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className=""> Make the most of your money.</span>
                            </li>
                            <li className="flex items-start">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className="">Monitor your outcomes.</span>
                            </li>
                            <li className="flex items-start">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className=""> Improve lead generation and brand awareness</span>
                            </li>
                        </ul>
                    </div>
                    <div className=" flex flex-col items-start justify-start w-full lg:w-[48%] transition-transform duration-1000 ease-in-out hover:-translate-y-4 hover:shadow-lg ">
                        <h2 className="text-[30px]  font-bold font-poppins text-justify my-2 mt-4">Meta Ads</h2>
                        <p className="text-[19px] font-[400] font-poppins text-justify my-5">
                            With our expertise in meta advertisements, we can assist your D2C online enterprises with
                            their unique marketing requirements. Meta advertisements enable us to:
                        </p>
                        <ul className="list-none space-y-3 text-left mb-8 text-[18px]  font-poppins font-[400] w-[95%]">
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span>Get in front of your target market and boost sales</span>
                            </li>
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span>
                                    Make compelling and customized advertisements that cater to the needs and tastes of
                                    your target audience
                                </span>
                            </li>
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span>Find out how well the advertisements are performing and provide comments</span>
                            </li>
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span>Campaigns should be optimized for best outcomes.</span>
                            </li>
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span>Benefit from multi-channel advertising</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="f-center mb-6">
                    <AppButton1
                        variant="h6"
                        style={{ fontFamily: "Gilroy-bold" }}
                        text={"Let's Talk"}
                        onClick={handleClickScheduleMeeting}
                    />
                    <PopupButton
                        ref={scheduleMeetingModalRef}
                        url="https://calendly.com/appretive"
                        rootElement={document.getElementById("root")}
                        styles={{ display: "none" }}
                        text=""
                    />
                </div>

                <div className="steps">
                    <div className="mx-auto text-center max-w-[80%]">
                        <h1 className="text-[30px] text-justify md:text-center lg:text-[2.5vw] font-poppins font-bold mb-4">
                            Our Approach to D2C eCommerce Ads
                        </h1>
                    </div>
                    <div className="my-10 max-w-[75%] mx-auto">
                        <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mx-auto place-content-center">
                            {stepsData.map((service, index) => (
                                <div
                                    key={index}
                                    className="p-5 flex flex-col justify-center items-center text-center bg-white sm:w-full border-[1px] md:max-w-[400px] rounded-lg shadow-lg  transform transition-transform ease-in-out duration-300 hover:scale-105 hover:shadow-xl group"
                                >
                                    <div className="mb-[15px]">
                                        <img src={service.image} className="h-[100px] w-[100px]" alt="img" />
                                    </div>
                                    <div className="my-4">
                                        <h2 className="text-[24px]  font-semibold  capitalize">{service.title}</h2>
                                        <div className="hidden transition-transform duration-2000 ease-in-out border-b-2 border-blue-500 group-hover:block "></div>
                                    </div>
                                    <p className="capitalize font-poppins font-[500] my-3 text-[16px] text-center">
                                        {service.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row items-center space-x-10 w-full p-6  lg:max-w-[85%] mx-auto bg-white">
                    <div className="w-full md:w-1/2 flex justify-center">
                        <img
                            src="/img/d2c-shoping-ads.jpg"
                            alt="shoping-ads img"
                            //   className="max-w-[580px] max-h-[580px]"
                        />
                    </div>
                    <div className="w-[90%] mx-auto md:w-1/2 mt-6 md:mt-0 md:ml-6">
                        <h2 className="text-[30px] text-justify lg:text-[2.5vw] font-bold mb-4 font-poppins">
                            What are the main components of D2C advertising and branding that we focus on? on?
                        </h2>
                        <p className="text-[18px] font-poppins font-[400] text-justify my-10  mx-auto">
                            There are several challenges in the current market that you must overcome to remain
                            competitive. With our array of modern tools and technology, we are the ideal performance
                            marketer for showcasing your product or service for D2C eCommerce advertising.
                        </p>

                        <ul className="list-none space-y-3 text-left mb-8 text-[18px]  font-poppins font-[400] w-[95%]">
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className="">Ads for specific products.</span>
                            </li>
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className="">Automated advertisements.</span>
                            </li>
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className="">dependable CRO methods.</span>
                            </li>
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className="">Data-oriented advertising .</span>
                            </li>
                            <li className="flex items-start ">
                                <span className="inline-block mt-[6px] mr-2 w-5 h-4 ">
                                    <img className="min-w-5 min-h-4" src="/img/checkmark.png" alt="img" />
                                </span>
                                <span className="">Advertisements for Remarketing.</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className=" flex gap-2 flex-wrap justify-around  items-center text-center mt-10 mb-12 max-w-[70%] mx-auto p-7 border-[15px] border-blue-500 rounded-xl shadow-md round">
                    <p className="text-[22px] font-bold font-poppins">Request a free quote in less than a minute</p>
                    <div className="f-start">
                        <AppButton1
                            variant="h6"
                            style={{ fontFamily: "Gilroy-bold" }}
                            text={"Let's Talk"}
                            onClick={handleClickScheduleMeeting}
                        />
                        <PopupButton
                            ref={scheduleMeetingModalRef}
                            url="https://calendly.com/appretive"
                            rootElement={document.getElementById("root")}
                            styles={{ display: "none" }}
                            text=""
                        />
                    </div>
                </div>

                <div className="">
                    <div className="max-w-[90%] mx-auto">
                        <h1 className="text-[30px] lg:text-[2.5vw] font-bold mb-4 text-center ">
                            A 360-Degree Solution to All of Your D2C Concerns
                        </h1>
                    </div>
                    <div className="flex flex-wrap justify-evenly lg:max-w-[80%] space-y-5 items-center  mx-auto my-10">
                        <div className="flex flex-col gap-3 justify-center items-center w-[300px] transition-transform ease-in-out duration-1000 hover:scale-105 shadow-lg p-5">
                            <img src="/img/Meta-Advertising.png" className="h-[100px] w-[100px]" alt="meta img" />
                            <p className="text-[20px]">Meta Advertising</p>
                            <p className="text-center">
                                Grow engagement and revenues for your eCommerce store by using meta advertising to
                                target and interact with your audience with expertise.
                            </p>
                        </div>
                        <div className="flex flex-col gap-3 justify-center items-center w-[300px]  transition-transform ease-in-out duration-1000 hover:scale-105 shadow-lg p-5">
                            <img src="/img/Youtube-Advertising.png" className="h-[100px] w-[100px]" alt="youtube img" />
                            <p className="text-[20px]">Youtube Advertising</p>
                            <p className="text-center">
                                Increase the visibility of your company and interact with a large audience by using
                                targeted YouTube advertising to convert viewers into buyers.
                            </p>
                        </div>
                        <div className="flex flex-col gap-3 justify-center items-center w-[300px]  transition-transform ease-in-out duration-1000 hover:scale-105 shadow-lg p-5">
                            <img src="/img/Google-Advertising.png" className="h-[100px] w-[100px]" alt="Google img" />
                            <p className="text-[20px]">Google Advertising</p>
                            <p className="text-center">
                                Use customized Google ad campaigns to increase traffic and conversions to your website
                                and draw in high-intent clients.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="clientReview my-10  mb-[-100px] lg:mb-[100px]">
                    <div className="mx-auto text-center">
                        <h1 className="text-[26px] text-center lg:text-[2.5vw] font-bold font-poppins mb-4 capitalize">
                            Some of our happy clients
                        </h1>
                        <p className=" px-5 text-[19px] font-[400] font-poppins text-center">
                            Our clients' reviews demonstrate how satisfied they are with our services
                        </p>
                    </div>
                    <div className="contact-review mb-[50px] md:mb-[70px] lg:mb-[130px]">
                        <div className="page-section contact-review-flex sm:flex flex-wrap justify-evenly">
                            <div className={"contact-section"}>
                                <div className={`page-section`}>
                                    <Typography
                                        className="mb3 t-start clr-w font-bold"
                                        variant="h4"
                                        component="h3"
                                        fontWeight={900}
                                        style={{ fontFamily: "Gilroy-bold" }}
                                    >
                                        Let’s Turn
                                        <span className="clr-pm">Your Ideas</span> into Reality
                                    </Typography>
                                    <Typography
                                        className="mb3 t-start clr-w"
                                        variant="h6"
                                        component="p"
                                        style={{ fontFamily: "Gilroy-bold" }}
                                    >
                                        Let's innovate for your technology needs with Appretive Infotech.
                                    </Typography>
                                    <div className="f-start">
                                        <AppButton1
                                            variant="h6"
                                            style={{ fontFamily: "Gilroy-bold" }}
                                            text={"Let's Talk"}
                                            onClick={handleClickScheduleMeeting}
                                        />
                                        <PopupButton
                                            ref={scheduleMeetingModalRef}
                                            url="https://calendly.com/appretive"
                                            rootElement={document.getElementById("root")}
                                            styles={{ display: "none" }}
                                            text=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="review-section review-section lg:mb-[-180px]">
                                <div className="page-section" style={{ paddingTop: 0, paddingLeft: 0 }}>
                                    <div className="bg-w shadow review-box w-[95%]">
                                        {/* <h2
                                        className="mb2 t-center"
                                        style={{ fontSize: "36px", fontWeight: "600", padding: "20px" }}
                                    > */}
                                        <Typography
                                            className="mb1 mt1 t-center clr-w"
                                            variant="h4"
                                            component="h3"
                                            fontWeight={600}
                                            style={{ fontFamily: "Gilroy-bold" }}
                                        >
                                            <span className="heading-highlighted clr-b">
                                                {" "}
                                                What’s Our <span className="clr-pm">Client</span> Say
                                            </span>{" "}
                                        </Typography>
                                        <div className="slider f-center">
                                            <ReviewSlider data={reviewsList} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PopupButton
                        ref={scheduleMeetingModalRef}
                        url="https://calendly.com/appretive"
                        rootElement={document.getElementById("root")}
                        styles={{ display: "none" }}
                        text=""
                    />
                </div>
            </div>
        </>
    );
};

export default EcommerceAds;
